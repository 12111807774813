import { isEmpty, removeSpacesAndHTML } from "../../general.utils";

export const addBonusFormValidation = ({ subject, message, tokens, employeeId, employees, recurring, recurringType }) => {
	const errors = {}

	if (subject && removeSpacesAndHTML(subject).length > 50) {
		errors.subject = 'Too long'
	}

	if (message && removeSpacesAndHTML(message).length > 1000) {
		errors.message = 'Too long'
	}

	if (!tokens) {
		errors.tokens = '* Required'
	}

	if (!employeeId && (!employees || employees.length === 0)) {
		errors.employees = '* Required'
	}

	if (recurring) {
		if (isEmpty(recurringType)) {
			errors.recurringType = "* Required";
		}
	}

	return errors
}
