import { removeSpacesAndHTML } from "../../general.utils";

export const cityValidations = ({ name }) => {
  const errors = {};

  if (!name.trim()) {
    errors.name = "* Required";
  } else if (!removeSpacesAndHTML(name)) {
    errors.name = "HTML tag is not allowed";
  }

  return errors;
};
