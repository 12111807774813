import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SidebarMenuTabStyles = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 32px;
  cursor: pointer;

  .linkName {
    margin-left: ${({ paraIconLeftMargin }) => paraIconLeftMargin};
    color: #fff;
    font-size: 14px;
  }
`

const SidebarMenuTab = ({
	linkName,
	Icon,
	active = false,
	customCssClass,
	paraIconLeftMargin,
	onClick
}) => (
	<SidebarMenuTabStyles
		active={active}
		className={customCssClass}
		paraIconLeftMargin={paraIconLeftMargin}
		onClick={onClick}
	>
		{Icon && <Icon />}
		<p className="linkName">{linkName}</p>
	</SidebarMenuTabStyles>
)

SidebarMenuTab.propTypes = {
	linkName: PropTypes.string,
	Icon: PropTypes.elementType,
	active: PropTypes.bool,
	customCssClass: PropTypes.string,
	paraIconLeftMargin: PropTypes.string,
	onClick: PropTypes.func
}

export default SidebarMenuTab
