import styled from 'styled-components'

const FormStyles = styled.div`
  width: 545px;
  padding: 36px 32px 32px 27px;

  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    color: #4b4b4b;
    margin-bottom: 32px;
  }

	.selectContainer {
		width: 100%;
		margin-bottom: 22px;
	}

  .nameContainer {
    width: 100%;
    margin-bottom: 12px;
  }

  .dateContainer {
    width: 100%;
    margin: 12px 0;
  }

  .tokensAndCurrencyContainer {
    display: flex;
    justify-content: space-between;
  }

  .tokensContainer {
    width: 48%;
  }

  .inputContainer {
    width: 100%;
    height: 46px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .descriptionContainer {
    width: 100%;
    margin-bottom: 12px;
  }

  .labelAndValidation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .label {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
    text-transform: capitalize;
  }

  .numbersInputs {
    width: 100%;
    height: 46px;
    border-radius: 4px;
    border: solid 1px #d5dbdd;
    background-color: #ffffff;
    padding: 0 14px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737cc;
    outline: none;
    position: absolute;
  }

  .input {
    width: 100%;
    height: 46px;
    border-radius: 4px;
    border: solid 1px #d5dbdd;
    background-color: #ffffff;
    padding: 0 14px;
    font-size: 14px;
    font-family: "Montserrat", sans-serif !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
    outline: none;
  }

  .textArea {
    min-height: 119px;
    max-height: 119px;
    min-width: 100%;
    max-width: 100%;
    border-radius: 4px;
    border: solid 1px #d5dbdd;
    background-color: #ffffff;
    opacity: 0.8;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.23px;
    color: #373737;
    outline: none;
    padding: 14px;
    resize: none;
  }

  .tokenWorth {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.85;
    letter-spacing: -0.22px;
    color: #626262;
  }

  .datepickerWrapper {
    margin-top: 22px;
  }
	.dateDisclaimer {
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: italic;
		line-height: 1.85;
		letter-spacing: -0.22px;
		color: #626262;
	}

	.datepickerWrapper {
    .react-datepicker-wrapper {
      width: 100% !important
    }
  }

	.checkbox-wrapper {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
      margin-left: 10px;
      width: 150px;
    }
  }

  .datePickerInput {
    height: 46px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #979797;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b4b;
    cursor: pointer;
    position: relative;
  }

  input[type=number]::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: none;
  }

  input[type=number] {
      -moz-appearance:textfield;
  }

  @media screen and (max-width: 767px) {
    width: 100%;  
    padding: 36px 12px 32px 12px;
    
    button {
      width: 100%;
    }
  }
`

export default FormStyles
