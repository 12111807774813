import { removeSpacesAndHTML } from "../../../services/general.utils";

export class CreateCompanyPayload {
  payload = new FormData();
  paymentDeadlineDaysNumber;

  constructor(newCompany = {}) {
    if (newCompany.paymentDeadline) {
      this.paymentDeadlineDaysNumber = parseInt(
        newCompany.paymentDeadline.substr(
          0,
          newCompany.paymentDeadline.indexOf(" ")
        ),
        0
      );
    }

    this.payload.append(
      "name",
      newCompany.name && removeSpacesAndHTML(newCompany.name)
    );
    this.payload.append(
      "address",
      newCompany.address && removeSpacesAndHTML(newCompany.address)
    );
    this.payload.append("pib", newCompany.pib);
    this.payload.append("identification_number", newCompany.idNumber);
    this.payload.append("contact", newCompany.contact);

    this.payload.append("invoice_email", newCompany.invoiceEmail);
    this.payload.append("status", newCompany.status);
    this.payload.append("payment_deadline", this.paymentDeadlineDaysNumber);
    this.payload.append("vat_enabled", newCompany.vatEnabled);
    this.payload.append("bonus_budget_enabled", newCompany.bonusBudgetEnabled);
    this.payload.append(
      "only_sso_login_enabled",
      newCompany.onlySSOLoginEnabled
    );
    this.payload.append("photo", newCompany.photo);
    this.payload.append("hasReimbursement", newCompany.hasReimbursement);
    newCompany.tags.forEach(tag => {
      this.payload.append("tags", tag.id);
    });
  }
}

export default CreateCompanyPayload;
