import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ToggleButtonStyles from "./ToggleButtonStyles";

const ToggleButton = ({ tiers, isActive, onToggle }) => (
  <ToggleButtonStyles tiers={tiers} isActive={isActive}>
    <div className="activationCheckBox">
      {isActive && <p className="activityOnText">ON</p>}
      <div className="dot" onClick={tiers > 0 && onToggle} />
      {!isActive && <p className="activationOffText">OFF</p>}
    </div>
    {isActive ? (
      <p className="benefitActivityStatusText">Benefit group is enabled</p>
    ) : (
      <p className="benefitActivityStatusText">Benefit group is disabled</p>
    )}
  </ToggleButtonStyles>
);

ToggleButton.propTypes = {
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,
  tiers: PropTypes.number
};

const mapStateToProps = state => {
  return {
    isActive: state.benefits.editPageState.isBenefitGroupActive,
    tiers: state.benefits.editPageState.tiers.length
  };
};

export default connect(mapStateToProps, null)(ToggleButton);
