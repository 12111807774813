// import React from 'react'
// import { renderToString } from 'react-dom/server'
// import { TokenIcon } from '../../../../assets/Icons'

export const categoryBudgetConfig = {
  series: [
    {
      name: 'Chosen month budget',
      data: []
    },
    {
      name: 'Previous month budget',
      data: []
    }
  ],
  options: {
    chart: {
      type: 'bar',
      height: 430,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    colors: ['#3361b5', '#24ccb8'],
    tooltip: {
      y: {
        // formatter(val) {
        // 	return `${val} ${renderToString(<TokenIcon height="16" />)}`
        // }
        formatter(val) {
          return `${val} RSD`;
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left'
    },
    xaxis: {
      categories: []
    }
  }
};
