export const relationshipOptions = [
	{
		id: 1,
		name: 'Single'
	},
	{
		id: 2,
		name: 'Divorced'
	},
	{
		id: 3,
		name: 'Married'
	}
]
