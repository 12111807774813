import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import styled from "styled-components";
// components
import BenefitGroupForm from "./BenefitGroupForm";
// entity
import { LocalBenefitGroupForm } from "../../../../../models/domain";
// validation
import { validateHrLocalBenefitGroupCreate } from "../../../../../services/validations/hr/hrLocalBenefitValidation";
// services
import * as actionCreators from "../../../../../actions/hr/localBenefits.actions";
import * as localBenefitsService from "../../../../../services/api/hr/localBenefits.service";
// context
import { HrLocalBenefitsContext } from "../../../../../modules/hr/hrLocalBenefits/context/localBenefits.context";

const EditLocalBenefitGroupStyles = styled.div`
  .title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    margin: 30px 0;
  }

  .form {
    width: 481px;
    height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const EditLocalBenefitGroup = ({
  closeModal,
  fetchLocalBenefitGroups,
  chosenBenefitGroupId,
  editLocalBenefitGroup,
  localBenefitGroups
}) => {
  // const {
  //   localBenefitsState: { localBenefitGroups },
  //   localBenefitsActions
  // } = useContext(HrLocalBenefitsContext);
  const [disableButton, setDisableButton] = useState(false);

  const handleUpdateBenefitGroupError = ({ error }) => {
    const { data } = error;
    if (data) {
      if (data.errors && data.error) {
        if (data.errors.name) {
          toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
        }
        if (data.errors.description) {
          toast.error(
            `${data.error.message}: ${data.errors.description.keys[0]}`
          );
        }
        if (data.errors.summary) {
          toast.error(
            `${data.error.message}: ${data.errors.summary.keys[0]}`
          );
        }
        if (data.errors.photo) {
          toast.error(`${data.errors.photo.keys[0]}`);
        }
        if (data.errors.taxes) {
          toast.error(`${data.errors.taxes.keys[0]}`);
        }
      } else {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handleUpdateBenefitGroup = async values => {
    setDisableButton(true);

    const response = await localBenefitsService.editLocalBenefitGroup(
      values,
      chosenBenefitGroupId
    );

    if (response.hasError) {
      setTimeout(() => {
        setDisableButton(false);
      }, 1000);
      return handleUpdateBenefitGroupError(response);
    }

    editLocalBenefitGroup(response);
    fetchLocalBenefitGroups();
    toast.success("Local benefit group successfully edited!");
    closeModal();
    setTimeout(() => {
      setDisableButton(false);
    }, 550);
  };

  const formik = useFormik({
    initialValues:
      localBenefitGroups.find(
        benefitGroup => benefitGroup.id === chosenBenefitGroupId
      ) || new LocalBenefitGroupForm(),
    validate: validateHrLocalBenefitGroupCreate,
    onSubmit: handleUpdateBenefitGroup
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditLocalBenefitGroupStyles>
      <h3 className="title">Edit Local Benefit Group</h3>
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <BenefitGroupForm
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
          buttonText="Update"
          closeModal={closeModal}
          disableButton={disableButton}
          isHr={true}
        />
      </form>
    </EditLocalBenefitGroupStyles>
  );
};

EditLocalBenefitGroup.propTypes = {
  closeModal: PropTypes.func,
  chosenBenefitGroupId: PropTypes.number
};

const mapStateToProps = state => {
  return {
    localBenefitGroups: state.localBenefits.localBenefitGroups
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editLocalBenefitGroup: benefitGroup =>
      dispatch(actionCreators.editLocalBenefitGroup(benefitGroup))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLocalBenefitGroup);
