import React, { useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
// import StarRatings from 'react-star-ratings'
// assets
import { TrashIcon, EditIconSvg } from "../../../../assets/Icons";
// styles
import ProviderRowStyles from "./ProviderRowStyles";
// services
import * as providerService from "../../../../services/api/admin/providers.service";
import * as actionCreators from "../../../../actions/admin/providers.actions";
import { addHeightOnClick, addCardView } from "../providersAnimations";
import { getUrl } from "../../../../services/imageHandler";
// context
import { ProvidersContext } from "../../../../modules/superAdmin/providers/context/providers.context";

const ProviderRow = ({
  provider,
  children,
  // openSubRow,
  // index,
  openModal,
  setChosenProvider,
  setChosenBenefitGroup,
  setChosenBenefit,
  removeProvider
  // chosenBenefitGroupId,
  // deleteProvider
}) => {
  // const { providersActions } = useContext(ProvidersContext);
  return (
    <ProviderRowStyles
      status={provider.status}
      className="row"
      id={`providerRow${provider.id}`}
    >
      <div
        className="providersRowContainer"
        onClick={() => {
          setChosenProvider();
          if (provider.benefitGroups.length > 0) {
            setChosenBenefitGroup(provider.benefitGroups[0].id);
            if (provider.benefitGroups[0].benefits.length > 0) {
              setChosenBenefit(provider.benefitGroups[0].benefits[0].id);
            } else {
              setChosenBenefit(null);
            }
          } else {
            setChosenBenefitGroup(null);
            setChosenBenefit(null);
          }
          addHeightOnClick(provider.id);
          addCardView(provider.id);
        }}
      >
        <div className="imgAndTitle">
          <div className="imgContainer">
            <img
              src={getUrl(provider.photo)}
              alt="provider logo"
              style={{ width: 70 }}
            />
          </div>
          <div className="providerNameAndCategory">
            <h2 className="providerName">{provider.name}</h2>
            {/* <p className="category">{provider.category}</p> */}
          </div>
        </div>
        {/* <div>
					<div className="status">
						{provider.status}
					</div>
				</div>
				<div>
					<StarRatings
						numberOfStars={5}
						rating={provider.reviews / 20}
						starDimension="25px"
						starSpacing="0px"
						starEmptyColor="#d8d8d8"
						starRatedColor="#373737"
					/>
					<p className="reviewsText">{Math.round(provider.reviews)} reviews</p>
				</div>
				<div className="usersContianer">
					<p className="userNumber">
					{provider.newUsers}
						<span className="newUsers">
						+{provider.latestNewUsers}<span className="arrow">↑</span></span>
					</p>
					<p className="label">New users</p>
				</div>
				<div className="usersContianer">
					<p className="userNumber">
						{provider.leftUsers}
						<span className="leftUsers">
						-{provider.lastestLeftUsers}<span className="arrow">↓</span>
						</span>
					</p>
					<p className="label">Left users</p>
				</div> */}
        <div className="rightSideContainer">
          <div
            onClick={e => {
              e.stopPropagation();
              setChosenProvider();
              openModal("editProvider");
            }}
          >
            <EditIconSvg />
          </div>
          <div
            onClick={async e => {
              e.stopPropagation();
              if (provider.benefitGroups.length > 0) {
                toast.info(
                  "In order to delete this provider please first delete all benefit groups and benefits belonging to the provider."
                );
              } else {
                const result = confirm(
                  "Are you sure you want to delete this provider?"
                );
                if (result) {
                  const response = await providerService.deleteProvider(
                    provider.id
                  );

                  if (response.hasError) {
                    return toast.error(response.error.data.error.messagee);
                  }

                  removeProvider(provider.id);
                }
              }
            }}
          >
            <TrashIcon stroke="#8f8f8f" />
          </div>
        </div>
      </div>
      {children}
    </ProviderRowStyles>
  );
};

ProviderRow.propTypes = {
  provider: PropTypes.shape(),
  children: PropTypes.node,
  // openSubRow: PropTypes.func,
  // index: PropTypes.number,
  openModal: PropTypes.func,
  setChosenProvider: PropTypes.func,
  // deleteProvider: PropTypes.func,
  setChosenBenefitGroup: PropTypes.func,
  setChosenBenefit: PropTypes.func
  // chosenBenefitGroupId: PropTypes.number
};

const mapDispatchToProps = dispatch => {
  return {
    removeProvider: id => dispatch(actionCreators.removeProvider(id))
  };
};

export default connect(undefined, mapDispatchToProps)(ProviderRow);
