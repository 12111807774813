import React from 'react'
import PropTypes from 'prop-types'

const itemRenderer = ({ item, itemIndex, props: { valueField, labelField }, state, methods }) => (
	<div className="itemContainer" key={item[valueField]} onClick={() => methods.addItem(item)}>
		<div style={{ margin: '10px' }} className="dropDownItem" checked={methods.isSelected(item)}>
			{item[labelField]}
		</div>
	</div>
)

itemRenderer.propTypes = {
	item: PropTypes.shape(),
	methods: PropTypes.shape(),
	props: PropTypes.shape({
		valueField: PropTypes.string,
		labelField: PropTypes.string
	}),
	state: PropTypes.shape(),
	itemIndex: PropTypes.string
}

export default itemRenderer
