// Constants
import { BenefitExpirationTypeEnum } from "./benefit.constants";

export const FILTERS_TIMER_IN_MILLISECONDS = 600;

export const PRICE_STEP = 50;
export const PRICE_MIN = 0;
export const PRICE_MAX = 1000;

export const BENEFIT_EXPIRATION_TYPES = [
  {
    name: BenefitExpirationTypeEnum.ONE_TIME,
    key: BenefitExpirationTypeEnum.ONE_TIME.split(' ').join('_')
  },
  {
    name: BenefitExpirationTypeEnum.MONTH,
    key: BenefitExpirationTypeEnum.MONTH
  },
  {
    name: BenefitExpirationTypeEnum.VOUCHER,
    key: BenefitExpirationTypeEnum.VOUCHER
  }
]

export  const SORT_OPTIONS = [
  {
    id: 1,
    name: 'Newest / Recently added',
    key: 'created'
  },
  {
    id: 2,
    name: 'Benefit Group Name',
    key: 'name'
  },
  {
    id: 3,
    name: 'Best Rated',
    key: 'rating'
  }
];

