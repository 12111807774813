export const validateNotificationForm = ({ subject, message, companies }) => {
	const errors = {}
	if (!subject) {
		errors.subject = '* Required'
	} else if (subject.length < 5) {
		errors.subject = 'Subject must be at least 5 characters long'
	}

	if (!message) {
		errors.message = '* Required'
	} else if (message.length < 5) {
		errors.message = 'Message must be at least 5 characters long'
	}

	if (!companies) {
		errors.companies = '* Required'
	} else if (companies.length < 1) {
		errors.companies = 'Recipient must be at least 1 company'
	}

	return errors
}
