import React from "react";
import PropTypes from "prop-types";

import BudgetRolloverToggleStyles from "./BudgetRolloverToggle.styles";

const BudgetRolloverToggle = ({ enableRollover, onToggle }) => {
  return (
    <BudgetRolloverToggleStyles enableRollover={enableRollover}>
      <div className="activationCheckBox">
        {enableRollover && <p className="activityOnText">ON</p>}
        <div className="dot" onClick={onToggle} />
        {!enableRollover && <p className="activationOffText">OFF</p>}
      </div>
      {enableRollover ? (
        <p className="benefitActivityStatusText">Rollover is enabled</p>
      ) : (
        <p className="benefitActivityStatusText">Rollover is disabled</p>
      )}
    </BudgetRolloverToggleStyles>
  );
}

BudgetRolloverToggle.propTypes = {
  enableRollover: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default BudgetRolloverToggle;
