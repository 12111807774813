export const genderOptions = [
	{
		id: 1,
		name: 'Male'
	},
	{
		id: 2,
		name: 'Female'
	},
	{
		id: 3,
		name: 'Other'
	}
]
