import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import swal from "sweetalert";
// components
import { Header, Card, Input, InputLabel, Button, MultiselectInput, TextAndLine } from "../../../components";
// entity
import { Notification } from "./notifications.entity";
// services
import { validateNotificationForm } from "../../../services/validations/notificationFormValidation";
import * as notificationService from "../../../services/api/admin/notification.service";
import * as companiesService from "../../../services/api/admin/companies.service";
import * as actionCreators from "../../../actions/admin/companies.actions";
// styles
import NotificationsStyles from "./NotificationsStyles";
// context
import { UserContext } from "../../../modules/user/context/user.context";

const Notifications = ({ companies, setCompanies }) => {
  const [serverErrorMsg, setServerErrorMsg] = useState(null);
  const [companiesPage, setCompaniesPage] = useState(1);
  const [totalCompaniesPages, setTotalCompaniesPages] = useState(1);
  // const { user } = useContext(UserContext)

  const getCompanies = async () => {
    const response = await companiesService.getCompanies({
      page: companiesPage
    });

    if (response.hasError) {
      return setServerErrorMsg(response.error.data.error.message);
    }

    setCompanies(response.companies);
    setTotalCompaniesPages(response.pages);
  };

  /**
   * Refetches paginated companies.
   * Fired from on scroll event from select input.
   * Sets new companies to state, along with page number.
  */
  const refetchCompanies = async () => {
    let currentPage = companiesPage;
    let newPage = currentPage += 1;

    if (newPage > totalCompaniesPages) return;

    setCompaniesPage(newPage);
    const response = await companiesService.getCompanies({
      page: newPage
    });
    if (response.hasError) {
      return toast.error(response.errorMessage ? response.errorMessage : "Fetching companies failed")
    }

    let newCompanies = [];
    setTimeout(() => {
      newCompanies = [...companies, ...response.companies];
      setTimeout(() => {
        setCompanies(newCompanies);
      }, 100)
    }, 100)
  }

  const handleSendMail = async (values, { resetForm }) => {
    try {
      const { data } = await notificationService.sendMail(values);
      if (data) {
        toast.success(data.message);
        resetForm();
      }
    } catch (error) {
      if (error && error.response) {
        const { data } = error.response;
        if (data) {
          if (data.errors && data.error) {
            if (data.errors.subject) {
              toast.error(
                `${data.error.message}: ${data.errors.subject.keys[0]}`
              );
            }
            if (data.errors.message) {
              toast.error(
                `${data.error.message}: ${data.errors.message.keys[0]}`
              );
            }
          } else {
            toast.error(data.error.message);
          }
        }
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  /**
   * Sends budget reminder emails to all employees.
   * @param {Event} event 
   */
  const handleSendBudgetReminders = (event) => {
    event.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Accepting this will send out budget reminder emails to all employees in the database.",
      buttons: true,
      dangerMode: true,
      icon: "warning"
    }).then(async value => {
      if (!value) return;
      const response = await notificationService.sendBudgetRemindersEmails();
      if (response.hasError) {
        return toast.error(
          response.errorMessage
            ? response.errorMessage
            : "Sending emails failed."
        );
      }

      toast.success(response.data.message);
    });
  }

  /**
   * Sends usage instructions emails.
   * @param {Event} event 
  */
  const handleSendUsageInstructions = (event) => {
    event.preventDefault();
    swal({
      title: "Are you sure?",
      text: "Accepting this will send out usage instructions emails to employees that have selected benefits that have usage instructions.",
      buttons: true,
      dangerMode: true,
      icon: "warning"
    }).then(async value => {
      if (!value) return;
      const response = await notificationService.handleSendUsageInstructions();
      if (response.hasError) {
        return toast.error(
          response.errorMessage
            ? response.errorMessage
            : "Sending emails failed."
        );
      }

      toast.success(response.data.message);
    });
  }

  const formik = useFormik({
    initialValues: new Notification(),
    validate: validateNotificationForm,
    onSubmit: handleSendMail
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  const filteredCompanies = () => {
    const chosenIds = [];
    values.companies.forEach(company => {
      chosenIds.push(company.id);
    });
    return companies.filter(t => !chosenIds.includes(t.id));
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <NotificationsStyles>
      <div className="notificationsContainer">
        <Header headerTitle="Notifications" />
        <div className="notificationsContent">
          <Card padding="30px">
            <TextAndLine title="Reminders & Instructions" />
            <div className="notificationActionsSection">
              <Button
                onClick={e => handleSendBudgetReminders(e)}
                margin="0"
                padding="10px 25px"
              >
                Send Budget Reminders
              </Button>
              <Button
                onClick={e => handleSendUsageInstructions(e)}
                margin="0"
                padding="10px 25px"
              >
                Send Usage Instructions
              </Button>
            </div>
            <TextAndLine title="Custom Email Notifications" />
            <form style={{ marginTop: "20px" }} onSubmit={handleSubmit} autoComplete="off">
              <h3 className="title">
                Send message to users of chosen companies
              </h3>
              <div className="serverErrorMsg">{serverErrorMsg}</div>
              <div className="inputContainer">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <InputLabel margin="0 0 5px 0" label="Subject" />
                  <div className="errorMsg">
                    {errors.subject && touched.subject ? errors.subject : ""}
                  </div>
                </div>
                <Input
                  name="subject"
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="inputContainer">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <InputLabel margin="0 0 5px 0" label="Message" />
                  <div className="errorMsg">
                    {errors.message && touched.message ? errors.message : ""}
                  </div>
                </div>
                <textarea
                  cols="30"
                  rows="10"
                  className="textArea"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <InputLabel
                    margin="0 0 5px 0"
                    label="Choose companies as receivers"
                  />
                  <div className="errorMsg">
                    {errors.companies && touched.companies
                      ? errors.companies
                      : ""}
                  </div>
                </div>
                <MultiselectInput
                  options={companies}
                  selectedValues={values.companies}
                  handleChange={option => setFieldValue("companies", option)}
                  onBlur={setFieldTouched}
                  shouldHaveFullWidth
                  fetchMoreData={refetchCompanies}
                />
              </div>
              <Button type="submit" margin="30px 0 0 0">
                Send message
              </Button>
            </form>
          </Card>
        </div>
      </div>
    </NotificationsStyles>
  );
};

const mapStateToProps = state => {
  return {
    companies: state.companies.companies
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCompanies: companies => dispatch(actionCreators.setCompanies(companies))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
