import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-dropdown-select'

import itemRenderer from './itemRenderer';

import SortSelectInputStyles from './SortSelectInput.styles'

import { onChangeParam, getSelectedValue } from '../selectInput/selectInput.services'
import { isEmpty } from '../../services/general.utils';

const SortSelectInput = ({
  value,
  selectedValues,
  disabled = false,
  getNameValue = false,
  onChange = () => { },
  handleChange,
  onBlur,
  name,
  defaultOption,
  customId,
  shouldHaveHight = true,
  options = [],
  placeholder = 'Select Item',
  sortKey,
  sortOrder,
}) => {

  const getValues = () => {
    if (defaultOption) {
      return [options[defaultOption]]
    }
    if (selectedValues && selectedValues.length > 0) {
      return value ? [getSelectedValue(selectedValues, value)] : undefined
    }
    return []
  }

  /**
   * Renders custom content for the dropdown selected value.
   * @param {object} props - Props from the dropdown library contentRender handler
   * @param {object} state - Current state of the dropdown content
   * @returns 
   */
  const customContentRenderer = ({ props, state }) => {
    return state.values.length > 0 && !isEmpty(sortKey) && !isEmpty(sortOrder) ?
      (
        <div>
          {state.values[0].name}: <b>{sortOrder === 'DESC' ? 'Descending' : 'Ascending'}</b>
        </div>
      ) : 'Sort By'
  }

  return (
    <SortSelectInputStyles
      id={customId}
      shouldHaveHight={shouldHaveHight}
    >
      <Select
        values={getValues()}
        placeholder={placeholder}
        options={options}
        className="selectInput"
        labelField="name"
        valueField="id"
        disabled={disabled}
        dropdownPosition="auto"
        onChange={(values) => {
          onChange(name, onChangeParam(getNameValue, values))
          if (handleChange !== undefined) {
            handleChange(values[0])
          }
        }}
        onBlur={onBlur}
        contentRenderer={customContentRenderer}
        itemRenderer={
          itemRenderer
            ? (item, itemIndex, props, state, methods) =>
              itemRenderer(item, itemIndex, props, state, methods, sortKey, sortOrder)
            : undefined
        }
        clearable
      />
    </SortSelectInputStyles>
  )
}

SortSelectInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape()]),
  selectedValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape())
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  customId: PropTypes.string,
  handleChange: PropTypes.func,
  defaultOption: PropTypes.number,
  onBlur: PropTypes.func,
  shouldHaveHight: PropTypes.bool,
  name: PropTypes.string,
  getNameValue: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string
}

export default SortSelectInput
