import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { BeatLoader } from "react-spinners";
import StarRatings from "react-star-ratings";

// assets
import {
  ActiveIcon,
  PendingActivationIcon,
  PendingDeactivationIcon
} from "../../../../assets/Icons";

// components
import LeaveReviewForm from "../../../../components/forms/review/LeaveReviewForm";
import {
  openRatingModalAnim,
  closeRatingModalAnim
} from "../../../../components";

// style component
import BenefitSubscribeStyles from "./BenefitSubscribeStyles";
import { subscribe } from "../../../../services/loadingOverride.styles";

// services
import { getUrl } from "../../../../services/imageHandler";
import * as api from "../../../../services/api/employee/employeeBenefits.services";

// actions
import * as actionCreators from "../../../../actions/employee/employeeBenefits.actions";

// builder constants
import { BenefitExpirationTypeEnum } from "../../../../constants/benefit.constants";
import {
  ACTIVE,
  PENDING_ACTIVATION,
  PENDING_DEACTIVATION
} from "../../../../builders/benefitGroups/benefitGroup.status";

// Utils
import { renderLocations } from "../../../../renderers/locations.renderer";
import { doesDescriptionExist } from "../../../../services/textEditor.utils";
import { isEmpty } from "../../../../services/general.utils";
import { calculatePriceWithVat } from "../../../../services/benefit.utils";

// Renderers
import { renderCategories } from "../../../../renderers/categories.renderer";
import { toast } from "react-toastify";
import { apiService } from "../../../../services/api/api.service";
import { BenefitSubscribeItem } from "./BenefitSubscribeItem";

const BenefitSubscribe = ({
  benefitGroup,
  isPreview = false,
  maxHeight = "90vh",
  user,
  usedBenefits,
  setUsedBenefits,
  setShowPDFBill,
  setVoucherReimbursementData,
  priceRange,
  chosenBenefitExpirationTypes
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenRatingModal, setIsOpenRatingModal] = useState(false);
  const [benefits, setBenefits] = useState([]);
  const isMounted = useRef(false);

  const { subscribedToSingleBenefitGroup } = useSelector(
    state => state.employeeBenefitsPage.socialProof
  );

  useEffect(() => {
    return () => {
      dispatch(actionCreators.checkIfInFavorites(false));
    };
  }, []);

  const { isInFavorites } = useSelector(
    state => state.employeeBenefitsPage.favoriteBenefits
  );

  const updateStateOfFavorites = async () => {
    const benefitListResponse = await apiService.get(`/favorites`);

    const listOfFavorite =
      benefitListResponse.data.items.length > 0
        ? benefitListResponse.data.items.map(item => item.group)
        : [];

    dispatch(actionCreators.setListOfFavorites(listOfFavorite));

    const listOfBenefitGroupIds = listOfFavorite.map(favorite => favorite.id);

    const isItemInListOfBenefits = listOfBenefitGroupIds.includes(
      benefitGroup.id
    );

    dispatch(actionCreators.checkIfInFavorites(isItemInListOfBenefits));
  };

  const removeFromFavorites = async () => {
    try {
      const removeFromBenefitsResponse = await apiService.delete(
        `/favorites/remove/benefits/${benefitGroup.id}`
      );

      toast.success(removeFromBenefitsResponse.data.message);

      updateStateOfFavorites();
    } catch (error) {
      toast.error("Failed to remove benefit from favorites.");
    }
  };

  const addToFavorites = async () => {
    try {
      const removeFromBenefitsResponse = await apiService.post(
        `/favorites/add/benefits/${benefitGroup.id}`
      );

      toast.success(removeFromBenefitsResponse.data.message);

      updateStateOfFavorites();
    } catch (error) {
      toast.error("Failed to add benefit to favorites.");
    }
  };

  const handleAddOrRemoveFromFavorites = isInFavorites => {
    if (isInFavorites) {
      removeFromFavorites();
      return;
    }

    addToFavorites();
  };

  const fetchBenefits = async () => {
    setIsLoading(true);
    const response = await api.getBenefitsForBenefitGroup(
      benefitGroup.id,
      priceRange,
      chosenBenefitExpirationTypes
    );

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching benefits failed."
      );
    }

    setBenefits(response.benefits);
    setIsLoading(false);
  };

  const getVoucherPrices = benefit => {
    if (benefit.expiration === BenefitExpirationTypeEnum.VOUCHER) {
      const voucherPrices = benefit.vouchers.map(voucher => {
        return user.companyVatEnabled && benefit.vat
          ? calculatePriceWithVat(voucher.price, benefit.vat)
          : voucher.price;
      });
      const maxTokenPrice =
        voucherPrices.length > 0 ? Math.max(...voucherPrices) : 0;
      const minTokenPrice =
        voucherPrices.length > 0 ? Math.min(...voucherPrices) : 0;
      if (maxTokenPrice === minTokenPrice) {
        return `${maxTokenPrice}`;
      } else {
        return `${minTokenPrice} - ${maxTokenPrice}`;
      }
    }
  };

  /**
   * Returns benefit price.
   * If benefit is subscribed to, returns subscribed price.
   * If not, returns default price.
   * @param {Benefit} benefit
   * @returns {number}
   */
  const getBenefitPrice = benefit => {
    if (benefit.isSubscribed) {
      return benefit.subscribedPrice;
    }

    const benefitPrice =
      user.companyVatEnabled && benefit.vat
        ? calculatePriceWithVat(benefit.tokenPrice, benefit.vat)
        : benefit.tokenPrice;
    if (
      benefitGroup.doesBenefitGroupHaveInstalments &&
      benefitGroup.enableInstalments &&
      benefit.expiration === "one time" &&
      benefit.instalments
    ) {
      return `${benefit.instalments} x ${Math.floor(
        benefitPrice / benefit.instalments
      )}`;
    }

    return benefitPrice;
  };

  /**
   * Renders benefit status with appropriate icon and color scheme.
   * @param {string} status
   * @param {string} statusPreview
   * @param {boolean} shouldBeIndicatedAsActive
   * @param {boolean} isSubscribed
   * @returns {HTMLDivElement}
   */
  const renderBenefitStatus = (
    status,
    statusPreview,
    shouldBeIndicatedAsActive,
    isSubscribed
  ) => {
    if (isSubscribed && status === ACTIVE) {
      return (
        <div className="benefitStatusActive">
          <ActiveIcon opacity="1" fill="#41C264" /> {statusPreview}
        </div>
      );
    }
    if (status === PENDING_DEACTIVATION && shouldBeIndicatedAsActive) {
      return (
        <div className="benefitStatusPendingDeactivation">
          <PendingDeactivationIcon opacity="1" fill="#C53C3C" /> {statusPreview}
        </div>
      );
    }
    if (isSubscribed && status === PENDING_ACTIVATION) {
      return (
        <div className="benefitStatusPendingActivation">
          <PendingActivationIcon opacity="1" fill="#84988E" /> {statusPreview}
        </div>
      );
    }
  };

  /**
   * Returns class names for benefit card based on benefit status.
   * @param {Benefit} benefit
   * @returns {string}
   */
  const getClassNameForBenefitCard = benefit => {
    if (
      benefit.shouldBeIndicatedAsActive &&
      benefitGroup.status === PENDING_DEACTIVATION
    ) {
      return "benefiItemCard benefitStatusPendingDeactivationIndicator";
    }

    if (benefit.isSubscribed && benefitGroup.status === ACTIVE) {
      return "benefiItemCard benefitStatusActiveIndicator";
    }

    if (benefit.isSubscribed && benefitGroup.status === PENDING_ACTIVATION) {
      return "benefiItemCard benefitStatusPendingActivationIndicator";
    }

    return "benefiItemCard";
  };

  const openModal = () => {
    setIsOpenRatingModal(!isOpenRatingModal);

    setTimeout(() => {
      openRatingModalAnim();
    }, 100);
  };

  const closeModal = () => {
    setIsOpenRatingModal(!isOpenRatingModal);

    setTimeout(() => {
      closeRatingModalAnim();
    }, 350);
  };

  /**
   * Sorts benefits from benefit group by subscription status.
   * Subscribed benefits should always be on top.
   * @returns {Benefit[]}
   */
  const sortedBenefits = () => {
    return (
      !isEmpty(benefits) &&
      benefits.sort(
        (a, b) =>
          Number(b.isSubscribed) - Number(a.isSubscribed) ||
          Number(b.shouldBeIndicatedAsActive) -
            Number(a.shouldBeIndicatedAsActive)
      )
    );
  };

  useEffect(() => {
    isMounted.current = true;
    fetchBenefits();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const benefitItemProps = {
    getBenefitPrice,
    getVoucherPrices,
    renderBenefitStatus,
    getClassNameForBenefitCard,
    doesDescriptionExist,
    ReactHtmlParser,
    setIsLoading,
    setVoucherReimbursementData,
    setShowPDFBill,
    isPreview,
    benefitGroup,
    user
  };

  return (
    <BenefitSubscribeStyles maxHeight={maxHeight}>
      <BeatLoader
        css={subscribe}
        size={25}
        color="#123abc"
        loading={isLoading}
      />
      <div className="imageContainer">
        {benefitGroup?.photo && (
          <img
            src={getUrl(
              !isEmpty(benefitGroup?.companyId) && benefitGroup?.provider
                ? benefitGroup?.provider.photo
                : benefitGroup?.photo,
              "url3x"
            )}
            alt="Benefit card image"
            className="image"
            crossOrigin="anonymous"
          />
        )}
        {subscribedToSingleBenefitGroup?.length > 0 && (
          <div id="social-proof" className="socialProofForSingleBenefit">
            <div className="socialProofForSingleBenefitCountWrapper">
              <span className="socialProofForSingleBenefitNumberOfSubscribes">
                {subscribedToSingleBenefitGroup?.length}
              </span>
              <span className="socialProofForSingleBenefitUsers">
                {subscribedToSingleBenefitGroup?.length > 1
                  ? "korisnika"
                  : "korisnik"}
              </span>
            </div>
            <div className="socialProofForSingleBenefitDescription">
              Nedavno se{" "}
              {subscribedToSingleBenefitGroup?.length > 1
                ? "pretplatilo"
                : "pretplatio"}{" "}
              na
              <span className="socialProofForSingleBenefitName">
                {" "}
                {
                  new Set(
                    subscribedToSingleBenefitGroup?.map(
                      x => x.BenefitGroup.name
                    )
                  )
                }{" "}
              </span>
              benefit.
            </div>
          </div>
        )}
      </div>
      <div className="topContainer">
        <div className="providerImageOuterCircle">
          <div className="providerImageInnerCircle">
            {benefitGroup && (
              <img
                src={
                  !isEmpty(benefitGroup.provider)
                    ? getUrl(benefitGroup.provider.photo)
                    : getUrl(benefitGroup.photo)
                }
                alt="provider logo"
                className="providerLogo"
                crossOrigin="anonymous"
              />
            )}
          </div>
        </div>
        <div className="titleWrapper">
          <h2 className="title">
            {benefitGroup && benefitGroup.name && benefitGroup.name}
          </h2>
          <button
            className="addOrRemoveFromFavoritesButton"
            onClick={() => handleAddOrRemoveFromFavorites(isInFavorites)}
          >
            {isInFavorites ? "Remove from favorites" : "Add to favorites"}
          </button>
        </div>

        <div className="categoryTag">
          {renderCategories(benefitGroup.categories)}
        </div>
        <div className="ratingContainer">
          <div className="starContainer">
            <span className="total">
              {benefitGroup.numberReviews
                ? (
                    benefitGroup.sumRatings / benefitGroup.numberReviews
                  ).toFixed(1)
                : 0}
            </span>
            <StarRatings
              numberOfStars={1}
              rating={
                benefitGroup.numberReviews
                  ? benefitGroup.sumRatings / benefitGroup.numberReviews
                  : 0
              }
              starDimension="18px"
              starSpacing="0px"
              starEmptyColor="#EAEAEA"
              starRatedColor="#FFD402"
            />
            <span className="reviewsNumber">
              {" "}
              ({benefitGroup.numberReviews} ratings)
            </span>
          </div>
          {/**
           * When the client decides to add comments, change the text of the link to View reviews. The link opens the same modal.
           */}
          {usedBenefits?.length > 0 && !isOpenRatingModal && (
            <div
              onClick={() => {
                openModal(benefitGroup.id);
              }}
              className="link"
            >
              Rate this benefit
            </div>
          )}
        </div>
        {isOpenRatingModal && (
          <div id="ratingModalCard" className="ratingCardContainer">
            {usedBenefits?.length > 0 && (
              <LeaveReviewForm
                usedBenefits={usedBenefits}
                setUsedBenefits={setUsedBenefits}
                closeModal={closeModal}
              />
            )}
          </div>
        )}
        {doesDescriptionExist(benefitGroup.description) ? (
          <div className="description">
            {ReactHtmlParser(benefitGroup.description)}
          </div>
        ) : (
          <p className="summary">{benefitGroup.summary}</p>
        )}
        {renderLocations(
          benefitGroup.cities,
          benefitGroup.isRemote,
          user?.employee?.cities
        )}
      </div>
      <div className="benefitItemsContainer">
        {!isEmpty(benefits) &&
          benefits &&
          sortedBenefits().map(benefit => (
            <BenefitSubscribeItem
              key={benefit.id}
              benefit={benefit}
              {...benefitItemProps}
            />
          ))}
      </div>
    </BenefitSubscribeStyles>
  );
};

BenefitSubscribe.propTypes = {
  benefitGroupId: PropTypes.number,
  closeModal: PropTypes.func,
  isSubscribedBenefits: PropTypes.bool,
  getUserBenefitGroups: PropTypes.func,
  resetBenefits: PropTypes.func
};

const mapStateToProps = state => {
  return {
    benefitGroup:
      state.employeeBenefitsPage.pageFrontEndStates.chosenBenefitGroup,
    user: state.app.user,
    priceRange: state.employeeBenefitsPage.filters.chosenPriceRange,
    chosenBenefitExpirationTypes: state.employeeBenefitsPage.filters.expirations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeBenefitGroupModal: () =>
      dispatch(actionCreators.closeBenefitGroupModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BenefitSubscribe);
