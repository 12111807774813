import React from "react";
import ReactTooltip from "react-tooltip";

import { InfoIconSvg } from "../../../../assets/Icons";

import ToggleButton from "./toggleButton";

import { ActivitySectionStyles } from "./activitySection.styles";

const ActivitySection = ({ onToggle }) => (
  <ActivitySectionStyles>
    <ToggleButton onToggle={onToggle} />
    <a data-tip data-for="sadFace" style={{ cursor: "pointer" }}>
      <InfoIconSvg />
    </a>
    <ReactTooltip
      id="sadFace"
      place="top"
      effect="solid"
      className="tooltip"
      backgroundColor="#1568bf"
      borderColor="#1568bf"
      arrowColor="#1568bf"
    >
      <span className="spanText">
        If you disable benefit, all users will lose access automatically.
      </span>
    </ReactTooltip>
  </ActivitySectionStyles>
);

export default ActivitySection;
