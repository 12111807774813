import validator from "validator";
import moment from "moment";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";

const allowedDateFormats = [
  "DD/MM/YYYY",
  "D/M/YYYY",
  "DD.MM.YYYY",
  "D.M.YYYY",
  "DD-MM-YYYY",
  "D-M-YYYY"
];

const validateDate = value => moment(value, allowedDateFormats, true).isValid();

export const hrEmployeeValidations = ({
  firstName,
  lastName,
  email,
  //   gender,
  // team,
  // contact,
  // description,
  tier,
  cities
}) => {
  const errors = {};

  if (!firstName || !/\S/.test(firstName)) errors.firstName = "* Required"
    else if (!removeSpacesAndHTML(firstName)){
      errors.firstName = 'HTML tag is not allowed'
    };

  if (!lastName || !/\S/.test(lastName)) errors.lastName = "* Required"
    else if (!removeSpacesAndHTML(lastName)){
      errors.lastName = 'HTML tag is not allowed'
    };

  if (!email) errors.email = "* Required";
  else if (!validator.isEmail(email)) errors.email = "Invalid email";

  // if (!gender) errors.gender = '* Required'
  // if (!team) errors.team = '* Required'
  // if (!contact) errors.contact = '* Required'
  // if (!description) errors.description = '* Required'

  if (!tier) errors.tier = "* Required";

  if (isEmpty(cities)) {
    errors.cities = "* Required";
  }

  return errors;
};
