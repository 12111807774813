// import React from 'react'
// import { renderToString } from 'react-dom/server'
// import { TokenIcon } from '../../../../assets/Icons'

export const barChart = {
  series: [],
  options: {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '40px',
        endingShape: 'rounded'
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      fontSize: '12px',
      fontFamily: 'Montserrat'
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Top Benefits']
    },
    // yaxis: {
    // 	title: {
    // 		text: '$ (thousands)'
    // 	}
    // },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        // formatter(val) {
        // 	return `${val} ${renderToString(<TokenIcon height="16" />)}`
        // }
        formatter(val) {
          return `${val} RSD`;
        }
      }
    }
  }
};
