import { isEmpty, removeSpacesAndHTML } from "../../general.utils";

export const tierFormValidation = ({ name, description, tokens }) => {
	const errors = {}

	if (!name.trim()) {
		errors.name = '* Required'
	} else if (removeSpacesAndHTML(name).length > 20) {
		errors.name = 'Too long'
	} else if (!removeSpacesAndHTML(name)) {
		errors.name = 'HTML tag is not allowed'
	};

	if (description && removeSpacesAndHTML(description).length > 100) {
		errors.description = 'Too long'
	}

	if (isEmpty(tokens)) {
		errors.tokens = '* Required'
	}

	return errors
}
