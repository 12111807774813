export const reportTypeDropdownOptions = [
  {
    id: 1,
    name: "Chosen for this month"
  },
  {
    id: 2,
    name: "Chosen for next month"
  }
];
