import React, { useState, useReducer, useContext, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
// components
import { InputLabel, TextAndLine, SelectInput, Button } from '../../../../components'
// actions
import { useCompanyTagInternActions } from './companyTags.actions'
// styles
import CompanyTagsSectionStyles from './CompanyTagsSectionStyles'
// local reducer
import { companyTagsSectionReducer, initialCompanyTagsSectionState } from './companyTagsSectionReducer'
// services
import { getCompanyTags } from '../../../../modules/superAdmin/companies/companies.services'
import * as actionCreators from '../../../../actions/admin/companyTags.actions';
import * as companyTagService from '../../../../services/api/admin/companyTags.service';
// context
import { CompaniesContext } from '../../../../modules/superAdmin/companies/context/companies.context'
// utils
import { isEmpty } from '../../../../services/general.utils';

const CompanyTagsSection = ({
	isFormDisplay = false,
	values,
	setFieldValue,
	setCompanyTags,
	tags
}) => {
	const [{ isAddTagOpen, tagObj }, dispatch] =
		useReducer(companyTagsSectionReducer, initialCompanyTagsSectionState);
	const isMounted = useRef(true);

	// const {
	// 	companiesState: {
	// 		allTags
	// 	},
	// 	companiesActions: { setCompanyTags }
	// } = useContext(CompaniesContext)

	const fetchTags = async () => {
		const response = await companyTagService.getCompanyTags()
		if (isMounted.current && response) {
			setCompanyTags(response)
		}
	}

	useEffect(() => {
		fetchTags()

		return () => () => { isMounted.current = false }
	}, [])

	const removeTag = (id) => {
		const filteredTags = values.tags.filter(tag => tag.id !== id)
		setFieldValue('tags', filteredTags)
	}

	const {
		handleTagChange,
		openAddTagInput,
		addTag,
		filteredTags
	} = useCompanyTagInternActions(dispatch)

	return (
		<CompanyTagsSectionStyles isAddTagOpen={isAddTagOpen}>
			{isFormDisplay ?
				<>
					<TextAndLine title="Company tags" />
					<div className="companyTagsContainer">
						<div className="addTagContainer">
							<Button
								height="27px"
								type="button"
								disabled={isAddTagOpen}
								onClick={() => openAddTagInput()}
								padding="0"
								margin="0 30px 0 0"
								width="100px"
							>
								Add tag
							</Button>
							{isAddTagOpen &&
								<div className="addTagInput" id="addTagInput">
									<div className="tagNameInputContainer">
										<InputLabel margin="0 0 5px 0" label="Tag name" />
										<SelectInput
											options={filteredTags(values.tags, tags)}
											handleChange={(option) => {
												handleTagChange(tags, option.id);
											}}
											value={tagObj}
											customHeight="27px"
										/>
									</div>
									<Button
										height="27px"
										type="button"
										onClick={() => addTag(setFieldValue, values, tagObj)}
										disabled={!tagObj}
										padding="0"
										margin="0 30px 0 0"
										width="100px"
									>
										Submit tag
									</Button>
								</div>
							}
						</div>
						<div style={{ minHeight: 50 }}>
							{!isEmpty(values.tags) &&
								<div className="tagsContainer">
									{values.tags.map((tag) =>
										<div className="tagButton" key={tag.name}>
											<p className="tagName">{tag.name}</p>
											<button
												onClick={() => removeTag(tag.id)}
												className="removeTagButton"
												type="button"
											>
												X
											</button>
										</div>
									)}
								</div>
							}
						</div>
					</div>
				</>
				:
				<>
					{!isEmpty(values.tags) &&
						<div className="companyDetailsTagsSection">
							<TextAndLine title="Company tags" />
							<div className="companyDetailsTagsContainer">
								{values.tags?.map(tag =>
									<div className="tagButton" key={tag.id}>
										<p className="tagName">{tag.name}</p>
									</div>
								)}
							</div>
						</div>
					}
				</>
			}
		</CompanyTagsSectionStyles>
	)
}

CompanyTagsSection.propTypes = {
	isFormDisplay: PropTypes.bool,
	values: PropTypes.shape(),
	setFieldValue: PropTypes.func
}

const mapStateToProps = state => {
	return {
		tags: state.companyTags.tags,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setCompanyTags: companyTags => dispatch(actionCreators.setCompanyTags(companyTags)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTagsSection);
