import styled from "styled-components";

const ToggleButtonStyles = styled.div`
  display: flex;
  align-items: center;

  .activationCheckBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 55px;
    height: 25px;
    border-radius: 16px;
    border: 2px solid
      ${({ isActive, tiers }) => {
        if (tiers > 0) {
          if (isActive) {
            return "#41c264";
          }
          return "#ce1212";
        }
        return "#d8d8d8";
      }};
    padding: 5px;
    margin-right: 8px;
    position: relative;
  }

  .dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: ${({ isActive, tiers }) => {
      if (tiers > 0) {
        if (isActive) {
          return "#41c264";
        }
        return "#ce1212";
      }
      return "#d8d8d8";
    }};
    cursor: pointer;
  }

  .activityOnText {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ tiers }) => {
      if (tiers > 0) {
        return "#41c264";
      }
      return "#d8d8d8";
    }};
  }

  .activationOffText {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ tiers }) => {
      if (tiers > 0) {
        return "#ce1212";
      }
      return "#d8d8d8";
    }};
  }

  .benefitActivityStatusText {
    font-size: 16px;
    font-weight: 500;
    width: 209px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${({ tiers }) => {
      if (tiers > 0) {
        return "#4b4b4b";
      }
      return "#d8d8d8";
    }};
  }
`;

export default ToggleButtonStyles;
