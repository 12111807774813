/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";

import { Card } from "../../../../components";
import { configUniqueCombinations } from "./config";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";

const PosibleCombinationsStyles = styled.div`
  margin-top: 20px;

  .numberOfPosbileCombinationsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .combinationsTitle {
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .line {
    width: 90%;
    border: 0.5px solid #d8d8d8;
    margin: 0 auto;
  }

  .tierContainer {
    margin: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #1568bf;
    padding: 0 5px 10px 5px;
  }
`;

const PosibleCombinations = ({ companyName }) => {
  const [numberOfCombinations, setNumberOfCombinations] = useState([]);
  const [numberOfUniqueCombinations, setNumberOfUniqueCombinations] = useState(
    configUniqueCombinations(0)
  );

  const fectchWidgetData = async () => {
    const [
      usedCombinationsRes,
      uniqueCOmbinationsPerTiersRes
    ] = await Promise.all([
      dashboardService.fetchUniqueUsedCombinations(),
      dashboardService.fetchUniqueCombinationsPerTiers()
    ]);

    if (usedCombinationsRes) {
      setNumberOfUniqueCombinations(
        configUniqueCombinations(usedCombinationsRes.combinations)
      );
      setNumberOfCombinations(uniqueCOmbinationsPerTiersRes.items);
    }
  };

  useEffect(() => {
    fectchWidgetData();
  }, []);

  return (
    <PosibleCombinationsStyles>
      <Card>
        <div>
          <div className="titleContainer">
            <h3 className="title">{companyName}</h3>
            <h3 className="title">Combination Statistics</h3>
          </div>
          {/* <div className="numberOfPosbileCombinationsContainer">
						<h4 className="title">total Posibile combinations of benefits</h4>
						<ReactApexChart
							options={numberOfCombinations.options}
							series={numberOfCombinations.series}
							type="radialBar"
							width={140}
							height={140}
						/>
					</div> */}
          <div className="numberOfPosbileCombinationsContainer">
            <h4 className="combinationsTitle">
              uniquely chosen combinations of benefits
            </h4>
            <ReactApexChart
              options={numberOfUniqueCombinations.options}
              series={numberOfUniqueCombinations.series}
              type="radialBar"
              width={140}
              height={140}
            />
          </div>
          <hr className="line" />
          <div style={{ width: "90%", margin: "20px auto 0 auto" }}>
            <h4 className="combinationsTitle" style={{ textAlign: "center" }}>
              Over 2 million possible combinations
            </h4>
          </div>
        </div>
      </Card>
    </PosibleCombinationsStyles>
  );
};

PosibleCombinations.propTypes = {
  companyName: PropTypes.string
};

export default PosibleCombinations;
