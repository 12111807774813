import React, { useContext } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// icons
import { EditIconSvg, TrashIcon } from "../../../../assets/Icons";
// styles
import BenefitGroupRowStyles from "./BenefitGroupRowStyles";
// services
import * as localBenefitsService from "../../../../services/api/hr/localBenefits.service";
import {
  addHeightOnClick,
  addCardView
} from "./benefitGroupRowAnimation.services";
import { getUrl } from "../../../../services/imageHandler";
// context
import { HrLocalBenefitsContext } from "../../../../modules/hr/hrLocalBenefits/context/localBenefits.context";

const BenefitGroupRow = ({
  benefitGroup,
  children,
  openModal,
  setChosenBenefitGroupId,
  setChosenBenefitId,
  fetchLocalBenefitGroups
}) => {
  // const {
  //   localBenefitsActions: { removeLocalBenefitGroup }
  // } = useContext(HrLocalBenefitsContext);
  return (
    <BenefitGroupRowStyles
      id={`benefitGroupRow${benefitGroup.id}`}
      className="benefitGroupRow"
    >
      <div
        className="benefitRowContainer"
        onClick={() => {
          setChosenBenefitId(undefined);
          setChosenBenefitGroupId(benefitGroup.id);
          addHeightOnClick(benefitGroup.id);
          addCardView(benefitGroup.id);
        }}
      >
        <div className="imgAndTitle">
          <div className="imgContainer">
            <img
              src={getUrl(benefitGroup.photo)}
              alt="BenefitGroup"
              className="image"
            />
          </div>
          <div className="providerNameAndCategory">
            <h2 className="providerName">{benefitGroup.name}</h2>
            <p className="category">{benefitGroup.benefits.length} Benefits</p>
          </div>
        </div>
        <div className="actionsContainer">
          <div
            onClick={e => {
              e.stopPropagation();
              setChosenBenefitGroupId(benefitGroup.id);
              openModal("editLocalBenefitGroup");
            }}
          >
            <EditIconSvg />
          </div>
          <div
            onClick={async e => {
              e.stopPropagation();
              if (benefitGroup.benefits.length > 0) {
                toast.info(
                  "In order to delete this benefit group please first delete all benefits belonging to this benefit group."
                );
              } else {
                const result = confirm(
                  "Are you sure you want to delete this benefit group?"
                );
                if (result) {
                  const response = await localBenefitsService.deleteBenefitGroup(
                    benefitGroup.id
                  );

                  if (response.hasEror) {
                    return toast.error(response.error.data.error.message);
                  }

                  fetchLocalBenefitGroups();
                }
              }
            }}
          >
            <TrashIcon stroke="#8f8f8f" />
          </div>
        </div>
      </div>
      {children}
    </BenefitGroupRowStyles>
  );
};
BenefitGroupRow.propTypes = {
  benefitGroup: PropTypes.shape(),
  openModal: PropTypes.func,
  setChosenBenefitId: PropTypes.func,
  setChosenBenefitGroupId: PropTypes.func,
  children: PropTypes.node
};

export default BenefitGroupRow;
