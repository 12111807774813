import React from "react";
import InfoPopUp from "../../../../../components/InfoPopUp/InfoPopUp";

export const tableHeaderListHREmployees = [
  [
    {
      id: 0,
      field: "blanco1",
      headName: "",
      isSortedUp: null,
      width: "42px",
      justifyContent: "flex-start",
      isSortable: false,
      orderPriority: 0
    },
    {
      id: 1,
      field: "name",
      headName: "Name",
      isSortedUp: null,
      width: "34%",
      justifyContent: "flex-start",
      margin: "0 0 0 46px",
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 2,
      field: "tier",
      headName: "Tier",
      isSortedUp: null,
      width: "22%",
      justifyContent: "flex-start",
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 3,
      field: "tokens",
      headName: "Budget",
      isSortedUp: null,
      width: "20%",
      justifyContent: "center",
      isSortable: false,
      orderPriority: 0,
      Component: (
        <InfoPopUp
          text="Remaining tokens / Total number of tokens"
          id="popUp"
        />
      )
    },
    {
      id: 4,
      field: "used_budget_percentage",
      headName: "Spent(%)",
      isSortedUp: null,
      width: "21%",
      justifyContent: "center",
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 5,
      field: "is_verified",
      headName: "Verified",
      isSortedUp: null,
      width: "20%",
      justifyContent: "center",
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 6,
      field: "email",
      headName: "Email",
      isSortedUp: null,
      width: "30%",
      justifyContent: "flex-start",
      isSortable: true,
      orderPriority: 0,
      padding: "0 0 0 21px"
    },
    {
      id: 7,
      field: "blanco",
      headName: "",
      isSortedUp: null,
      width: "35px",
      justifyContent: "flex-start",
      isSortable: false,
      orderPriority: 0
    }
  ],
  [
    {
      id: 1,
      field: "name",
      headName: "Name",
      isSortedUp: null,
      width: "48%",
      justifyContent: "flex-start",
      margin: "",
      isSortable: true,
      orderPriority: 0,
      margin: "0 0.5% 0 75px"
    },
    {
      id: 2,
      field: "tier",
      headName: "Tier",
      isSortedUp: null,
      width: "10%",
      justifyContent: "flex-start",
      margin: "0 0 0 1%",
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 3,
      field: "tokens",
      headName: "Budget",
      isSortedUp: null,
      width: "25%",
      justifyContent: "flex-start",
      margin: "0 0 0 4%",
      isSortable: false,
      orderPriority: 0,
      Component: (
        <InfoPopUp
          text="Remaining tokens / Total number of tokens"
          id="popUp"
        />
      )
    },
    {
      id: 4,
      field: "used_budget_percentage",
      headName: "Spent(%)",
      isSortedUp: null,
      width: "17%",
      justifyContent: "flex-start",
      margin: "0 0 0 1%",
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 5,
      field: "is_verified",
      headName: "Verified",
      isSortedUp: null,
      width: "15%",
      justifyContent: "flex-start",
      margin: "0 0 0 1%",
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 6,
      field: "blanco",
      headName: "",
      isSortedUp: null,
      width: "2%",
      justifyContent: "flex-start",
      margin: "0 0 0 3%",
      isSortable: false,
      orderPriority: 0
    }
  ],

  [
    {
      id: 1,
      field: "name",
      headName: "Name",
      isSortedUp: null,
      width: "48%",
      justifyContent: "flex-start",
      margin: "",
      isSortable: true,
      orderPriority: 0,
      margin: "0 0.5% 0 51px"
    },
    {
      id: 2,
      field: "tier",
      headName: "Tier",
      isSortedUp: null,
      width: "15%",
      justifyContent: "flex-start",
      margin: "0 0 0 1%",
      isSortable: true,
      orderPriority: 0
    },
    {
      id: 3,
      field: "tokens",
      headName: "Budget",
      isSortedUp: null,
      width: "30%",
      justifyContent: "flex-start",
      margin: "0 0 0 4%",
      isSortable: false,
      orderPriority: 0,
      Component: (
        <InfoPopUp text="Remaining tokens/Total number of tokens" id="popUp" />
      )
    },
    {
      id: 4,
      field: "is_verified",
      headName: "Verified",
      isSortedUp: null,
      width: "15%",
      justifyContent: "flex-start",
      margin: "0 0 0 1%",
      isSortable: true,
      orderPriority: 0
    }
  ],
  []
];
