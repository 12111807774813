import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { toast } from "react-toastify";
// Style
import VoucherReimbursementStyles from "./VoucherReimbursementStyles";

// Services
import * as employeeBenefitsService from "../../../../services/api/employee/employeeBenefits.services";

// Components
import Button from "../../../../components/button";
// Utils
import dateFormater from "../../../../services/dateFormater";
import { TOKEN_VALUE } from "../../../../builders/benefits/tokenValue";
import { useSelector } from "react-redux";

const VoucherReimbursement = ({ setShowPDFBill, voucherReimbursementData }) => {
  const [isUpload, setIsUpload] = useState(false);

  const companyId = useSelector(state => state.app.user.companyId);

  const handleSubmitFile = async () => {
    employeeBenefitsService.uploadPDF(values, companyId);

    setTimeout(() => {
      setShowPDFBill(false);
    }, 500);
  };

  const formik = useFormik({
    initialValues: {
      pdfFile: undefined,
      voucherId: voucherReimbursementData.voucher_id,
      benefitGroupId: voucherReimbursementData.benefit_group_id
    },
    onSubmit: handleSubmitFile
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur
  } = formik;

  const handleUpload = () => {
    document.getElementById("pdf").click();
    setIsUpload(true);
  };

  const uploadPDF = e => {
    if (e.target.files.length > 0) {
      setFieldValue(e.target.name, e.target.files[0]);
    }
  };

  return (
    <VoucherReimbursementStyles>
      <div className="recipe" id="content">
        <h2>Refund voucher</h2>
        <p>Voucher name: {voucherReimbursementData.benefit_name}</p>
        <p>
          Voucher price: {voucherReimbursementData.benefit_price * TOKEN_VALUE}{" "}
          RSD
        </p>
        <p>
          Date: {dateFormater(voucherReimbursementData.created_at.toString())}
        </p>
        <br />
        <h2>User information</h2>
        <p>Email: {voucherReimbursementData.email}</p>
        <p>User: {voucherReimbursementData.name}</p>
      </div>
      <form
        className="recipeButtons"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <div className="uploadButtonContainer">
          <h2>Upload the receipt</h2>
          <input
            type="file"
            name="pdfFile"
            id="pdf"
            onClick={e => {
              e.target.value = null;
            }}
            accept=".pdf, .jpg, .jpeg, .png, .JPG, .PNG, .JPEG, .PDF"
            onChange={e => {
              const fileName = e.target.value;
              const regex = /\.(pdf|jpg|jpeg|png|JPG|PNG|JPEG|PDF)$/;

              if (!regex.test(fileName)) {
                toast.error("Invalid file format. Please select a valid file.");
                e.target.value = null;
                return;
              }

              uploadPDF(e);
            }}
            style={{ display: "none" }}
          />
          <Button
            onClick={handleUpload}
            onBlur={() => handleBlur("pdfFile", true)}
            margin="30px auto"
            width="122px"
            customClass="mobileButton"
          >
            Upload
          </Button>
          <div style={{ marginLeft: 15 }}>
            {values.pdfFile && (
              <p className="fileName">{values.pdfFile.name}</p>
            )}
            {/*errors.pdfFile && (
              <p className="errorMsg" style={{ height: 10 }}>
                {errors.pdfFile && touched.pdfFile
                  ? errors.pdfFile
                  : ""}
              </p>
            )*/}
          </div>
        </div>
        <div className="submitButtonContainer">
          <Button
            margin="30px auto"
            width="250px"
            mobileWidth="100%"
            disabled={!isUpload}
            onClick={handleSubmit}
            customClass="mobileButton"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </VoucherReimbursementStyles>
  );
};

VoucherReimbursement.propTypes = {
  setShowPDFBill: PropTypes.func,
  voucherReimbursementData: PropTypes.shape({
    user_id: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
    benefit_group_name: PropTypes.string,
    benefit_name: PropTypes.string,
    benefit_price: PropTypes.number,
    quantity: PropTypes.number,
    created_at: PropTypes.object
  })
};

export default VoucherReimbursement;
