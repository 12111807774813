const employeeActiveBenefitsTableHead = [
	{
		id: 1,
		headName: 'Name',
		isSortedUp: false,
		width: '29%',
		justifyContent: 'flex-start'
	},
	{
		id: 2,
		headName: 'Provider',
		isSortedUp: false,
		width: '23%',
		justifyContent: 'flex-start'
	},
	{
		id: 3,
		headName: 'Benefit Group',
		isSortedUp: false,
		width: '25%',
		justifyContent: 'flex-start',
	},
	{
		id: 4,
		headName: 'Tokens',
		isSortedUp: false,
		width: '10%',
		justifyContent: 'flex-start',
		margin: '0'
	},
	{
		id: 5,
		headName: 'Expiration',
		isSortedUp: false,
		width: '10%',
		justifyContent: 'flex-start',
		margin: ''
	}
]

export default employeeActiveBenefitsTableHead
