import React from "react";
import PropTypes from "prop-types";

import ProviderToggleButtonComponentStyles from "./ProviderToggleButton.styles";

const ToggleButton = ({ isActive, onToggle }) => (
  <ProviderToggleButtonComponentStyles isActive={isActive}>
    <div className="activationCheckBox">
      {isActive && <p className="activityOnText">ON</p>}
      <div className="dot" onClick={onToggle} />
      {!isActive && <p className="activationOffText">OFF</p>}
    </div>
    {isActive ? (
      <p className="benefitActivityStatusText">Provider is enabled</p>
    ) : (
      <p className="benefitActivityStatusText">Provider is disabled</p>
    )}
  </ProviderToggleButtonComponentStyles>
);

ToggleButton.propTypes = {
  isActive: PropTypes.bool,
  onToggle: PropTypes.func
};

export default ToggleButton;