export const EXPIRATION_OPTIONS = [
  {
    id: 1,
    name: "month"
  },
  {
    id: 2,
    name: "one time"
  },
  {
    id: 3,
    name: "voucher"
  }
];
