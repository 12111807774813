import React from "react";
import { toast } from "react-toastify";

import BenefitDetailsActionsStyles from "./BenefitDetailsActionsStyles";

// Services
import * as providersService from '../../../../../../services/api/admin/providers.service';

const BenefitDetailsActions = ({
  openEditBenefit,
  benefitId,
  isDraft,
  hideTooltip,
  fetchData
}) => {

  /**
   * Published drafted benefit.
   * If request fails, returns toast error message.
   * If request is successfull, fires toast message, closes modal & refetches data.
   */
  const publishBenefit = async () => {
    const response = await providersService.publishBenefit(benefitId);

    if (response.hasError) {
      return toast.error('Benefit publishing failed');
    }

    toast.success('Successfully published benefit');
    fetchData();
  }

  return (
    <BenefitDetailsActionsStyles>
      {isDraft && (
        <button
          type="button"
          className="button"
          onClick={e => {
            e.stopPropagation();
            hideTooltip();
            publishBenefit();
          }}
        >
          Publish
        </button>
      )}
      <button
        type="button"
        className="button"
        style={{ textAlign: 'center' }}
        onClick={e => {
          e.stopPropagation();
          hideTooltip();
          openEditBenefit();
        }}
      >
        Edit
      </button>
    </BenefitDetailsActionsStyles>
  );
};

export default BenefitDetailsActions;
