import React, { useState } from "react";
import styled from "styled-components";
import { Card, Button, SelectInput, InputLabel } from "../../../../components";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";
import { reportTypeDropdownOptions } from "../../../SuperAdminPages/Dashboard/reportTypeDropdownOptions";
import { connect, useSelector } from "react-redux";
import debounce from "../../../../utils/helpers/debounce";

import {
  setDownloadPercentage,
  setIsRefundDownloading
} from "../../../../actions/app.actions";

const UsageReportGeneratorStyles = styled.div`
  margin: 0 20px 20px 0;
  order: 7;
  width: 400px;
`;

const RefundReport = props => {
  const {
    setDownloadPercentage,
    isDownloading,
    setIsRefundDownloading,
    downloadPercent
  } = props;

  const [reportType, setReportType] = useState(undefined);

  const companyId = useSelector(state => state.app.user.companyId);

  const getStatistics = () => {
    setIsRefundDownloading(true);
    dashboardService.getRefundStatistics(
      reportType,
      companyId,
      setIsRefundDownloading,
      setDownloadPercentage
    );
  };

  const debouncedGetStatistic = debounce(getStatistics, 777);

  return (
    <UsageReportGeneratorStyles>
      <Card>
        <div className="titleContainer">
          <h3 className="title">Generate refund report</h3>
        </div>
        {isDownloading ? (
          <div>
            <div
              style={{
                height: "122px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <div>Downloading: {downloadPercent}%</div>
            </div>
          </div>
        ) : (
          <>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <InputLabel label="Select report type" margin="0 0 5px 0" />
              <SelectInput
                name="reportTypes"
                options={reportTypeDropdownOptions}
                selectedValues={reportTypeDropdownOptions}
                value={reportType}
                handleChange={option => setReportType(option.id)}
                customId="reportTypeId"
                shouldHaveHight={false}
                shouldHaveFullWidth
                customHeight="27px"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                margin="0"
                width="200px"
                disabled={!reportType || isDownloading}
                onClick={debouncedGetStatistic}
              >
                Download
              </Button>
            </div>
          </>
        )}
      </Card>
    </UsageReportGeneratorStyles>
  );
};

const mapStateToProps = state => {
  return {
    isDownloading: state.app.isDownloading,
    downloadPercent: state.app.downloadPercent
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsRefundDownloading: payload =>
      dispatch(setIsRefundDownloading(payload)),
    setDownloadPercentage: payload => dispatch(setDownloadPercentage(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundReport);
