import React, { useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import { BeatLoader } from 'react-spinners';
//components
import { TextAndLine, Button} from "../../../../components";
import DetailsSection from "./detailsSection";
// services
import * as settingsService from "../../../../services/api/hr/settings.service";
import { handleCompanyThemeFormError } from '../../../../services/errors/hr/CompanyThemeFormError';
import { validateCompanyThemeForm } from '../../../../services/validations/hr/companyThemeFormValidation';
import { isEmpty } from '../../../../services/general.utils';
//actions
import * as appActionCreators from '../../../../actions/app.actions';
//styles
import CompanyThemeFormStyles from './CompanyThemeForm.styles';
import { subscribe } from "../../../../services/loadingOverride.styles";

const CompanyThemeForm = ({setUserThemes, theme}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const handleSubmitForm = async (service, message = '') => {
    setIsLoading(true);
    setDisableButton(true);
    const response = await service;
    setDisableButton(false);
    setIsLoading(false);
    setIsDelete(false);
    if (response.hasError) {
      return handleCompanyThemeFormError(response);
    }
    if (message) {
      setUserThemes([response]);
      toast.success(message);      
    } else {
      setUserThemes([]);
      toast.success(response.data.message);
    }    
    return response;
  }

  const addCompanyThemeSubmit = async values => {
    await handleSubmitForm(settingsService.addCompanyTheme(values), 'Company theme added successfully');  
  };

  const updateCompanyThemeSubmit = async values => {
    await handleSubmitForm(settingsService.editCompanyTheme(values.id, values),'Company theme updated successfully');           
  }

  const removeCompanyThemeSubmit = async values => {
    await handleSubmitForm(settingsService.removeCompanyTheme(values.id), '');
  }

  const handleCancel = () => {
    for(let el in values){
      setFieldValue(el, theme[el]);
    }
    setIsDelete(false);
  }

  const handleOnSubmit = () => isEmpty(theme.photo) ? addCompanyThemeSubmit(values) 
    : isDelete && isEmpty(values.photo) ? removeCompanyThemeSubmit(values) : updateCompanyThemeSubmit(values);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: theme,
    validate: !isDelete && validateCompanyThemeForm,
    onSubmit: handleOnSubmit,
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleChange,
    handleBlur
  } = formik;
  
  const buttonText = isEmpty(theme.photo) ? "Add logo" : isDelete && isEmpty(values.photo) ? "Remove" : "Update";

  return(
    <CompanyThemeFormStyles>
      <TextAndLine title="company brand settings" />
      <div className="themeListContainer">
        <form
          autoComplete="off"
          encType="multipart/form-data"
          onSubmit={e => handleSubmit(e)}
        >
          <div className="photoContainer">     
             <DetailsSection
              isFormDisplay
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setFieldError={setFieldError}
              setIsDelete = {setIsDelete}
            />
          </div>
          <div style={{display: 'flex'}}>
            {disableButton ? (
              <Button width="130px" disabled margin="0 30px 0 0">
                Please wait
              </Button>
            ) : (              
              <Button
                type="submit"
                width="130px"
                margin="0 30px 0 0"
                padding="10px 25px"
              >
                {buttonText}
              </Button>              
            )}
            <Button
              type="button"
              onClick={handleCancel}
              width="130px"
              padding="10px 18px"
              margin="0"
              outline
            >
              Cancel
            </Button>
          </div>
            <BeatLoader
              css={subscribe}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
        </form>
      </div>
    </CompanyThemeFormStyles>
  )
}

CompanyThemeForm.propTypes = {
  setUserThemes: PropTypes.func,
  theme: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      mode: PropTypes.oneOf(['light', 'dark', 'custom']),
      photo: PropTypes.oneOfType([
        PropTypes.shape({}),
        PropTypes.instanceOf(null)
      ]),
      companyId: PropTypes.number,
      color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(null)
      ]),
    }), 
    PropTypes.instanceOf(null)
  ]),
};

const mapDispatchToProps = dispatch => {
  return {    
    setUserThemes: payload =>
      dispatch(appActionCreators.setUserThemes(payload)),
    };
};

export default connect(
  null,
  mapDispatchToProps
)(CompanyThemeForm);