import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Pagination from "react-pagination-js";
// components
import {
  Header,
  Button,
  Card,
  SearchInput,
  MultiselectInput,
  SortSelectInput,
  Modal,
  openModalAnim,
  closeModalAnim
} from "../../../components";
import ProviderRow from "./ProviderRow/ProviderRow";
import PreviewModal from "./PreviewModal/PreviewModal";
import {
  FormRenderer,
  FormModal,
  openModalAnimForm,
  closeModalAnimForm
} from "./shared";
import SubRow from "./SubRow/SubRow";
// styles
import ProvidersStyles from "./ProvidersStyles";
import { override } from "../../../services/loadingOverride.styles";
// services
import { collapseSection } from "./providersAnimations";
import * as actionCreators from "../../../actions/admin/providers.actions";
import * as providersService from "../../../services/api/admin/providers.service";
import * as employeeService from '../../../services/api/employee/employeeBenefits.services';
// context
import { UserContext } from "../../../modules/user/context/user.context";
import { ProvidersContext } from "../../../modules/superAdmin/providers/context/providers.context";
// hooks
import { useDisableBodyScroll } from "../../../hooks/useDisableBodyScroll";
// Utils
import { downloadCsvFromBase64EncodedString } from "../../../services/files.utils";
import { isEmpty } from "../../../services/general.utils";

const LIMIT = 4;

const Providers = ({
  providers,
  setInitProviders,
  count,
  page,
  search,
  setProvidersPage,
  setProvidersSearch
}) => {
  const [modalType, setModalType] = useState("");
  const [chosenProviderId, setChosenProviderId] = useState(null);
  const [chosenBenefitGroupId, setChosenBenefitGroupId] = useState(null);
  const [chosenBenefitId, setChosenBenefitId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setOrder] = useState('');
  const [sortKey, setKey] = useState('');
  const [categories, setCategories] = useState([]);
  const [chosenCategories, setChosenCategories] = useState([]);
  const [chosenCategoryIds, setChosenCategoryIds] = useState([]);

  useDisableBodyScroll(modalType);

  const areFiltersLoaded = useRef(false);

  const sortOptions = [
    {
      id: 1,
      name: 'Created At',
      key: 'created'
    },
    {
      id: 2,
      name: 'Provider name',
      key: 'name'
    }
  ]

  /**
   * Handles altering of sorting direction.
   * Sets sort order to state.
   * Sets sort key to state.
   * @param {object} option 
   */
  const handleSelectingSortOption = (option) => {
    if (isEmpty(option)) {
      setKey('');
      setOrder('');
      return;
    }

    if (sortOrder === '') {
      setOrder('DESC')
    }
    if (sortOrder === 'DESC') {
      setOrder('ASC')
    }
    if (sortOrder === 'ASC') {
      setOrder('DESC')
    }
    setKey(option.key);
  }

  // const { user } = useContext(UserContext);
  // const { providersState, providersActions } = useContext(ProvidersContext);

  const isMounted = useRef(false);

  const openModal = type => {
    if (type !== 'preview') {
      setModalType(type);
      setTimeout(() => {
        openModalAnimForm();
      }, 100);
    } else {
      setModalType(type);
      setTimeout(() => {
        openModalAnim();
      }, 100);
    }
  };

  const closeModalForm = () => {
    closeModalAnimForm();
    setTimeout(() => {
      setModalType("");
    }, 600);
  };

  const closeModal = () => {
    closeModalAnim();
    setTimeout(() => {
      setModalType("");
    }, 600);
  };

  const fetchCategories = async () => {
    const categoriesResponse = await employeeService.getCategories();

    if (categoriesResponse.hasError) {
      return toast.error(
        categoriesResponse.errorMessage
          ? categoriesResponse.errorMessage
          : "Fetching Categories failed."
      );
    }

    setCategories(categoriesResponse.categories);

    areFiltersLoaded.current = true;
  }

  /**
 * Handles select of element from multiselect.
 * Sets selected elements' ids to state.
 * @param {Category} options - Selected options, emmited from multiselect.
 */
  const handleSetChosenCategories = (options) => {
    let selectedCategoryIds = [];

    if (options && options.length) {
      selectedCategoryIds = options.map(category => category.id);
    }

    setChosenCategories(options);
    setChosenCategoryIds(selectedCategoryIds);
  };

  const fetchProviders = async (searchText = search, order = sortOrder, key = sortKey, categoryIds = chosenCategoryIds) => {
    const response = await providersService.getInitProviders({
      limit: LIMIT,
      page,
      searchText,
      order,
      key,
      categoryIds
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching providers failed"
      );
    }

    if (isMounted.current) {
      setInitProviders(response.providers, response.count);
      setIsLoading(false);
      setTimeout(() => {
        const providersList = document.getElementById("providersList");
        if (isMounted.current && providersList) {
          providersList.style.opacity = 1;
        }
      }, 100);
    }
  };

  const getVendorReport = async () => {
    const response = await providersService.getVendorReport();

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching report failed"
      );
    }

    downloadCsvFromBase64EncodedString(response.data.providerBuffer, 'providers.csv');
    downloadCsvFromBase64EncodedString(response.data.benefitGroupBuffer, 'benefit_groups.csv');
    downloadCsvFromBase64EncodedString(response.data.benefitBuffer, 'benefits.csv');
  }

  useEffect(() => {
    isMounted.current = true;

    fetchProviders();
    fetchCategories();
  }, [page]);


  useEffect(() => {
    if (areFiltersLoaded.current === true)
      fetchProviders();
  }, [chosenCategoryIds, sortOrder]);

  useEffect(() => {
    window.onresize = () => {
      const allProviderRows = document.getElementsByClassName("row");
      const allSubrows = document.getElementsByClassName("testDiv");
      for (let i = 0; i < allSubrows.length; i++) {
        if (allSubrows[i]) {
          collapseSection(allSubrows[i]);
        }
      }

      for (let i = 0; i < allProviderRows.length; i++) {
        if (
          allProviderRows[i] &&
          allProviderRows[i].classList.contains("cardView")
        ) {
          allProviderRows[i].classList.remove("cardView");
        }
      }
    };

    return () => {
      window.onresize = null;
      isMounted.current = false;
    };
  }, []);

  return (
    <ProvidersStyles>
      <div className="providersContainer">
        <Header headerTitle="Providers" />
        <div className="providersContent">
          {isLoading ? (
            <div
              style={{
                height: "calc(100vh - 180px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <BeatLoader
                css={override}
                size={25}
                color="#123abc"
                loading={isLoading}
              />
            </div>
          ) : (
            <Card id="providersList">
              <div
                style={{
                  marginBottom: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <div className="filtersHeader">
                  <Button margin="0" onClick={() => openModal("addProvider")}>
                    <div className="plusContainer">
                      <p className="buttonText">+</p>
                    </div>
                    Add Provider
                  </Button>
                  <div className="sortInputWrapper">
                    <SortSelectInput
                      name="sorting"
                      options={sortOptions}
                      sortKey={sortKey}
                      sortOrder={sortOrder}
                      placeholder="Sort By"
                      handleChange={option => handleSelectingSortOption(option)}
                    />
                  </div>
                  <MultiselectInput
                    name="categories"
                    options={categories}
                    placeholder='Filter by group category'
                    handleChange={option => handleSetChosenCategories(option)}
                    selectedValues={chosenCategories}
                  />
                </div>
                <SearchInput
                  fetchData={fetchProviders}
                  setSearch={setProvidersSearch}
                  search={search}
                  customClass='providersSearch'
                  setPagginationPage={setProvidersPage}
                />
              </div>
              {providers.map(provider => (
                <ProviderRow
                  key={provider.id}
                  provider={provider}
                  openModal={openModal}
                  setChosenProvider={() => setChosenProviderId(provider.id)}
                  setChosenBenefitGroup={setChosenBenefitGroupId}
                  setChosenBenefit={setChosenBenefitId}
                >
                  <SubRow
                    provider={provider}
                    chosenBenefitId={chosenBenefitId}
                    chosenBenefitGroupId={chosenBenefitGroupId}
                    openModal={openModal}
                    setChosenBenefitGroupId={setChosenBenefitGroupId}
                    setChosenBenefitId={setChosenBenefitId}
                    fetchData={fetchProviders}
                  />
                </ProviderRow>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10
                }}
              >
                <Pagination
                  currentPage={page}
                  totalSize={count}
                  sizePerPage={LIMIT}
                  changeCurrentPage={page => setProvidersPage(page)}
                  theme="border-bottom"
                />
              </div>
              <Button margin="0" onClick={() => getVendorReport()}>
                Download Vendor Report
              </Button>
            </Card>
          )}
        </div>
      </div>
      {modalType !== "" && (
        <>
          {modalType !== 'preview' ? (
            <FormModal closeModal={closeModalForm}>
              <FormRenderer
                setChosenBenefitGroupId={setChosenBenefitGroupId}
                modalType={modalType}
                providerId={chosenProviderId}
                benefitGroupId={chosenBenefitGroupId}
                benefitId={chosenBenefitId}
                closeModal={closeModal}
                fetchProviders={fetchProviders}
                setCurrentPage={setProvidersPage}
              />
            </FormModal>
          ) :
            <Modal closeModal={closeModal}>
              <PreviewModal benefitGroupId={chosenBenefitGroupId} />
            </Modal>
          }
        </>
      )}
    </ProvidersStyles>
  );
};

const mapStateToProps = state => {
  return {
    providers: state.providers.providers,
    count: state.providers.count,
    page: state.providers.page,
    search: state.providers.search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setInitProviders: (providers, count) =>
      dispatch(actionCreators.setInitProviders(providers, count)),
    setProvidersPage: page => dispatch(actionCreators.setProvidersPage(page)),
    setProvidersSearch: search => dispatch(actionCreators.setProvidersSearch(search))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
