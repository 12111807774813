export const totalBudgetConfig = {
  series: [0, 0],
  options: {
    chart: {
      type: 'donut'
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#4880ff', '#ffc400'],
    fill: {
      type: 'gradient'
    },
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'left'
    },
    tooltip: {
      y: {
        // formatter(val) {
        // 	return `${val} ${renderToString(<TokenIcon height="16" />)}`
        // }
        formatter(val) {
          return `${val} RSD`;
        }
      }
    },
    labels: ['Chosen month', 'Previous month']
  }
};
