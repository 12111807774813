import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// components
import { SelectInput, CheckboxInput, InputLabel } from "../../../../components";
// services
import * as employeesService from "../../../../services/api/hr/employees.service";
// styles
import RankStyles from "./RankStyles";

const Rank = ({
  isFormDisplay = false,
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue = () => { }
}) => {
  const [companyTiers, setCompanyTiers] = useState([]);
  const isMounted = useRef(true);

  const fetchTiers = async () => {
    const response = await employeesService.getCompanyTiers();

    if (response.hasError) {
      return toast.error(response.error.data.error.message);
    }

    if (isMounted.current) {
      setCompanyTiers(response.tiers);
    }
  };

  useEffect(() => {
    if (isFormDisplay) {
      fetchTiers();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const displayInfo = () => {
    let desc = "";
    let token = 0;
    const chosenTier = companyTiers.find(singleTier =>
      typeof values.tier === "object"
        ? singleTier.id === values.tier.id
        : singleTier.id === values.tier
    );
    if (chosenTier) {
      if (chosenTier.description !== "") {
        desc = chosenTier.description;
      }
      if (chosenTier.tokens) {
        token = chosenTier.tokens;
      }
    }
    return {
      desc,
      token
    };
  };

  const handleUserHrRoleCheckbox = () => {
    if (isFormDisplay)
      setFieldValue('shouldEmployeeHaveHRRole', !values.shouldEmployeeHaveHRRole)
  }

  return (
    <RankStyles>
      <div className="tierAndInfoContainer">
        <div className="tierContainer">
          <p className="title">Tier :</p>       
            <div className="labelAndValidation">
              {isFormDisplay && (
                <div className="errorMsg" style={{ marginBottom: 5 }}>
                  {errors.tier && touched.tier ? errors.tier : ""}
                </div>
              )}
            </div>
            {companyTiers.length > 0 && isFormDisplay ? (
              <SelectInput
                name="tier"
                onBlur={handleBlur}
                options={companyTiers}
                selectedValues={companyTiers}
                value={
                  typeof values.tier === "object" ? values.tier.id : values.tier
                }
                handleChange={option => setFieldValue("tier", option.id)}
                customHeight='28px'
                shouldHaveFullWidth
              />
            ) : (
              <div className="tierContainerResult">
                {values.tier && values.tier.name}
              </div>
            )}
        </div>
        <div className="infoContainer">
          <p className="title">Info :</p>
          {isFormDisplay ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                justifyContent: "flex-end"
              }}
            >
              <p className="text" style={{ height: 45 }}>
                {displayInfo().desc}
              </p>
              <p className="text" style={{ height: "unset" }}>
                Monthly tokens: {displayInfo().token}
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                justifyContent: "flex-end"
              }}
            >
              <p className="text" style={{ height: 45 }}>
                {values.tier ? values.tier.description : ""}
              </p>
              <p className="text" style={{ height: "unset" }}>
                Monthly tokens: {values.tier ? values.tier.tokens : 0}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="hrCheckbox">
        <CheckboxInput
          isChecked={values.shouldEmployeeHaveHRRole}
          onChange={() => handleUserHrRoleCheckbox()}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 24
          }}
        >
          <InputLabel
            customCssClass="labelStyles"
            margin="0 0 0 10px"
            label="Enable HR Role"
            shouldHaveColon={false}
          />
        </div>
      </div>
    </RankStyles>
  );
};

Rank.propTypes = {
  isFormDisplay: PropTypes.bool,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func
};

export default Rank;
