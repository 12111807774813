export const validateCompanyThemeForm = ({
	photo,
}) => {
	const errors = {}
	
	if (!photo) {
		errors.photo = '* Required'
	}

	return errors
}
