import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { useFormik } from "formik";
import StarRatings from "react-star-ratings";

//entities
import BenefitGroupReview from '../../../models/domain/BenefitGroupReview.entity';
//components
import { SelectInput } from "../..";
// actions
import * as actionCreators from "../../../actions/employee/employeeBenefits.actions";
//services
import * as api from "../../../services/api/employee/employeeBenefits.services";
import * as benefitGroupService from '../../../services/api/admin/benefitGroups.service';
import { uniqueArrayOfObjects } from '../../../services/general.utils';
//styles
import { LeaveReviewFormStyles } from "./LeaveReviewFormStyles";

const LeaveReviewForm = ({
  usedBenefits, 
  setUsedBenefits, 
  closeModal, 
  benefitGroup, 
  updateBenefitGroup, 
  setChosenBenefitGroup,
  setSubscribedBenefitGroups
}) => {
  const [rating, setRating] = useState(0);

  useEffect(() => {      
    setFieldValue("rating", rating);
  }, [rating])

  const leaveReview = async values => {
    if (values.rating === 0) {
      return toast.error('You must select rating');
    }

    const response = await api.createBenefitGroupReview(values);
  
    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : "Leaving Review failed"
      );
    } 

    const [ 
      benefitGroupResponse, 
      usedBenefitsResponse,
      subscribedBenefitGroupsResponse,
    ] = await Promise.all([ 
      benefitGroupService.refreshBenefitGroup(benefitGroup.id),
      api.getAlreadyUsedBenefits(benefitGroup.id),      
      api.getSubscribedBenefits(),
    ])

    if (benefitGroupResponse.hasError) {
      return toast.error(
        benefitGroupResponse.errorMessage
          ? benefitGroupResponse.errorMessage
          : "Fetching Benefit group failed."
      );
    };

    if (usedBenefitsResponse.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching Used Benefits failed."
      );
    }

    if (subscribedBenefitGroupsResponse.hasError) {
      return toast.error(
        subscribedBenefitGroupsResponse.errorMessage
          ? subscribedBenefitGroupsResponse.errorMessage
          : "Fetching Subscribed Benefit groups failed."
      );
    }
    
    toast.success('Review successfully sent!');

    setUsedBenefits(uniqueArrayOfObjects(usedBenefitsResponse.benefits));
    setChosenBenefitGroup(benefitGroupResponse);
    updateBenefitGroup(benefitGroupResponse);
    setSubscribedBenefitGroups(subscribedBenefitGroupsResponse.groups);

    closeModal();
  };

  const formik =  useFormik( {
    initialValues: new BenefitGroupReview( {
      benefitId: usedBenefits[0].id, 
      benefitGroupId: usedBenefits[0].benefitGroupId 
    }),
    onSubmit: leaveReview
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue
  } = formik;

    
  return (
    <LeaveReviewFormStyles>
      <form onSubmit={handleSubmit} autoComplete="off"> 
        <button className="closeButton" type="button"	onClick={closeModal}>X</button>
        <div className="ratingTopContainer">
          <h2 className="ratingTitle">Rate this benefit</h2>
          <StarRatings
            numberOfStars={5}
            rating = {rating}
            starDimension="40px"
            starSpacing="0px"
            starEmptyColor="#EAEAEA"
            starRatedColor="#FFD402"
            starHoverColor="#FFD402"
            name="rating" 
            value={rating} 
            changeRating={(newRating) => {setRating(newRating)}}
            handleChange
          />
        </div>
        <div style={{ margin: "20px 0", height: "41px"}}>
          <SelectInput
            maxWidth        
            name="id"
            onBlur={handleBlur}
            options={usedBenefits}
            value={usedBenefits.find(benefit => benefit.id === values.benefitId)}
            handleChange={option => setFieldValue("benefitId", option.id)}
            customHeight="41px"
            shouldHaveFullWidth
          />
        </div>        
        <div className="ratingButtonsContainer">
          <button
            onClick={() => {
              setRating(0);
              closeModal();
            }}
            className="cancel"
            type="reset"
          >Cancel</button>
          <button
            className="post"
            type="submit"
          >Post</button>
        </div>
      </form>
    </LeaveReviewFormStyles>
  );
};

LeaveReviewForm.propTypes = {
  usedBenefits: PropTypes.arrayOf(PropTypes.shape({})),
  setUsedBenefits: PropTypes.func,
  closeModal: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    benefitGroup: state.employeeBenefitsPage.pageFrontEndStates.chosenBenefitGroup,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateBenefitGroup: benefitGroup => dispatch(actionCreators.updateBenefitGroup(benefitGroup)),
    setChosenBenefitGroup: benefitGroup => dispatch(actionCreators.setChosenBenefitGroup(benefitGroup)),      
    setSubscribedBenefitGroups: benefitGroups => dispatch(actionCreators.setSubscribedBenefitGroups(benefitGroups))
  
  };
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
  )(LeaveReviewForm)
