import React from "react";
import PropTypes from "prop-types";

import ArrowDown from "../../../assets/images/categoryFilters/rectangle.png";

import TableHeadColumnStyles from "./TableHeadColumnStyles";

const TableHeadColumn = ({
  tableHeadColName,
  isSortedUp = false,
  toggleSortFunc = () => {},
  colWidth,
  justifyContent,
  margin,
  isSortable = false,
  id,
  Component,
  fontSize = "16px",
  textAlign = "initial"
}) => (
  <TableHeadColumnStyles
    width={colWidth}
    justifyContent={justifyContent}
    margin={margin}
    isSortable={isSortable}
    fontSize={fontSize}
    textAlign={textAlign}
  >
    <p className="tableHeadColumnName">{tableHeadColName}</p>
    {isSortable && (
      <span className="arrowsContainer">
        <button
          onClick={() => toggleSortFunc(id)}
          type="button"
          className="arrowButtons"
        >
          <img
            src={ArrowDown}
            alt={isSortedUp ? "arrowDown" : "arrowUp"}
            className={isSortedUp ? "arrowImg" : "arrowUpImg"}
          />
        </button>
      </span>
    )}
    {Component && Component}
  </TableHeadColumnStyles>
);

TableHeadColumn.propTypes = {
  tableHeadColName: PropTypes.string,
  isSortedUp: PropTypes.bool,
  toggleSortFunc: PropTypes.func,
  colWidth: PropTypes.string,
  justifyContent: PropTypes.string,
  margin: PropTypes.string,
  isSortable: PropTypes.bool,
  id: PropTypes.number,
  fontSize: PropTypes.string
};

export default TableHeadColumn;
