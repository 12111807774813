import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
// components
import Button from "../button";
// styles
import DisableUsersModalStyles from './DisableUsersModalStyles';
// services
import * as api from "../../services/api/admin/users.service";
// Utils
import { startFutureMonthFromDate, formatToStartOfDay } from "../../services/date.utils";

const initalDate = startFutureMonthFromDate(new Date());

const DisableUsersModal = ({
  userIdsMap,
  closeModal,
  fetchData = () => { },  
  text,
  setSelectedNumber
}) => {
  const [date, setDate] = useState(initalDate);
 
  let userIds = [];
    
  userIdsMap.forEach((value, key) => {
    if(value) userIds.push(key);
  });

  const handleDateChange = (chosenDate) => {
    setDate(formatToStartOfDay(chosenDate));
  };

  const handleDisableUsersError = ({ error }) => {
    const { data } = error;
    
    if (data) {
      if (data.errors && data.error) {
        if (data.errors.scheduleDeleteAt) {
          toast.error(`${data.errors.scheduleDeleteAt.keys[0]}`);
        } else {
          toast.error(data.error.message);
        }
      } else {
        toast.error(data.error.message);
      }
    }
  };

  /**
   * Sends patch request to disable user.
   * Closes modal.
   * Refetches data from server.
   */
  const disableUsers = async () => {
    try {
      const response = await api.disableUsers({
        userIds,
        scheduleDeleteAt: date
      });
  
      if (response.hasError) {        
        return handleDisableUsersError(response);
      };

      userIdsMap.clear();
      setSelectedNumber(0);
      
      toast.success(response.data.message);
  
      setTimeout(() => {
        closeModal();
        fetchData();
      }, 200);  
    } catch (error) {
      toast.error(error.message);
    }
    
  }

  return (
    <DisableUsersModalStyles>
      <div className="contentWrapper">
        <h2 className="disableUserTitle">Disable {text}</h2>
        <p>Choose when you want the {text} to be deleted.</p>
        <p>If you wish to delete them immediately, select today's date.</p>
        <DatePicker
          className="datePickerInput"
          id="datePicker"
          selected={date}
          onChange={chosenDate => handleDateChange(chosenDate)}
          minDate={new Date()}
          value={date}
        />

        <Button
          onClick={async () => disableUsers()}
          margin="29px 0 0 0"
          width="140px"
        >
          Submit
        </Button>
      </div>
    </DisableUsersModalStyles>
  );
};

export default DisableUsersModal;
