import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import { InfoIconSvg } from "../../assets/Icons";

const Styles = styled.div`
  /* margin: 0 87px 0 0; */
  cursor: pointer;

  .tooltip {
    background: #ffffff !important;
    border: 1px solid #1568bf;
    color: #757575 !important;
    padding: 0;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: 0.2px;
    text-align: center;
    padding: 0 8px;

    .arrow {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      bottom: -7px;
      left: 50%;
      position: absolute;
      border-top: 8px solid white !important;
      margin-left: -3px !important;
      width: 10px;
      height: 10px;
      z-index: 2;
    }
  }

  .tooltip:after {
    border-top: 8px solid #1568bf !important;
    margin-left: -3px !important;

    .__react_component_tooltip.place-top {
      &:after {
        bottom: -8px !important;
      }
    }

    .__react_component_tooltip.show {
      opacity: 0.9 !important;
      margin-top: -10px !important;
      margin-left: 0px !important;
      visibility: visible;
    }
  }
`;

const InfoPopUp = ({ text, id }) => {
  return (
    <Styles>
      <a data-tip data-for={id}>
        <InfoIconSvg />
      </a>
      <ReactTooltip
        id={id}
        place="top"
        effect="solid"
        className="tooltip"
        backgroundColor="#1568bf"
        borderColor="#1568bf"
        arrowColor="#1568bf"
      >
        <span>{text}</span>
        <span className="arrow"></span>
      </ReactTooltip>
    </Styles>
  );
};

export default InfoPopUp;
