export const pieChart = {
  series: [],
  options: {
    chart: {
      width: 380,
      type: 'pie'
    },
    legend: {
      show: false,
      position: 'bottom'
    },
    labels: [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            show: false,
            position: 'bottom'
          }
        }
      }
    ],
    tooltip: {
      y: {
        // formatter(val) {
        // 	return `${val} ${renderToString(<TokenIcon height="16" />)}`
        // }
        formatter(val) {
          return `${val} RSD`;
        }
      }
    }
  }
};
