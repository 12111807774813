import React, { useEffect, useState } from "react";
import { TextAndLine } from "../../../../components";
import { LoadingFavoriteBenefits } from "./LoadingFavoriteBenefits";
import { EmptyFavoriteBenefits } from "./EmptyFavoriteBenefits";
import { apiService } from "../../../../services/api/api.service";
import { useDispatch, useSelector } from "react-redux";
import {
  setBenefitGroupType,
  setListOfFavorites
} from "../../../../actions/employee/employeeBenefits.actions";
import { toast } from "react-toastify";
import { ListOfFavoriteBenefits } from "./ListOfFavoriteBenefits";

export const FavoriteBenefits = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { listOfFavoriteBenefits } = useSelector(
    state => state.employeeBenefitsPage.favoriteBenefits
  );

  const fetchFavoritesData = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.get("/favorites");
      const listOfFavorite =
        response.data?.items.length > 0
          ? response.data.items.map(item => item.group)
          : [];

      dispatch(setListOfFavorites(listOfFavorite));
    } catch (error) {
      console.log(error);
      toast.error("Failed to get favorite benefits");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFavoritesData();
  }, []);

  const showContent = () => {
    if (isLoading) return <LoadingFavoriteBenefits />;

    if (listOfFavoriteBenefits.length > 0) {
      return (
        <ListOfFavoriteBenefits
          listOfFavoriteBenefits={listOfFavoriteBenefits}
        />
      );
    }

    return (
      <EmptyFavoriteBenefits
        textPlaceholder={"Currently there are no favorite benefits."}
      />
    );
  };

  return (
    <div>
      <TextAndLine title={"Favorite Benefits"} />
      <div>{showContent()}</div>
    </div>
  );
};
