import styled from "styled-components";

export const LeaveReviewFormStyles = styled.div`
      min-height: 180px;
      width: 100%;
      padding: 12px 0;

      .closeButton {
          width: 30px;
          height: 30px;
          color: rgba(0, 0, 0, 0.54);
          border: none;
          outline: none;
          font-size: 15px;
          position: absolute;
          z-index: 2;
          right: -10px;
          top: 0px;
          font-weight: 500;
          cursor: pointer;
          background: rgba(255, 255, 255, 0.6);
          border-radius: 50%;
        }

        .closeButton:hover {
          color: #1568bf;
          background: rgba(255, 255, 255, 0.8);
        }

        .ratingTopContainer {
            text-align: center;
            height: 75px;
            margin: 20px 0;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.2px;
            
            .ratingTitle {
                font-size: 18px;
                line-height: 19px;
                color: #354052;
                margin-bottom: 16px;
            }        
        }

      .ratingButtonsContainer {
        text-align: right;
        
        button {
          font-size: 16px;
          line-height: 20px;
          border: none;
          outline: none;
          background-color: transparent;  
          cursor: pointer;          
        }
        
        .cancel {
          color: #87919E;
        }
        
        .post {
          color: #2F68B9;  
          margin-left: 40px;
        }
        
        .itemContainer:hover {
            background-color: red;
        }
      }
`;
