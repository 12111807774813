import React, { useContext } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// assets
import { EditIconSvg, TrashIcon } from "../../../../../assets/Icons";
import StarIcon from "../../../../../assets/Icons/StarIcon";
// styles
import BenefitsListStyles from "./BenefitsListStyles";
// services
import * as localBenefitsService from "../../../../../services/api/hr/localBenefits.service";
import * as actionCreators from "../../../../../actions/hr/localBenefits.actions";
// context
import { HrLocalBenefitsContext } from "../../../../../modules/hr/hrLocalBenefits/context/localBenefits.context";

const BenefitsList = ({
  benefits,
  openModal,
  setChosenBenefitId,
  setBenefit,
  chosenBenefitId,
  chosenBenefitGroupId,
  removeLocalBenefit
}) => {
  // const {
  //   localBenefitsActions: { removeLocalBenefit }
  // } = useContext(HrLocalBenefitsContext);
  return (
    <BenefitsListStyles>
      <div className="benefitList">
        {benefits.map(benefit => (
          <div
            className={`item ${benefit.id === chosenBenefitId &&
              "chosenBenefit"}`}
            id={`item${benefit.id}`}
            onClick={() => {
              setChosenBenefitId(benefit.id);
              setBenefit(benefit);
            }}
            key={benefit.id}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  background: "#e0e0e0",
                  marginRight: 10
                }}
              >
                <StarIcon />
              </div>
              <div>
                <p className="title">{benefit.title}</p>
                <p className="desc">{benefit.summary}</p>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ marginRight: 20 }}
                onClick={e => {
                  e.stopPropagation();
                  setChosenBenefitId(benefit.id);
                  setBenefit(benefit);
                  openModal("editLocalBenefitOfBenefitGroup");
                }}
              >
                <EditIconSvg />
              </div>
              <div
                style={{ marginRight: 10 }}
                onClick={async e => {
                  const result = confirm("Are u sure u want to delete benefit");
                  if (result) {
                    const response = await localBenefitsService.deleteLocalBenefit(
                      benefit.id
                    );

                    if (response.hasError) {
                      return toast.error(response.error.data.error.message);
                    }

                    removeLocalBenefit(chosenBenefitGroupId, benefit.id);
                  }
                }}
              >
                <TrashIcon stroke="#8f8f8f" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className="addBenefit"
        onClick={e => {
          e.stopPropagation();
          openModal("addLocalBenefitTobenefitGroup");
        }}
      >
        <div className="circle">
          <p>+</p>
        </div>
        <p className="buttonText">Add Benefit</p>
      </div>
    </BenefitsListStyles>
  );
};

BenefitsList.propTypes = {
  openModal: PropTypes.func,
  setBenefit: PropTypes.func,
  chosenBenefitId: PropTypes.number,
  setChosenBenefitId: PropTypes.func,
  chosenBenefitGroupId: PropTypes.number,
  benefits: PropTypes.arrayOf(PropTypes.shape())
};

const mapDispatchToProps = dispatch => {
  return {
    removeLocalBenefit: (chosenBenefitGroupId, benefitId) =>
      dispatch(
        actionCreators.removeLocalBenefit(chosenBenefitGroupId, benefitId)
      )
  };
};

export default connect(undefined, mapDispatchToProps)(BenefitsList);
