import React, { useState, useEffect, useContext, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
// components
import {
  Card,
  Header,
  TextAndLine,
  Modal,
  openModalAnim,
  closeModalAnim,
  Input,
  InputLabel,
  Button
} from "../../../components";
import TierItem from "./TierItem";
import FormRenderer from "./FormRenderer";
import BudgetRolloverToggle from "./budgetRolloverToggle";
import CompanyThemeForm from "./companyTheme";
// styles
import SettingsStyles from "./SettingsStyles";
import { override } from "../../../services/loadingOverride.styles";
// services
import * as actionCreators from "../../../actions/hr/settings.actions";
import * as settingsService from "../../../services/api/hr/settings.service";
// entity
import { CompanyTheme } from "../../../models/domain";
// context
import { UserContext } from "../../../modules/user/context/user.context";
// Hooks
import { useDisableBodyScroll } from "../../../hooks/useDisableBodyScroll";
import { isEmpty } from "../../../services/general.utils";
import { InfoIconSvg } from "../../../assets/Icons";
import { apiService } from "../../../services/api/api.service";
import moment from "moment";

const Settings = ({ setTiers, removeTier, tiers, user }) => {
  const [selectedTier, setSelectedTier] = useState(undefined);
  const [tokenWorth, setTokenWorth] = useState(0);
  const [modalType, setModalTypes] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [rolloverDuration, setRolloverDuration] = useState("");
  const [rolloverEnabled, setRolloverEnabled] = useState(null);
  const [scheduledDeleteAt, setScheduleDeleteAt] = useState(null);
  const [rolloverInfo, setRolloverInfo] = useState(null);

  const { companyId } = useSelector(state => state.app.user);

  const isMounted = useRef(true);
  useDisableBodyScroll(modalType);

  const openModal = type => {
    setModalTypes(type);
    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  const closeModal = () => {
    closeModalAnim();
    setTimeout(() => {
      setModalTypes(undefined);
    }, 350);
  };

  const fetchTiers = async () => {
    const [{ tiers }, responseTokenWorth] = await Promise.all([
      settingsService.getCompanyTiers(),
      settingsService.getTokenWorth()
    ]);
    if (isMounted.current && tiers && responseTokenWorth) {
      setTiers(tiers);
      setTokenWorth(responseTokenWorth);
      setIsLoading(false);
      // setTimeout delays animation untill content is ready to be displayed (set to state)
      setTimeout(() => {
        const settingsCard = document.getElementById("settingsCard");
        if (settingsCard && isMounted.current) {
          settingsCard.style.opacity = 1;
        }
      }, 100);
    }
  };

  const fetchCompanyBudgetRolloverData = async () => {
    const response = await settingsService.getCompanyWithBudgetRollover();

    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : "Something went wrong"
      );
    }

    setRolloverEnabled(response.isRolloverEnabled);
    if (!isEmpty(response.companyBudgetRollover)) {
      setRolloverDuration(response.companyBudgetRollover.rolloverDuration);
      setScheduleDeleteAt(response.companyBudgetRollover.scheduleDeleteAt);
    }
  };

  useEffect(() => {
    fetchTiers();
    fetchCompanyBudgetRolloverData();
    return () => {
      isMounted.current = false;
    };
  }, []);

  /**
   * Handles number input on change.
   * Sets rollover duration number in months.
   * @param {Event} e
   */
  const handleNumberInput = e => {
    let inputValue;
    if (e.target.validity.valid) {
      let replacedValue = e.target.value.replace(/[^0-9]*/g, "");
      inputValue = parseInt(replacedValue);
    }
    if (isNaN(inputValue)) {
      inputValue = "";
    }

    setRolloverDuration(inputValue);
  };

  const handleKeyDown = e => {
    if (e.key === "." || e.key === "," || e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  const handleRolloverToggle = () => {
    if (rolloverEnabled) {
      setRolloverEnabled(false);
    }
    if (!rolloverEnabled) {
      setRolloverEnabled(true);
    }
  };

  const updateCompanyBudgetRollover = async () => {
    const response = await settingsService.updateBudgetRollover({
      rolloverEnabled: rolloverEnabled,
      companyId: user.companyId,
      rolloverDuration: rolloverDuration
    });

    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : "Something went wrong"
      );
    }

    toast.success(response.data.message);

    if (!rolloverEnabled) {
      setRolloverDuration("");
    }
  };

  const getIfCompanyHasRollover = async companyId => {
    try {
      const response = await apiService.get(
        `/settings/company-rollover?companyId=${companyId}`
      );

      setRolloverInfo(response.data.companyRollover);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!companyId) return;
    getIfCompanyHasRollover(companyId);
  }, [companyId]);

  return (
    <SettingsStyles>
      <div className="settingsContainer">
        <Header headerTitle="Settings" />
        <div className="settingsContent">
          {isLoading ? (
            <div
              style={{
                height: "calc(100vh - 180px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <BeatLoader
                css={override}
                size={25}
                color="#123abc"
                loading={isLoading}
              />
            </div>
          ) : (
            <Card id="settingsCard">
              <TextAndLine title="tier settings" />
              <div className="rolloverContainer">
                <BudgetRolloverToggle
                  enableRollover={rolloverEnabled}
                  onToggle={handleRolloverToggle}
                />
                <div className="inputContainer">
                  <InputLabel label="Rollover duration (cycles)" />
                  <div className="inputContainerRollover">
                    <Input
                      type="number"
                      width="150px"
                      pattern="^\d+$"
                      className="numbersInputs"
                      name="rolloverDuration"
                      min={1}
                      max={12}
                      onChange={handleNumberInput}
                      value={rolloverDuration}
                      onKeyDown={handleKeyDown}
                    />
                    {rolloverInfo && (
                      <p className="rolloverResets">
                        Rollover resets on{" "}
                        <b>
                          {moment(rolloverInfo.rolloverEndDate).format(
                            "DD.MM.YYYY"
                          )}
                        </b>
                      </p>
                    )}
                  </div>
                </div>

                <Button
                  onClick={updateCompanyBudgetRollover}
                  margin="0 0 0 50px"
                  width="90px"
                  disabled={isEmpty(rolloverDuration)}
                >
                  Submit
                </Button>
              </div>
              <div className="rolloverDisclaimerContainer">
                <InfoIconSvg />
                <span className="rolloverDisclaimer">
                  If this is turned on it will enable transfer of all the
                  unspent budget of each user to the next month.
                  <br />
                  This way you can maximize usage of your benefit budget.
                </span>
              </div>
              {scheduledDeleteAt && (
                <div className="scheduleDeleteContainer">
                  Scheduled rollover deletion : {scheduledDeleteAt}
                </div>
              )}
              <div className="tierListContainer">
                {tiers.map(tier => (
                  <TierItem
                    setSelectedTier={setSelectedTier}
                    openModal={openModal}
                    key={tier.id}
                    tier={tier}
                  />
                ))}
              </div>
              <div className="addBenefit" onClick={() => openModal("addTier")}>
                <div className="circle">
                  <p>+</p>
                </div>
                <p className="buttonText">Add Tier</p>
              </div>
              {user.authenticatedRole && (
                <CompanyThemeForm
                  theme={
                    isEmpty(user.themes) ? new CompanyTheme() : user.themes[0]
                  }
                />
              )}
            </Card>
          )}
        </div>
      </div>
      {modalType && (
        <Modal closeModal={closeModal}>
          <FormRenderer
            modalType={modalType}
            closeModal={closeModal}
            selectedTier={selectedTier}
            tokenWorth={tokenWorth}
          />
        </Modal>
      )}
    </SettingsStyles>
  );
};

const mapStateToProps = state => {
  return {
    user: state.app.user,
    tiers: state.tiers.tiers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTiers: tiers => dispatch(actionCreators.setTiers(tiers)),
    removeTier: tierId => dispatch(actionCreators.removeTier(tierId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
