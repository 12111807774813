import React from "react";
import ActivityLog from "../../../assets/images/activity-log.png";

export const EmptyHistory = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 460
      }}
    >
      <img src={ActivityLog} alt="History placeholder" />
      <p className="text">User did not choose any benefit.</p>
    </div>
  );
};
