import React, { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
// assets
import { DotsIcon } from "../../../../assets/Icons";
// styles
import TierItemStyles from "./TierItemStyles";
// services
import * as actionCreators from "../../../../actions/hr/settings.actions";
import * as settingsService from "../../../../services/api/hr/settings.service";

const TierItem = ({ tier, setSelectedTier, removeTier, openModal }) => {
  const [isActive, setIsActive] = useState(false);
  // const myTooltip = useRef()

  const onClickDots = () => {
    setIsActive(true);
    ReactTooltip.rebuild();
  };

  const handleBlur = () => {
    setIsActive(false);
    // ReactTooltip.hide()
    ReactTooltip.rebuild();
  };

  return (
    <TierItemStyles
      onClick={() => {
        setSelectedTier(tier);
        openModal("editTier");
      }}
    >
      <div className="titleContainer">
        <h3 className="title">{tier.name}</h3>
        <button
          type="button"
          className="actionsCol"
          data-tip={`${tier.id}`}
          data-event={isActive ? "focus" : "click"}
          data-event-off={!isActive ? "focusout" : ""}
          onClick={e => {
            e.stopPropagation();
            onClickDots();
          }}
          onBlur={() => handleBlur()}
        >
          <DotsIcon />
        </button>
        <ReactTooltip
          place="bottom"
          effect="solid"
          // eslint-disable-next-line
          // ref={ref => myTooltip.current = ref}
          className="tooltip"
          getContent={dataTip => (
            <div>
              <button
                type="button"
                className="button"
                onClick={async e => {
                  e.stopPropagation();
                  handleBlur();
                  const result = confirm(
                    "Are you sure you want to delete this tier?"
                  );
                  if (result) {
                    const response = await settingsService.removeTier(dataTip);

                    if (response.hasError) {
                      return toast.error(response.error.data.error.message);
                    }

                    removeTier(dataTip);
                    ReactTooltip.rebuild();
                  }
                }}
              >
                Delete
              </button>
            </div>
          )}
          border
          clickable
        />
      </div>
      <div className="tokensMembersContainer">
        <p className="tokensAndMembers">{tier.tokens} Tokens</p>
        <p style={{ margin: "0 10px" }}>-</p>
        <p className="tokensAndMembers">{tier.countEmployee} Members</p>
      </div>
      <p className="description">{tier.description}</p>
    </TierItemStyles>
  );
};

TierItem.propTypes = {
  tier: PropTypes.shape(),
  setSelectedTier: PropTypes.func,
  removeTier: PropTypes.func,
  openModal: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    removeTier: tierId => dispatch(actionCreators.removeTier(tierId))
  };
};

export default connect(undefined, mapDispatchToProps)(TierItem);
