import validator from 'validator'

export const validateLogin = ({ email, password }) => {
	const errors = {}
	if (!email) {
		errors.email = '* Required'
	} else if (!validator.isEmail(email)) {
		errors.email = 'Invalid email'
	}

	if (!password) {
		errors.password = '* Required'
	} else if (password.length < 8) {
		errors.password = 'At least 8 characters'
	}

	return errors
}
