import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useClickOutside from "../../../../../../hooks/useClickOutside";
import { setFilters } from "../../../../../../actions/hr/refunds.actions";
import { RefundBranchCodeFilter } from "../../../RefundBranchCodeFilter";
import { FilterIconSvg } from "../../../../../../assets/Icons";
import { RefundStatusFilter } from "../../../RefundStatusFilter";

export const RefundMobileHeader = ({ handleStatus }) => {
  const dispatch = useDispatch();

  const statusRef = useRef(null);
  const branchRef = useRef(null);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isBranchOpen, setIsBranchOpen] = useState(false);

  useClickOutside([statusRef], () => setIsStatusOpen(false));
  useClickOutside([branchRef], () => setIsBranchOpen(false));

  const { filters } = useSelector(state => state.refundsReducer);

  const handleOnClickStatus = status => {
    let payload = status;
    if (status === "all") payload = undefined;

    dispatch(setFilters({ ...filters, status: payload }));
  };

  return (
    <div className="refundMobileHeader">
      <div className="refundListHeaderFilterWrapper">
        <div className="refundListHeaderFilterWrapperFlex">
          <span>Branch</span>
          <button
            ref={branchRef}
            onClick={() => setIsBranchOpen(status => !status)}
            aria-haspopup="true"
            aria-expanded={isBranchOpen}
            aria-labelledby="statusFilterLabel"
          >
            <FilterIconSvg />
          </button>
        </div>
        {isBranchOpen && <RefundBranchCodeFilter style={{ top: "33px" }} />}
      </div>

      <div className="refundListHeaderFilterWrapper">
        <div className="refundListHeaderFilterWrapperFlex">
          <span>Status</span>
          <button
            ref={statusRef}
            onClick={() => setIsStatusOpen(status => !status)}
            aria-haspopup="true"
            aria-expanded={isStatusOpen}
            aria-labelledby="statusFilterLabel"
          >
            <FilterIconSvg />
          </button>
        </div>
        {isStatusOpen && (
          <RefundStatusFilter
            style={{ top: "33px", left: "-7px" }}
            handleOnClickStatus={handleOnClickStatus}
            handleStatus={handleStatus}
          />
        )}
      </div>
    </div>
  );
};
