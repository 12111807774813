import React, { useContext } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";

import Form from "./Form";

import { BenefitTag } from "../../../../../../modules/superAdmin/settings/settings.entity";
import { benefitTagValidations } from "../../../../../../services/validations/superAdmin/benefitTag.validations";
import { axiosInstance } from "../../../../../../services/http/axiosConfig";
import { SettingsContext } from "../../../../../../modules/superAdmin/settings/context/settings.context";
import * as benefitTagsService from "../../../../../../services/api/admin/benefitTags.service";

import * as actionCreators from "../../../../../../actions/admin/benefitTags.actions";

const AddBenefitTagStyles = styled.div`
  padding: 20px;
  width: 400px;

  .title {
    margin-bottom: 20px;
  }
`;

const AddCompanyTag = ({ closeModal, addBenefitTag }) => {
  // const { benefitTagsActions: { addBenefitTag } } = useContext(SettingsContext)

  const handleEditBenefitTagError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
    }
  };

  const createBenefitTag = async values => {
    const response = await benefitTagsService.addBenefitTag(values);
    if (response.hasError) {
      return handleEditBenefitTagError(response);
    }

    addBenefitTag(response);
    toast.success("Benefit tag successfully created!");
    closeModal();
  };

  const formik = useFormik({
    initialValues: new BenefitTag(),
    validate: benefitTagValidations,
    onSubmit: createBenefitTag
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddBenefitTagStyles>
      <h2 className="title">Add Benefit tag</h2>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Form
          values={values}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          handleChange={handleChange}
          buttonText="Create"
        />
      </form>
    </AddBenefitTagStyles>
  );
};

AddCompanyTag.propTypes = {
  closeModal: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    addBenefitTag: benefitTag =>
      dispatch(actionCreators.addBenefitTag(benefitTag))
  };
};

export default connect(undefined, mapDispatchToProps)(AddCompanyTag);
