import React, { useRef, useState } from "react";
import { CheckboxInput } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import useClickOutside from "../../../../../hooks/useClickOutside";
import { FilterIconSvg } from "../../../../../assets/Icons";
import { RefundBranchCodeFilter } from "../../RefundBranchCodeFilter";
import { setFilters } from "../../../../../actions/hr/refunds.actions";
import { RefundStatusFilter } from "../../RefundStatusFilter";

export const ListOfItemsRefundsMobile = props => {
  const {
    handleRefundOpen,
    handleStatus,
    handleCheckedOnChange,
    checkedPendingItems,
    refundItem
  } = props;

  const dispatch = useDispatch();

  const statusRef = useRef(null);
  const branchRef = useRef(null);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isBranchOpen, setIsBranchOpen] = useState(false);

  useClickOutside([statusRef], () => setIsStatusOpen(false));
  useClickOutside([branchRef], () => setIsBranchOpen(false));

  const { filters } = useSelector(state => state.refundsReducer);

  const handleOnClickStatus = status => {
    let payload = status;
    if (status === "all") payload = undefined;

    dispatch(setFilters({ ...filters, status: payload }));
  };

  return (
    <div
      tabIndex={0}
      className="refundsListItem"
      role="row"
      key={refundItem.id}
      onClick={() => handleRefundOpen(refundItem)}
    >
      <div className="refundsListItemTop">
        <div className="refundsListItemTopName" role="cell">
          <div className="checkboxCol">
            <CheckboxInput
              onChange={() => handleCheckedOnChange(refundItem.id)}
              isChecked={checkedPendingItems.includes(refundItem.id)}
              name={String(refundItem.id)}
              width="18px"
              height="18px"
              disabled={refundItem.status !== null}
            />
          </div>
          {refundItem.UserBenefit.User.firstName}{" "}
          {refundItem.UserBenefit.User.lastName}
        </div>
        <div className="refundsListItemTopButton">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m15.75 15.75-2.489-2.489m0 0a3.375 3.375 0 1 0-4.773-4.773 3.375 3.375 0 0 0 4.774 4.774ZM21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </div>
      </div>
      <div className="refundsListItemBottom">
        <div className="refundListHeaderDate" role="columnheader">
          <div className="refundListHeaderFilterWrapper">
            <div className="refundListHeaderFilterWrapperFlex">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p>Branch</p>
                <span>
                  {refundItem.UserBenefit.User.UserEmployee?.CompanyBranch
                    ?.code || "/"}
                </span>
              </div>

              <button
                ref={branchRef}
                onClick={e => {
                  e.stopPropagation();
                  setIsBranchOpen(status => !status);
                }}
                aria-haspopup="true"
                aria-expanded={isBranchOpen}
                aria-labelledby="statusFilterLabel"
              >
                <FilterIconSvg />
              </button>
            </div>
            {isBranchOpen && (
              <RefundBranchCodeFilter style={{ top: "33px", left: "-15px" }} />
            )}
          </div>
        </div>
        <div className="refundsListItemBottomBenefit" role="cell">
          <p>Benefit</p>
          <span>{refundItem.UserBenefit.BenefitGroup.name}</span>
        </div>
        <div className="refundsListItemBottomCenter" role="cell">
          <p>Price</p>
          <span>{refundItem.UserBenefit.benefitPrice}</span>
        </div>
        <div className="refundsListItemBottomCenter" role="cell">
          <div className="refundListHeaderFilterWrapper">
            <div className="refundListHeaderFilterWrapperFlex">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p>Status</p>
                <span>{handleStatus(`${refundItem.status}`)}</span>
              </div>

              <button
                ref={statusRef}
                onClick={e => {
                  e.stopPropagation();
                  setIsStatusOpen(status => !status);
                }}
                aria-haspopup="true"
                aria-expanded={isStatusOpen}
                aria-labelledby="statusFilterLabel"
              >
                <FilterIconSvg />
              </button>
            </div>
            {isStatusOpen && (
              <RefundStatusFilter
                classNAme="refundFilterMobile"
                style={{ top: "33px", left: "-6px" }}
                handleOnClickStatus={handleOnClickStatus}
                handleStatus={handleStatus}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
