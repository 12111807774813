import React, { useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import StarRatings from "react-star-ratings";

// Styles
import BenefitCardStyles from "./BenefitCardStyles";
// Assets
import Capacity from "../../assets/images/capacity-left.svg";
// Components
import {
  ActiveIcon,
  FullHeartIconSVG,
  HearIconSVG,
  InfoIconSvg,
  OutlineHeartIconSVG,
  PendingActivationIcon,
  PendingDeactivationIcon,
  TokenIcon
} from "../../assets/Icons";

// Services
import { getUrl } from "../../services/imageHandler";
import { truncate } from "../../services/truncateString";
// Utils
import { isEmpty } from "../../services/general.utils";
// Constants
import { LOCAL } from "../../builders/benefitGroups/benefitGroup.types";
import {
  ACTIVE,
  PENDING_ACTIVATION,
  PENDING_DEACTIVATION
} from "../../builders/benefitGroups/benefitGroup.status";
import { UserRolesKeysEnum } from "../../constants/roles.constants";
import { apiService } from "../../services/api/api.service";
import {
  setListOfFavorites,
  setSocialProofForSingleBenefit
} from "../../actions/employee/employeeBenefits.actions";
import { toast } from "react-toastify";
import isWithinLastMonth from "../../utils/helpers/isWithinLastThreeMonths";

const BenefitCard = ({
  benefitGroupId,
  benefitDetails: {
    photo,
    provider,
    name,
    benefits = [],
    categories,
    status,
    statusPreview,
    taxes,
    cities,
    isRemote,
    type,
    summary,
    doesBenefitGroupHaveMcp,
    isBenefitGroupNew,
    benefitGroupAvailable,
    doesBenefitGroupHaveInstalments,
    doesBenefitGroupHaveQuantity,
    sumRatings,
    numberReviews,
    priceRange,
    createdAt
  },
  onClick,
  isMyBenefits = false,
  isHr = false,
  user,
  isPreview = false
}) => {
  const dispatch = useDispatch();

  const isCardNew = isWithinLastMonth(createdAt);

  /**
   * Handles on click event for the benefit card.
   * @returns {Function}
   */
  const handleOnClick = async () => {
    const durationOfCurrentBenefitGroupSubscriptions = 3;
    const query = `/benefit-group/${benefitGroupId}/social-proof?duration=${durationOfCurrentBenefitGroupSubscriptions}`;

    const response = await apiService.get(query);

    dispatch(setSocialProofForSingleBenefit(response.data.items));
    return onClick();
  };

  const { listOfFavoriteBenefitsInObject } = useSelector(
    state => state.employeeBenefitsPage.favoriteBenefits
  );

  /**
   * Renders cities on benefit card.
   * If user is hr, and benefit group has more than one city while also being remote, renders remote with no of locations.
   * If user is hr, and benefit group has more than one city, renders first city name and array length - 1.
   * If user is employee and benefit group has remote renders remote and renders first city name and array length - 1.
   * If user is employee, renders first city from his cities and array length - 1.
   * If there is only one city in cities list, render its name.
   * @param {City[]} cities
   */
  const renderCities = cities => {
    if (isEmpty(cities) && type === LOCAL) return [];

    if (
      (isHr || user.authenticatedRole?.key !== UserRolesKeysEnum.MEMBER) &&
      cities.length >= 1 &&
      isRemote
    ) {
      return <p className="cities">Remote, + {cities.length}</p>;
    }

    if (
      (isHr || user.authenticatedRole?.key !== UserRolesKeysEnum.MEMBER) &&
      cities.length > 1
    ) {
      return (
        <p className="cities">
          {cities[0].name}, + {cities.length - 1}
        </p>
      );
    }

    if (isEmpty(cities) && isRemote) return <p className="cities">Remote</p>;

    if (
      user.authenticatedRole?.key === UserRolesKeysEnum.MEMBER &&
      !isEmpty(user.employee.cities)
    ) {
      const employeeCity = user.employee?.cities?.find(city =>
        cities.find(ct => ct.id === city.id)
      );
      if (!!employeeCity && cities.length > 1 && !isRemote) {
        return (
          <p className="cities">
            {employeeCity.name}, + {cities.length - 1}
          </p>
        );
      }

      if (!!employeeCity && cities.length > 1 && isRemote) {
        return (
          <p className="cities">
            Remote, {employeeCity.name}, + {cities.length - 1}
          </p>
        );
      }

      if (!!employeeCity && cities.length === 1 && isRemote) {
        return <p className="cities">Remote, {employeeCity.name}</p>;
      }

      if (!isEmpty(cities) && isEmpty(employeeCity) && isRemote) {
        return <p className="cities">Remote, + {cities.length}</p>;
      }

      if (cities.length > 1) {
        return (
          <p className="cities">
            {cities[0].name}, + {cities.length - 1}
          </p>
        );
      }
    }

    return <p className="cities">{cities[0].name}</p>;
  };

  /**
   * Renders benefit group categories to card.
   * @returns {HTMLDivElement}
   */
  const renderCategories = () => {
    if (isEmpty(categories)) return [];

    if (!isEmpty(categories) && categories.length > 1) {
      return (
        <p className="categoryText">
          {categories[0].name}, + {categories.length - 1}
        </p>
      );
    }

    return <p className="categoryText">{categories[0].name}</p>;
  };

  /**
   * Returns icon component based on status.
   * @param {string} status
   * @returns {import("react").ReactComponentElement}
   */
  const renderStatusIcon = status => {
    if (status === ACTIVE) {
      return <ActiveIcon />;
    }
    if (status === PENDING_ACTIVATION) {
      return <PendingActivationIcon />;
    }
    if (status === PENDING_DEACTIVATION) {
      return <PendingDeactivationIcon />;
    }
  };

  /**
   * Calculates & returns total quantity remaining.
   * @returns {number}
   */
  const calculateTotalQuantityRemaining = () => {
    return benefits?.reduce(
      (total, benefit) => total + benefit.totalQuantityRemaining,
      null
    );
  };
  const quantityRemaining = useMemo(() => calculateTotalQuantityRemaining(), [
    benefits
  ]);

  /**
   * Calculates & returns tooltip position relative on top left border.
   * @param {number} left
   * @param {number} top
   * @param {HTMLDivElement} node
   * @returns {number, number}
   */
  const overrideTooltipPosition = (
    { top, left },
    currentEvent,
    currentTarget,
    node
  ) => {
    const d = document.documentElement;
    left = Math.min(d.clientWidth - node.clientWidth, left);
    top = Math.min(d.clientHeight - node.clientHeight, top);
    left = Math.max(0, left);
    top = Math.max(0, top);
    return { top, left };
  };

  const refetchFavoriteBenefits = async () => {
    try {
      const response = await apiService.get("/favorites");
      const listOfFavorite =
        response.data?.items.length > 0
          ? response.data.items.map(item => item.group)
          : [];

      dispatch(setListOfFavorites(listOfFavorite));
    } catch (error) {
      toast.error("Failed to get favorite benefits");
    }
  };

  const handleRemoveFromFavorites = async e => {
    e.stopPropagation();

    try {
      const removeFromBenefitsResponse = await apiService.delete(
        `/favorites/remove/benefits/${benefitGroupId}`
      );
      refetchFavoriteBenefits();
      toast.success(removeFromBenefitsResponse.data.message);
    } catch (error) {
      toast.error("Failed to remove benefit from favorites.");
    }
  };

  const handleAddToFavorites = async e => {
    e.stopPropagation();

    try {
      const removeFromBenefitsResponse = await apiService.post(
        `/favorites/add/benefits/${benefitGroupId}`
      );

      refetchFavoriteBenefits();
      toast.success(removeFromBenefitsResponse.data.message);
    } catch (error) {
      toast.error("Failed to add benefit to favorites.");
    }
  };

  const svgString = `
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <g clip-path="url(#clip0)">
        <rect x="51" y="3" width="5" height="5" fill="#660707" />
        <rect x="4" y="50" width="5" height="5" fill="#660707" />
        <rect
          x="48.6691"
          y="-6"
          width="11.6452"
          height="77.3915"
          transform="rotate(45 48.6691 -6)"
          fill="url(#paint0_linear)"
        />
        <path
          d="M22.3354 32.6759L18.1422 28.4827L17.4067 29.2182L20.3174 32.129L15.2629 31.362L14.5085 32.1164L18.7017 36.3096L19.4373 35.574L16.4448 32.5816L21.625 33.3863L22.3354 32.6759ZM25.3827 29.7795C26.64 28.5221 26.6086 26.7179 25.3576 25.4668C24.1065 24.2158 22.3023 24.1844 21.0449 25.4417C19.7876 26.699 19.819 28.5033 21.0701 29.7543C22.3211 31.0054 24.1254 31.0368 25.3827 29.7795ZM24.7289 29.1257C23.8802 29.9744 22.6857 29.8612 21.8245 28.9999C20.9569 28.1324 20.85 26.9442 21.6987 26.0955C22.5412 25.2531 23.7356 25.3537 24.6032 26.2212C25.4644 27.0825 25.5713 28.2833 24.7289 29.1257ZM30.287 24.7243L27.7409 18.884L26.9048 19.7201L29.0297 24.3848L24.365 22.2599L23.5289 23.096L29.3692 25.6421L30.287 24.7243ZM34.4257 20.7365C35.683 19.4792 35.6516 17.6749 34.4005 16.4239C33.1495 15.1728 31.3452 15.1414 30.0879 16.3987C28.8306 17.6561 28.862 19.4603 30.113 20.7114C31.3641 21.9624 33.1683 21.9939 34.4257 20.7365ZM33.7719 20.0827C32.9232 20.9314 31.7287 20.8183 30.8674 19.957C29.9999 19.0894 29.893 17.9013 30.7417 17.0526C31.5841 16.2101 32.7786 16.3107 33.6461 17.1783C34.5074 18.0396 34.6143 19.2403 33.7719 20.0827Z"
          fill="white"
        />
        <rect
          x="35.3628"
          y="8"
          width="0.535621"
          height="40.0232"
          transform="rotate(45.1259 35.3628 8)"
          fill="url(#paint1_radial)"
        />
        <rect
          x="41.3628"
          y="16"
          width="0.535621"
          height="40.0232"
          transform="rotate(45.1259 41.3628 16)"
          fill="url(#paint2_radial)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.51 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="54.4917"
        y1="-6"
        x2="54.4917"
        y2="71.3915"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B20F0F" />
        <stop offset="1" stop-color="#F70404" />
      </linearGradient>
      <radialGradient
        id="paint1_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(35.6306 28.0116) rotate(90) scale(20.0116 0.267811)"
      >
        <stop stop-color="#FDF8CF" />
        <stop offset="1" stop-color="#FDF8CF" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(41.6306 36.0116) rotate(90) scale(20.0116 0.267811)"
      >
        <stop stop-color="#FDF8CF" />
        <stop offset="1" stop-color="#FDF8CF" stop-opacity="0" />
      </radialGradient>
      <clipPath id="clip0">
        <rect x="4" y="3" width="52" height="52" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;

  // Encode the SVG string in Base64
  const base64SVG = `data:image/svg+xml;base64,${btoa(svgString)}`;

  return (
    <BenefitCardStyles id="benefitCard" status={status} onClick={handleOnClick}>
      {isCardNew && (
        <p
          style={{
            background: `url(${base64SVG}) center center no-repeat`,
            position: "absolute",
            top: "-10px",
            left: "-12px",
            width: "74px",
            height: "74px",
            backgroundSize: "74px 74px",
            fontSize: "8px",
            zIndex: 2
          }}
        ></p>
      )}
      <div className="benefitCardWrapper">
        <div className="imgContainer">
          <img
            src={getUrl(photo, "url5x")}
            alt={name}
            className="benefitImage"
            crossOrigin="anonymous"
          />
          {isBenefitGroupNew && <span className="newIndicator">NEW</span>}
        </div>
        <div className="underImageCardWrapper">
          <div className="topContent">
            <div className="providerImageOuterCircle">
              <div className="providerImageInnerCircle">
                <img
                  src={
                    provider?.photo ? getUrl(provider?.photo) : getUrl(photo)
                  }
                  alt="Provider logo"
                  className="providerLogo"
                  crossOrigin="anonymous"
                />
              </div>
            </div>
            <div className="titleAndTokensContainer">
              <h2 className="title">{name}</h2>
              <div className="categoryTag">{renderCategories()}</div>
            </div>
            <div className="ratingContainer">
              <div className="starContainer">
                <span className="total">
                  {" "}
                  {numberReviews
                    ? (sumRatings / numberReviews).toFixed(1)
                    : 0}{" "}
                </span>
                <StarRatings
                  numberOfStars={1}
                  rating={numberReviews ? sumRatings / numberReviews : 0}
                  starDimension="12px"
                  starSpacing="0px"
                  starEmptyColor="#EAEAEA"
                  starRatedColor="#FFD402"
                />
                <span className="reviewsNumber">
                  {" "}
                  ({numberReviews} ratings)
                </span>
              </div>
            </div>
          </div>
          <div
            className={isHr ? "hrDescriptionContainer" : "descriptionContainer"}
          >
            {!isEmpty(taxes) && <p className="taxes">{truncate(taxes, 60)}</p>}
            <p className="providerDescription">{truncate(summary, 89)}</p>
          </div>
          <div
            className={isHr ? "hr-locations-container" : "locations-container"}
          >
            {cities && renderCities(cities)}
          </div>
          <div className="priceAncCapacityContainer">
            <div className="tokensContainer">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="tokensContent">
                  <TokenIcon />
                  <p className="price">{priceRange}</p>
                </div>
                {isPreview ||
                  (doesBenefitGroupHaveMcp && (
                    <>
                      <div
                        className="mcpContainer"
                        data-tip
                        data-for="mcpTooltip"
                      >
                        <span>MCP</span>
                      </div>
                      <ReactTooltip
                        id="mcpTooltip"
                        place="bottom"
                        effect="solid"
                        className="mcpTooltip"
                        backgroundColor="black"
                        borderColor="black"
                        arrowColor="black"
                        overridePosition={overrideTooltipPosition}
                      >
                        <span style={{ color: "black" }}>
                          You won't be able to unsubscribe until the commitment
                          period ends.
                          <br />
                          Once the commitment period ends you will be
                          automatically unsubscribed.
                        </span>
                      </ReactTooltip>
                    </>
                  ))}
                {doesBenefitGroupHaveInstalments && (
                  <>
                    <a
                      data-tip
                      data-for="instalmentsInfo"
                      style={{ cursor: "pointer" }}
                    >
                      <InfoIconSvg />
                    </a>
                    <ReactTooltip
                      id="instalmentsInfo"
                      place="top"
                      effect="solid"
                      className="tooltip"
                      backgroundColor="#1568bf"
                      borderColor="#1568bf"
                      arrowColor="#1568bf"
                    >
                      <span className="spanText" style={{ color: "black" }}>
                        This benefit group has a price split in instalments.
                      </span>
                    </ReactTooltip>
                  </>
                )}
              </div>
              {!isHr && (
                <div style={{ display: "flex" }}>
                  {!isEmpty(quantityRemaining) && doesBenefitGroupHaveQuantity && (
                    <div
                      className="capacityContainer"
                      style={{ marginLeft: 24 }}
                    >
                      <img
                        src={Capacity}
                        alt="capacity"
                        crossOrigin="anonymous"
                      />
                      <p className="price">{quantityRemaining} left</p>
                    </div>
                  )}
                  {!!listOfFavoriteBenefitsInObject[benefitGroupId] ? (
                    <button
                      className="favoriteButton"
                      onClick={handleRemoveFromFavorites}
                    >
                      <FullHeartIconSVG />
                    </button>
                  ) : (
                    <button
                      className="favoriteButton"
                      onClick={handleAddToFavorites}
                    >
                      <OutlineHeartIconSVG />
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          {isHr && (
            <div className="enabledStatus">
              {benefitGroupAvailable ? (
                <div className="enabled">
                  ON <div className="green-dot" />
                </div>
              ) : (
                <div className="disabled">
                  OFF <div className="red-dot" />
                </div>
              )}
            </div>
          )}
          {isMyBenefits
            ? status && (
                <div className="activeContainer">
                  {renderStatusIcon(status)}
                  <p className="activeText">{statusPreview}</p>
                </div>
              )
            : status && (
                <div className="activeContainer">
                  {renderStatusIcon(status)}
                  <p className="activeText">{statusPreview}</p>
                </div>
              )}
        </div>
      </div>
    </BenefitCardStyles>
  );
};

BenefitCard.propTypes = {
  benefitDetails: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    photo: PropTypes.shape({
      url: PropTypes.string
    }),
    provider: PropTypes.shape({
      photo: PropTypes.shape({
        url: PropTypes.string
      }),
      description: PropTypes.string
    }),
    benefitMin: PropTypes.number,
    benefitMax: PropTypes.number,
    capacity: PropTypes.number,
    takenBenefits: PropTypes.number,
    benefits: PropTypes.arrayOf(PropTypes.shape({})),
    isPremium: PropTypes.bool,
    sumRatings: PropTypes.number,
    numberReviews: PropTypes.number
  }),
  onClick: PropTypes.func,
  isHr: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    user: state.app.user
  };
};

export default connect(mapStateToProps, undefined)(BenefitCard);
