const SUPPORTED_FORMATS = [
	'csv'
]

export const AddEmployeesWithCSVValidations = ({ csvFile }) => {
	const errors = {}

	if (!csvFile) {
		errors.csvFile = '* Required'
	}
	if (
		csvFile && csvFile.name && !SUPPORTED_FORMATS.includes(csvFile.name.split('.').pop())
	) {
		errors.csvFile = 'You can upload only .csv file format'
	}
	return errors
}
