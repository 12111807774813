import React, { useState, useContext } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import styled from "styled-components";
// components
import BenefitGroupForm from "./BenefitGroupForm";
// entity
import { LocalBenefitGroupForm } from "../../../../../models/domain";
// validation
import { validateHrLocalBenefitGroupCreate } from "../../../../../services/validations/hr/hrLocalBenefitValidation";
// services
import * as localBenefitsService from "../../../../../services/api/hr/localBenefits.service";
// context
import { HrLocalBenefitsContext } from "../../../../../modules/hr/hrLocalBenefits/context/localBenefits.context";

const AddLocalBenefitGroupStyles = styled.div`
  .title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4b4b4b;
    margin: 30px 0;
  }

  .form {
    width: 481px;
    height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const AddLocalBenefitGroup = ({
  closeModal,
  setCurrentPage,
  fetchLocalBenefitGroups
}) => {
  // const { localBenefitsActions } = useContext(HrLocalBenefitsContext);
  const [disableButton, setDisableButton] = useState(false);

  const handleCreateBenefitGroupError = ({ error }) => {
    const { data } = error;
    if (data) {
      if (data.errors && data.error) {
        if (data.errors.name) {
          toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
        }
        if (data.errors.description) {
          toast.error(
            `${data.error.message}: ${data.errors.description.keys[0]}`
          );
        }
        if (data.errors.summary) {
          toast.error(
            `${data.error.message}: ${data.errors.summary.keys[0]}`
          );
        }
        if (data.errors.photo) {
          toast.error(`${data.error.message}: ${data.errors.photo.keys[0]}`);
        }
      } else {
        toast.error(data.error.message);
      }
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handleCreateBenefitGroup = async values => {
    setDisableButton(true);

    const response = await localBenefitsService.addLocalBenefitGroup(values);

    if (response.hasError) {
      setTimeout(() => {
        setDisableButton(false);
      }, 1000);
      return handleCreateBenefitGroupError(response);
    }

    setCurrentPage(1);
    fetchLocalBenefitGroups();
    toast.success("Local benefit group successfully created!");
    closeModal();
    setTimeout(() => {
      setDisableButton(false);
    }, 550);
  };

  const formik = useFormik({
    initialValues: new LocalBenefitGroupForm(),
    validate: validateHrLocalBenefitGroupCreate,
    onSubmit: handleCreateBenefitGroup
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddLocalBenefitGroupStyles>
      <h3 className="title">Add Local Benefit Group</h3>
      {/* <Card padding="20px"> */}
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <BenefitGroupForm
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          setFieldError={setFieldError}
          buttonText="Create"
          closeModal={closeModal}
          disableButton={disableButton}
          isHr={true}
        />
      </form>
      {/* </Card> */}
    </AddLocalBenefitGroupStyles>
  );
};

AddLocalBenefitGroup.propTypes = {
  closeModal: PropTypes.func,
  setCurrentPage: PropTypes.func,
  fetchLocalBenefitGroups: PropTypes.func
};

export default AddLocalBenefitGroup;
