import React from "react";
import PropTypes from "prop-types";
// assets
import { TokenIcon } from "../../../../assets/Icons";
// components
import { Button } from "../../../../components";
// styles
import FormStyles from "./FormStyles";

const Form = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  tokenWorth,
  setFieldValue,
  title,
  buttonText,
  disableButton
}) => {
  const handleNumberInput = e => {
    let inputValue = e.target.validity.valid
      ? parseInt(e.target.value, 0)
      : values.tokens;
    if (isNaN(inputValue)) {
      inputValue = "";
    }
    if (e.target.name === "tokens") {
      setFieldValue("tokens", inputValue);
      setFieldValue(
        "currency",
        inputValue === "" ? "" : inputValue * tokenWorth.worth
      );
    }
    if (e.target.name === "currency") {
      setFieldValue("currency", inputValue);
      if (inputValue % tokenWorth.worth === 0) {
        setFieldValue(
          "tokens",
          inputValue === "" ? "" : inputValue / tokenWorth.worth
        );
      } else {
        setFieldValue("tokens", "");
      }
    }
  };

  return (
    <FormStyles>
      <h2 className="title">{title}</h2>
      <div className="nameContainer">
        <div className="labelAndValidation">
          <p className="label">Name:</p>
          <div className="errorMsg">
            {errors.name && touched.name ? errors.name : ""}
          </div>
        </div>
        <input
          type="text"
          name="name"
          className="input"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="descriptionContainer">
        <div className="labelAndValidation">
          <p className="label">Description:</p>
          <div className="errorMsg">
            {errors.description && touched.description
              ? errors.description
              : ""}
          </div>
        </div>
        <textarea
          name="description"
          className="textArea"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="tokensAndCurrencyContainer">
        <div className="tokensContainer">
          <p className="label">Enter value (RSD)</p>
          <div className="inputContainer">
            <input
              type="text"
              className="numbersInputs"
              name="currency"
              onChange={handleNumberInput}
              style={{ paddingRight: 47 }}
              value={values.currency}
            />
            <p style={{ position: "absolute", right: 11, opacity: 0.6 }}>RSD</p>
          </div>
          <p className="tokenWorth">1 token = {tokenWorth.worth} RSD</p>
        </div>
        <div className="tokensContainer">
          <div className="labelAndValidation">
            <p className="label"># of tokens:</p>
            <div className="errorMsg">
              {errors.tokens && touched.tokens ? errors.tokens : ""}
            </div>
          </div>
          <div className="inputContainer">
            <div
              style={{
                width: 24,
                height: 24,
                marginLeft: 11,
                position: "relative",
                zIndex: 2
              }}
            >
              <TokenIcon />
            </div>
            <input
              type="text"
              pattern="[0-9]*"
              name="tokens"
              style={{ paddingLeft: 36 }}
              className="numbersInputs"
              value={values.tokens}
              onChange={handleNumberInput}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {disableButton ? (
          <Button width="130px" disabled margin="20px 0 0 0">
            Please wait
          </Button>
        ) : (
          <Button type="submit" width="130px" margin="20px 0 0 0">
            {buttonText}
          </Button>
        )}
      </div>
    </FormStyles>
  );
};

Form.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  tokenWorth: PropTypes.shape(),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  disableButton: PropTypes.bool
};

export default Form;
