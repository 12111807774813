import React, { useEffect } from 'react';
import DatePicker from "react-datepicker";
import { parseISO } from 'date-fns';
import PropTypes from "prop-types";
// utils
import { isEmpty } from "../../../../../services/general.utils";
import { formatToStartOfDay, startFutureMonthFromDate, formatDateForPlaceholder } from "../../../../../services/date.utils";

const DateInput = ({ values, setFieldValue, isNotCompanyBonusBudget }) => {  
  const handleStartDateChange = (selectedDate) => {
    if (isNaN(selectedDate) || isEmpty(selectedDate)) {
      return;
    }
    if (selectedDate) {
      selectedDate.setHours((-1 * selectedDate.getTimezoneOffset()) / 60);
    };
    setFieldValue("startDate", formatToStartOfDay(selectedDate));
  };

  useEffect(() => {
      isNotCompanyBonusBudget && setFieldValue("startDate", new Date());
      isNotCompanyBonusBudget && setFieldValue("endDate",startFutureMonthFromDate(new Date()));      
  }, [isNotCompanyBonusBudget]);

  return (
    <>
      <div className="datepickerWrapper">
        <div className="labelAndValidation">
          <p className="label">Bonus Start Date:</p>
        </div>        
        <DatePicker
          className="datePickerInput"
          name="startDate"
          id="datePicker"
          selected={values.startDate}
          dateFormat="dd/MM/yyyy"
          onChange={selectedDate => handleStartDateChange(selectedDate)}
          minDate={new Date}
          maxDate={startFutureMonthFromDate(new Date())}
          value={parseISO(values.startDate)}
          showYearDropdown
          placeholderText="DD/MM/YYYY"          
        />          
      </div>    
      <div className="dateContainer">
        <div className="labelAndValidation">
          <p className="label">Bonus End Date:</p>      
        </div>
        <input
          type="text"
          name="endDate"
          className="input"
          value={formatDateForPlaceholder(startFutureMonthFromDate(new Date()))}
          readOnly
        />
      </div>
    </>           
  )};

  DateInput.propTypes = {
    values: PropTypes.shape(),
    setFieldValue: PropTypes.func,
    isNotCompanyBonusBudget: PropTypes.bool,
  };

export default DateInput;