import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
// styles
import { OnboardingStyles } from "./onboarding.styles";
// assets
import { BenefitLogoIcon } from "../../../../assets/Icons";
// components
import { Button } from "../../../../components";
// services
import * as api from "../../../../services/api/employee/employeeBenefits.services";
// actions
import * as actionCreators from "../../../../actions/app.actions";

const EmployeeOnboarding = ({ finishEmployeeOnboarding }) => {
  const handleFinishOnboarding = async () => {
    const response = await api.finishOnboarding();
    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : "Onboarding failed."
      );
    }
    finishEmployeeOnboarding();
  };

  return (
    <OnboardingStyles>
      <div className="circleLogoContainer">
        <BenefitLogoIcon />
      </div>
      <h3 className="title">Welcome to Benefiti!</h3>
      <p className="text">Greetings!</p>
      <p className="text">
        {`Good to see you on the platform! Your employer wants to allow you to 
        spend your budget on the benefits you really want and change them as needed, 
        which we make possible. We believe that you will find it easy to navigate 
        and intuitively select your benefits. If you need help feel free to email us at `}
        <a
          href="mailto:info@benefiti.rs"
          target="_blank"
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
        >
          info@benefiti.rs
        </a>
        .
      </p>
      <p className="text">Benefiti team</p>
      <Button
        width="130px"
        onClick={() => handleFinishOnboarding()}
        margin="20px 0 0 0"
      >
        Ok
      </Button>
    </OnboardingStyles>
  );
};

EmployeeOnboarding.propTypes = {
  finishOnboarding: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    finishEmployeeOnboarding: () => dispatch(actionCreators.finishEmployeeOnboarding())
  };
};

export default connect(null, mapDispatchToProps)(EmployeeOnboarding);
