import React from 'react';
import DatePicker from "react-datepicker";
import { parseISO } from 'date-fns';
import PropTypes from "prop-types";
// components
import { SelectInput, CheckboxInput } from "../../../../../components";
// utils
import { isEmpty } from "../../../../../services/general.utils";
import { formatToStartOfDay, startFutureMonthFromDate } from "../../../../../services/date.utils";


const BonusBudgetDateInput = ({
  values, 
  errors, 
  touched, 
  handleChange, 
  handleBlur, 
  setFieldValue, 
  selectName,
}) => {
  const recurrenceTypeOptions = [
    { id: 1, name: "Monthly", key: "monthly" },
    { id: 2, name: "Three Months", key: "three_months" },
    { id: 3, name: "Six Months", key: "six_months" },
    { id: 4, name: "Yearly", key: "yearly" },
  ];

  const handleDateChange = (selectedDate) => {
    if (isNaN(selectedDate) || isEmpty(selectedDate)) {
      return;
    };
    
    // You who reads this in the future, don't touch this, dates in javascript with datepickers are real hard, okay?
    // This exists because the javascript date object automatically sets selected date to utc, so we have to offset it
    // So the selected value in the datepicker is valid
    if (selectedDate) {
      selectedDate.setHours((-1 * selectedDate.getTimezoneOffset()) / 60);
    };
    setFieldValue("endDate", formatToStartOfDay(selectedDate));
  };

  const handleStartDateChange = (selectedDate) => {
    if (isNaN(selectedDate) || isEmpty(selectedDate)) {
      return;
    }
    if (selectedDate) {
      selectedDate.setHours((-1 * selectedDate.getTimezoneOffset()) / 60);
    };
    setFieldValue("startDate", formatToStartOfDay(selectedDate));
  };

  const filterStartDateBasedOnRecurrence = (date) => {
    return date.getDate() <= 25;
  };

  return (
    <>
      <div className="datepickerWrapper">
          <div className="labelAndValidation">
            <p className="label">Bonus Start Date:</p>
          </div>
          {values.recurring && values.recurringType !== 'yearly' ? (
            <DatePicker
              className="datePickerInput"
              name="startDate"
              id="datePicker"
              selected={values.startDate}
              dateFormat="dd/MM/yyyy"
              onChange={selectedDate => handleStartDateChange(selectedDate)}
              minDate={new Date}
              value={parseISO(values.startDate)}
              showYearDropdown
              placeholderText="DD/MM/YYYY"
              filterDate={filterStartDateBasedOnRecurrence}
            />) :
            <DatePicker
              className="datePickerInput"
              name="startDate"
              id="datePicker"
              selected={values.startDate}
              dateFormat="dd/MM/yyyy"
              onChange={selectedDate => handleStartDateChange(selectedDate)}
              minDate={new Date}
              value={parseISO(values.startDate)}
              showYearDropdown
              placeholderText="DD/MM/YYYY"
            />}
          <p className="dateDisclaimer">
            If you don't select a start date, bonus will be active from the first day of next month.
          </p>
        </div>
        <div className="datepickerWrapper">
          <div className="labelAndValidation">
            <p className="label">Bonus End Date:</p>
          </div>
          <DatePicker
            className="datePickerInput"
            name="endDate"
            id="datePicker"
            selected={values.endDate}
            dateFormat="dd/MM/yyyy"
            onChange={selectedDate => handleDateChange(selectedDate)}
            minDate={startFutureMonthFromDate(new Date, 2)}
            value={parseISO(values.endDate)}
            showYearDropdown
            placeholderText="DD/MM/YYYY"
          />
          <p className="dateDisclaimer">
            If you don't select an end date, bonus will be active for a period of three months by default.
          </p>
        </div>
        <div className="checkbox-wrapper">
          <CheckboxInput
            name="recurring"
            value={values.recurring}
            isChecked={values.recurring}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <label className="label" htmlFor="recurring">
            Recurring Bonus
          </label>
        </div>
        {values.recurring && (
          <div style={{ margin: "20px 0" }}>
            <SelectInput
              maxWidth
              name={selectName}
              onBlur={handleBlur}
              options={recurrenceTypeOptions}
              value={recurrenceTypeOptions.find(option => option.key === values.recurringType)}
              handleChange={option => setFieldValue("recurringType", option.key)}
              customHeight="41px"
              shouldHaveFullWidth
            />
            <div className="errorMsg">
              {errors.recurringType && touched.recurringType
                ? errors.recurringType
                : ""}
            </div>
          </div>
        )}
    </>);
};

BonusBudgetDateInput.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  selectName: PropTypes.string,
};
  
export default BonusBudgetDateInput;