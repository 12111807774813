import React from "react";
import { apiService } from "../../../../services/api/api.service";
import { useDispatch, useSelector } from "react-redux";
import { setRefund, setRefunds } from "../../../../actions/hr/refunds.actions";
import { toast } from "react-toastify";
import bucketsSwap from "../../../../utils/supabaseUtils/BucketsSwap/bucketsSwap";

export const RefundModalActions = ({ id, fileName, status }) => {
  const dispatch = useDispatch();

  const { filters } = useSelector(state => state.refundsReducer);
  const { companyId } = useSelector(state => state.app.user);

  const fetchRefunds = async () => {
    try {
      const refundsResponse = await apiService.get(
        `/refunds?limit=${filters.perPage}&page=${filters.page}`
      );

      dispatch(setRefunds(refundsResponse.data?.refunds || []));
    } catch (error) {
      toast.error("Failed to get list of refunds.");
    }
  };

  const acceptOrRejectRefund = async newStatus => {
    try {
      const response = await apiService.put(`/refunds/${id}`, {
        description: "",
        status: newStatus
      });

      dispatch(setRefund(response.data.refund));
      toast.success(response.data.message);

      await bucketsSwap({
        oldStatus: status,
        status: response.data.status,
        fileName,
        companyId
      });

      await fetchRefunds();
    } catch (error) {
      toast.error("Failed to accept the refund.");
    }
  };

  return (
    <div className="refundButtons">
      {status === null && (
        <>
          <button
            className="accept"
            disabled={status === true}
            onClick={() => acceptOrRejectRefund(true)}
          >
            Accept
          </button>
          <button
            className="reject"
            disabled={status === false}
            onClick={() => acceptOrRejectRefund(false)}
          >
            Reject
          </button>
        </>
      )}
    </div>
  );
};
