// Entities
import Employee from "./Employee.entity";
import Company from "./Company.entity";
import CompanyTheme from "./CompanyTheme.entity";
import BudgetBreakdown from "./BudgetBreakdown.entity";
// Constants
import { CompanyThemeModeEnum } from "../../constants/companyThemeMode.constants";

class User {
  constructor(userData = {}) {
    this.id = userData.id || null;
    this.authenticatedUserRoleId = userData.authenticatedUserRoleId || null;
    this.authenticatedRole = userData.authenticatedRole || "";
    this.roles = userData.roles || [];
    this.firstName = userData.firstName || "";
    this.lastName = userData.lastName || "";
    this.vocativeName = userData.vocativeName || "";
    this.email = userData.email || "";
    this.companyName = userData.companyName || "";
    this.companyVatEnabled = userData.companyVatEnabled || false;
    this.availableTokens =
      userData.availableTokens || userData.available_tokens || 0;
    this.usedTokens = userData.usedTokens || userData.used_tokens || 0;
    this.remainingTokens =
      userData.remainingTokens || userData.remaining_tokens || 0;
    if (this.remainingTokens < 0) {
      this.remainingTokens = 0;
    }
    this.totalTokens = userData.totalTokens | userData.total_tokens || 0;
    this.isOnboardingDone = userData.onboarding || false;
    this.hasAcceptedPrivacyPolicy =
      userData.privacyPolicyAccepted ||
      userData.privacy_policy_accepted ||
      null;
    this.isDisabled = userData.isDisabled || userData.is_disabled || false;
    this.employeeOnboarding =
      userData.employeeOnboarding || userData.employee_onboarding || false;
    this.employee = userData.employee ? new Employee(userData.employee) : null;
    this.themes = userData.themes
      ? userData.themes.map(theme => new CompanyTheme(theme))
      : [];
    this.mode = this.mode || CompanyThemeModeEnum.LIGHT;
    this.serverOnboarding = userData.onboarding || false;
    this.isSync = userData.sync || false;
    this.employeeId = userData.employeeId || null;
    this.company = userData.Company
      ? new Company(userData.Company)
      : new Company();
    this.companyId = userData.companyId || userData.company_id || null;
    this.userHash = userData.userHash || userData.user_hash || null;
    this.budgetBreakdown = userData.budgetBreakdown
      ? new BudgetBreakdown(userData.budgetBreakdown)
      : new BudgetBreakdown();
    this.formalEmail = userData.formalEmail || userData.formal_email || false;
  }
}

export default User;
