import validator from "validator";
import  { removeSpacesAndHTML } from "../../general.utils";

export const validateCompanyForm = ({
  name,
  pib,
  address,
  idNumber,
  contact,
  invoiceEmail,
  paymentDeadline,
  photo
}) => {
  const errors = {};

  if (!name.trim()) {
    errors.name = "* Required";
  } else if (removeSpacesAndHTML(name).length > 20) {
    errors.name = "Too long";
  } else if (!removeSpacesAndHTML(name)){
		errors.name = 'HTML tag is not allowed'
	};

  if (!pib) {
    errors.pib = "* Required";
  } else if (typeof pib === "number") {
    if (pib.toString().split("").length !== 9) {
      errors.pib = "9 digits";
    }
  }

  if (!address.trim()) {
    errors.address = "* Required";
  } else if (!removeSpacesAndHTML(address)){
		errors.address = 'HTML tag is not allowed'
	};

  if (!idNumber) {
    errors.idNumber = "* Required";
  } else if (typeof idNumber === "string") {
    if (idNumber.length !== 8) {
      errors.idNumber = "8 digits";
    }
  }

  if (!contact) {
    errors.contact = "* Required";
  }

  if (!invoiceEmail) {
    errors.invoiceEmail = "* Required";
  } else if (!validator.isEmail(invoiceEmail))
    errors.invoiceEmail = "Invalid email";

  if (!paymentDeadline) {
    errors.paymentDeadline = "* Required";
  }

  if (!photo) {
    errors.photo = "* Required";
  }
  // else if (photo.type !== 'image/png') errors.photo = 'Only PNG is acceptable'

  return errors;
};
