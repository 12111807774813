export const tableHeadBuilder = [
	{
		id: 1,
		field: 'name',
		headName: 'Name',
		isSortedUp: null,
		width: '26%',
		justifyContent: 'flex-start',
		margin: '0 0.5% 0 75px',
		isSortable: true,
		orderPriority: 0
	},
	{
		id: 2,
		field: 'role',
		headName: 'Role',
		isSortedUp: null,
		width: '20%',
		justifyContent: 'flex-start',
		margin: '0 0 0 1%',
		isSortable: true,
		orderPriority: 0
	},
	{
		id: 3,
		field: 'company',
		headName: 'Company',
		isSortedUp: null,
		width: '20%',
		justifyContent: 'flex-start',
		margin: '0 0 0 1%',
		isSortable: true,
		orderPriority: 0
	},
	{
		id: 4,
		field: 'email',
		headName: 'Email',
		isSortedUp: null,
		width: '22%',
		justifyContent: 'flex-start',
		margin: '0 0 0 1%',
		isSortable: true,
		orderPriority: 0
	},
	{
		id: 5,
		headName: '',
		isSortedUp: null,
		width: '4%',
		justifyContent: 'center',
		margin: '',
		isSortable: false,
		orderPriority: 0
	}
]
