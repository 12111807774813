// import React from 'react'
// import { renderToString } from 'react-dom/server'
// import { TokenIcon } from '../../../../assets/Icons'

export const tierBudgetConfig = {
  series: [
    {
      name: 'Chosen month budget',
      data: []
    },
    {
      name: 'Previous month budget',
      data: []
    }
  ],
  options: {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: []
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left'
    },
    // yaxis: {
    // 	title: {
    // 		text: '$ (thousands)'
    // 	}
    // },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        // formatter(val) {
        // 	return `${val} ${renderToString(<TokenIcon height="16" />)}`
        // }
        formatter(val) {
          return `${val} RSD`;
        }
      }
    }
  }
};
