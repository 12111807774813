import React, { useContext, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import Pagination from "react-pagination-js";
// components
import { Header, Card, Button, SearchInput } from "../../../components";
import {
  FormModal,
  openModalAnim,
  closeModalAnim,
  FormRenderer
} from "./shared";
import BenefitGroupRow from "./BenefitGropRow";
import BenefitGroupSubRow from "./BenefitGroupSubRow";
// styles
import LocalBenefitsStyles from "./LocalBenefitStyles";
import { override } from "../../../services/loadingOverride.styles";
// context
import { UserContext } from "../../../modules/user/context/user.context";
import { HrLocalBenefitsContext } from "../../../modules/hr/hrLocalBenefits/context/localBenefits.context";
// services
import * as localBenefitsService from "../../../services/api/hr/localBenefits.service";
import * as actionCreators from "../../../actions/hr/localBenefits.actions";
// hooks
import { useDisableBodyScroll } from "../../../hooks/useDisableBodyScroll";

const LIMIT = 4;

const LocalBenefits = ({
  setLocalBenefitGroups,
  setLocalBenefitGroupPage,
  localBenefitGroups,
  page,
  count
}) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [modalFormType, setModalFormType] = useState("");
  const [chosenBenefitGroupId, setChosenBenefitGroupId] = useState(undefined);
  const [chosenBenefitId, setChosenBenefitId] = useState(undefined);
  const [keywordSearch, setKeywordSearch] = useState('');
  // const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(true);


  useDisableBodyScroll(isFormModalOpen);

  // const {
  //   localBenefitsState: { localBenefitGroups },
  //   localBenefitsActions
  // } = useContext(HrLocalBenefitsContext);

  const handleSearch = (searchText) => {
    setKeywordSearch(searchText);
  }

  const handleFetchAfterSearch = () => {
    fetchLocalBenefitGroups();
  }

  const openModal = type => {
    setIsFormModalOpen(true);
    setModalFormType(type);
    setTimeout(() => {
      openModalAnim();
    }, 100);
  };

  const closeModal = () => {
    closeModalAnim();
    setTimeout(() => {
      setIsFormModalOpen(false);
      setModalFormType("");
    }, 600);
  };

  const fetchLocalBenefitGroups = async () => {
    const response = await localBenefitsService.getLocalBenefitGroups({
      limit: LIMIT,
      page,
      searchQuery: keywordSearch
    });

    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    if (isMounted.current) {
      setTimeout(() => {
        setLocalBenefitGroups(response.groups, response.count);
        setIsLoading(false);
        setTimeout(() => {
          const localBenefitsList = document.getElementById(
            "localBenefitsList"
          );
          if (isMounted.current && localBenefitsList) {
            localBenefitsList.style.opacity = 1;
          }
        }, 100);
      }, 100);
    }
  };

  useEffect(() => {
    fetchLocalBenefitGroups();
  }, [page]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <LocalBenefitsStyles>
      <Header headerTitle="Company Benefit Groups" />

      <div className="localBenefitsContent">
        {isLoading ? (
          <div
            style={{
              height: "calc(100vh - 180px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <BeatLoader
              css={override}
              size={25}
              color="#123abc"
              loading={isLoading}
            />
          </div>
        ) : (
          <Card id="localBenefitsList">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Button
                margin="0 0 0 0"
                padding="9px 25px 9px 9px"
                onClick={() => openModal("addLocalBenefitGroup")}
              >
                <div className="addContainer">
                  <p className="buttonText">+</p>
                </div>
                <p className="buttonText">Add Local Benefit Group</p>
              </Button>
              <SearchInput
                fetchData={handleFetchAfterSearch}
                setSearch={handleSearch}
                search={keywordSearch}
                customClass='localBenefitsSearch'
                setPagginationPage={setLocalBenefitGroupPage}
              />
            </div>

            {localBenefitGroups && localBenefitGroups.length > 0 && (
              <div className="localBenefitsList">
                {localBenefitGroups.map(benefitGroup => (
                  <BenefitGroupRow
                    key={benefitGroup.id}
                    benefitGroup={benefitGroup}
                    fetchLocalBenefitGroups={fetchLocalBenefitGroups}
                    openModal={openModal}
                    setChosenBenefitGroupId={setChosenBenefitGroupId}
                    setChosenBenefitId={setChosenBenefitId}
                  >
                    <BenefitGroupSubRow
                      setChosenBenefitId={setChosenBenefitId}
                      benefitGroup={benefitGroup}
                      chosenBenefitGroupId={chosenBenefitGroupId}
                      chosenBenefitId={chosenBenefitId}
                      openModal={openModal}
                      fetchData={fetchLocalBenefitGroups}
                    />
                  </BenefitGroupRow>
                ))}
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Pagination
                currentPage={page}
                totalSize={count}
                sizePerPage={LIMIT}
                changeCurrentPage={page => setLocalBenefitGroupPage(page)}
                theme="border-bottom"
              />
            </div>
          </Card>
        )}
      </div>
      {isFormModalOpen && (
        <FormModal closeModal={closeModal}>
          <FormRenderer
            chosenBenefitGroupId={chosenBenefitGroupId}
            chosenBenefitId={chosenBenefitId}
            modalFormType={modalFormType}
            closeModal={closeModal}
            setCurrentPage={setLocalBenefitGroupPage}
            fetchLocalBenefitGroups={fetchLocalBenefitGroups}
          />
        </FormModal>
      )}
    </LocalBenefitsStyles>
  );
};

const mapStateToProps = state => {
  return {
    localBenefitGroups: state.localBenefits.localBenefitGroups,
    page: state.localBenefits.page,
    count: state.localBenefits.count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLocalBenefitGroups: (benefitGroups, count) =>
      dispatch(actionCreators.setLocalBenefitGroups(benefitGroups, count)),
    setLocalBenefitGroupPage: page =>
      dispatch(actionCreators.setLocalBenefitGroupPage(page))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalBenefits);
