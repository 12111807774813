import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types'

// Components
import LocationsMultiselectInputStyles from './LocationsMultiselectInputStyles';
import itemRenderer from '../selectInput/itemRendered';

const LocationsMultiselectInput = ({
  value,
  name,
  options,
  selectedValues,
  shouldHaveHight = false,
  shouldHaveFullWidth = false,
  handleChange,
  handleInputChange,
  isDisabled = false,
  fetchMoreData,
  customHeight = '100%',
  customInputFieldHeight = '100%'
}) => {

  /**
   * Handles on scroll event in multiselect dropdown.
   * Fires when list is scrolled to the end.
   * Fetches more data for the list.
   */
  const handleOnScroll = () => {
    setTimeout(() => {
      fetchMoreData()
    }, 100)
  }

  /**
   * Handles input change.
   * Request is debounced.
   * @param {string} searchText 
   */
  const debounceInputChange = (searchText) => {
    setTimeout(() => {
      handleInputChange(searchText)
    }, 500)
  }

  return (
    <LocationsMultiselectInputStyles
      shouldHaveHight={shouldHaveHight}
      shouldHaveFullWidth={shouldHaveFullWidth}
      customHeight={customHeight}
      customInputFieldHeight={customInputFieldHeight}
    >
      <Select
        value={selectedValues}
        className='react-dropdown-select'
        classNamePrefix='react-dropdown'
        placeholder="Select locations"
        labelField="name"
        valueField="id"
        getOptionLabel={(options) => options['name']}
        getOptionValue={(options) => options['id']}
        isMulti
        isDisabled={isDisabled}
        name={name}
        options={options}
        searchable
        searchBy="name"
        dropdownPosition="auto"
        onMenuScrollToBottom={handleOnScroll}
        onInputChange={debounceInputChange}
        onChange={(values, action) => handleChange(values, action)}
        itemRenderer={
          itemRenderer
            ? (item, itemIndex, props, state, methods) =>
              itemRenderer(item, itemIndex, props, state, methods)
            : undefined
        }
      />
    </LocationsMultiselectInputStyles>
  )
};

LocationsMultiselectInput.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  selectedValues: PropTypes.array,
  shouldHaveHight: PropTypes.bool,
  shouldHaveFullWidth: PropTypes.bool,
  handleChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  fetchMoreData: PropTypes.func
}

export default LocationsMultiselectInput;