import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { TrashIcon, EditIconSvg } from "../../../../assets/Icons";
import StarIcon from "../../../../assets/Icons/StarIcon";

const ItemStyles = styled.div`
  position: relative;
  width: 100%;
  height: 70px;
  border-radius: 4px;
  border: solid 1px #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 30px;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: #373737;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .desc {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.2px;
    color: #373737;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .taxes {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.33;
    letter-spacing: -0.2px;
    color: #373737;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .previewLink {
    color: #1e3c72;
    cursor: pointer;
    font-size: 15px;
    margin-right: 20px;
    position: absolute;
    right: -6px;
    bottom: 2px
  }
`;

const Item = ({
  onCardClick,
  title,
  description,
  editButton,
  removeButton,
  selectedItem,
  taxes,
  isDraft,
  openModalPreviewModalAction = () => { },
  isBenefitGroup = false
}) => (
  <ItemStyles
    onClick={() => onCardClick()}
    className={selectedItem && "selectedItem"}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <div
        style={{
          minWidth: 50,
          width: 50,
          height: 50,
          minHeight: 50,
          borderRadius: "50%",
          background: "#e0e0e0",
          marginRight: 10
        }}
      >
        <StarIcon />
      </div>
      <div>
        <p className="title">{title}</p>
        <p className="desc">{description}</p>
        {taxes && <p className="taxes">{taxes}</p>}
      </div>
    </div>
    <div style={{ display: "flex", alignItems: "center" }}>
      {isDraft && isBenefitGroup && (
        <div
          className="previewLink"
          onClick={e => {
            e.stopPropagation();
            openModalPreviewModalAction();
          }}
        >
          <span>Preview</span>
        </div>
      )}
      <div
        style={{ marginRight: 20 }}
        onClick={e => {
          e.stopPropagation();
          editButton();
        }}
      >
        <EditIconSvg />
      </div>
      <div
        style={{ marginRight: 10 }}
        onClick={e => {
          e.stopPropagation();
          removeButton();
        }}
      >
        <TrashIcon stroke="#8f8f8f" />
      </div>
    </div>
  </ItemStyles>
);

Item.propTypes = {
  onCardClick: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  editButton: PropTypes.func,
  selectedItem: PropTypes.bool,
  removeButton: PropTypes.func
};

export default Item;
