import React, { useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Components
import { DotsIcon, UserDisabledIconSvg } from '../../assets/Icons';
import CompaniesListItemStyles from './CompaniesListItemStyles';
import CompaniesListItemExpandedStyles from './CompaniesListItemExpandedStyles';
import ChevronIconDownSvg from '../../assets/Icons/ChevronIconDownSvg';

// Services
import getInitals from '../../services/getInitals';
import { isEmpty } from '../../services/general.utils';
import { apiService } from "../../services/api/api.service";

const CompaniesListItem = ({
  companyId,
  companyName,
  isFirstLetter = false,
  pib,
  paymendDeadline,
  invoiceEmail,
  isActionsEnabled = true,
  isEditActionVisable = true,
  isDeleteActionVisable = true,
  idNumber,
  address,
  contact,
  tags,
  fetchData = () => { }
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const myTooltip = useRef();
  const history = useHistory();

  const onClickDots = () => {
    setIsActive(true);
    ReactTooltip.rebuild();
  };

  const handleBlur = () => {
    setIsActive(false);
    ReactTooltip.rebuild();
  };

  const handleExpand = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(!isOpen);
  }

  return (
    <div style={{ cursor: 'pointer' }} onClick={e => handleExpand(e)} >
      <CompaniesListItemStyles>
        <div className="nameCol">
          <ChevronIconDownSvg isOpen={isOpen} />
          <div className="employeeAvatar">
            {getInitals(companyName, isFirstLetter)}
          </div>
          <span>{companyName !== "" ? companyName : "Unknown"}</span>
        </div>
        <div className="pib">
          {pib}
        </div>
        <div className="paymentDeadline">
          {paymendDeadline}
        </div>
        <div className="email">
          {invoiceEmail}
        </div>
        {isActionsEnabled && (
          <>
            <button
              type="button"
              className="actionsCol"
              data-tip={`${companyId}|${companyName}`}
              data-event={isActive ? "focus" : "click"}
              data-event-off={!isActive ? "focusout" : ""}
              onClick={e => {
                e.stopPropagation();
                onClickDots();
              }}
              onBlur={() => handleBlur()}
            >
              <DotsIcon />
            </button>
            <ReactTooltip
              place="bottom"
              effect="solid"
              // eslint-disable-next-line
              ref={ref => (myTooltip.current = ref)}
              className="tooltip"
              getContent={dataTip => {
                if (!dataTip) {
                  return "";
                }
                const [
                  companyId,
                  companyName
                ] = dataTip.split("|");

                return (
                  <div className="tooltipContentWrapper">
                    {isEditActionVisable && (
                      <button
                        type="button"
                        className="button"
                        onClick={e => {
                          e.stopPropagation();
                          history.push(`companies/edit/${companyId}`);
                        }}
                      >
                        Edit
                      </button>
                    )}
                    {isDeleteActionVisable && (
                      <button
                        type="button"
                        className="button"
                        onClick={async e => {
                          e.stopPropagation();
                          if (companyId) {
                            const result = confirm("Are you sure you want to delete company?");
                            if (result) {
                              const response = await apiService.delete(
                                `/company/delete/${companyId}`
                              );
                              if (response.hasError) {
                                return toast.error(
                                  response.errorMessage
                                    ? response.errorMessage
                                    : "Deleting Company failed"
                                );
                              }

                              fetchData();
                            }
                          }
                        }}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                );
              }}
              border
              clickable
            />
          </>
        )}
      </CompaniesListItemStyles>
      <CompaniesListItemExpandedStyles>
        {isOpen && (
          <div className="listItemExpanded open">
            <div className="idNumber">
              <div className="expandedListItemDataContainer gender">
                <span className="expandedListItemLabel">
                  ID Number
                </span>
                <span className="genderValue">
                  {!isEmpty(idNumber) ? idNumber : '-'}
                </span>
              </div>
            </div>
            <div className="addressWrapper">
              <div className="expandedListItemDataContainer">
                <span className="expandedListItemLabel">
                  Address
                </span>
                <span>
                  {!isEmpty(address) ? address : '-'}
                </span>
              </div>
            </div>
            <div className="contactWrapper">
              <div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Contact
                  </span>
                  <span>
                    {!isEmpty(contact) ? contact : '-'}
                  </span>
                </div>
              </div>
            </div>
            <div className="birthDayAndEnrolldateWrapper">
              <div>
                <div className="expandedListItemDataContainer">
                  <span className="expandedListItemLabel">
                    Tags
                  </span>
                  <span>
                    {!isEmpty(tags) ? tags.map(tag => tag.name).join(", ") : '-'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </CompaniesListItemExpandedStyles>
    </div>
  )
}

export default CompaniesListItem;