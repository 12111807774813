import React from "react";

export const RightModalSection = ({ image }) => {
  return (
    <div className="rightSection">
      {/* <div>Description: {singleRefund.description || "/"}</div> */}
      <div className="rightSectionImage">
        Image:
        <button
          className="imageButton"
          aria-label="View Image"
          onClick={() => window.open(image.imageSrc)}
        >
          <img src={image.logo} />
        </button>
      </div>
    </div>
  );
};
