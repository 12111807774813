import styled from "styled-components";

const SettingsStyles = styled.div`
  .settingsContainer {
    width: calc(100% - 210px);
    margin-left: 210px;
    background: whitesmoke;
    min-height: 100vh;
  }

  .settingsContent {
    padding: 0 30px 30px 30px;
  }

  #settingsCard {
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

  .tierListContainer {
    margin: 30px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .rolloverContainer {
    display: flex;
    margin: 20px 0 10px 0;
  }

  .scheduleDeleteContainer {
    color: #ce1212;
    margin-top: 10px;
  }

  .rolloverDisclaimerContainer {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  .inputContainerRollover {
    display: flex;
    flex-direction: column;
    align-items: center;

    .rolloverResets {
      font-size: 12px;
    }
  }

  .rolloverDisclaimer {
    margin-left: 11px;
    opacity: 0.7;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    -webkit-letter-spacing: -0.5px;
    -moz-letter-spacing: -0.5px;
    -ms-letter-spacing: -0.5px;
    letter-spacing: -0.5px;
    color: #373737;
  }

  .inputContainer {
    display: flex;
    align-items: center;
  }

  .addBenefit {
    width: 191px;
    height: 44px;
    border-radius: 4px;
    border: solid 1px #d8d8d8;
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
  }
  .circle {
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background: #1568bf;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-right: 13px;
  }

  .buttonText {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: #373737;
  }
`;

export default SettingsStyles;
