import React from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import ToggleButtonComponentStyles from "./ToggleButtonComponentStyles";

const ToggleButtonComponent = ({ enableInstalments, onToggle }) => {
  return (
    <ToggleButtonComponentStyles enableInstalments={enableInstalments}>
      <div className="activationCheckBox">
        {enableInstalments && <p className="activityOnText">ON</p>}
        <div className="dot" onClick={onToggle} />
        {!enableInstalments && <p className="activationOffText">OFF</p>}
      </div>
      {enableInstalments ? (
        <p className="benefitActivityStatusText">Instalments are enabled</p>
      ) : (
        <p className="benefitActivityStatusText">Instalments are disabled</p>
      )}
    </ToggleButtonComponentStyles>
  );
}

ToggleButtonComponent.propTypes = {
  isActive: PropTypes.bool,
  onToggle: PropTypes.func,
  tiers: PropTypes.number
};

const mapStateToProps = state => {
  return {
    enableInstalments: state.benefits.editPageState.benefitGroup.enableInstalments,
  };
};

export default connect(mapStateToProps, null)(ToggleButtonComponent);
