import React, { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// components
import ProviderForm from "../ProviderForm/ProviderForm";
// styles
import AddProviderStyles from "./AddProviderStyles";
// entity
import { Provider } from "../../../../../../models/domain";
// services
import { validateProviderForm } from "../../../../../../services/validations/addProviderFormValidation";
import { axiosInstance } from "../../../../../../services/http/axiosConfig";
import * as providerService from "../../../../../../services/api/admin/providers.service";
// context

const AddProvider = ({ closeModal, fetchProviders, setCurrentPage }) => {
  const [disableButton, setDisableButton] = useState(false);

  const handleCreateProviderError = ({ error }) => {
    const { data } = error;
    if (data.errors && data.error) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
      if (data.errors.description) {
        toast.error(
          `${data.error.message}: ${data.errors.description.keys[0]}`
        );
      }
      if (data.errors.summary) {
        toast.error(
          `${data.error.message}: ${data.errors.summary.keys[0]}`
        );
      }
      if (data.errors.photo) {
        toast.error(`${data.errors.photo.keys[0]}`);
      }
    } else {
      toast.error(data.error.message);
    }
  };

  const createProvider = async values => {
    setDisableButton(true);

    const response = await providerService.createProvider(values);

    if (response.hasError) {
      setDisableButton(false);
      return handleCreateProviderError(response);
    }

    setCurrentPage(1);
    fetchProviders();
    toast.success("Provided successfully created!");
    setTimeout(() => {
      setDisableButton(false);
    }, 550);
    closeModal();
  };

  const formik = useFormik({
    initialValues: new Provider(),
    validate: validateProviderForm,
    onSubmit: createProvider
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddProviderStyles>
      <h2 className="title">Add Provider</h2>
      <form className="form" autoComplete="off" onSubmit={handleSubmit}>
        <ProviderForm
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          closeModal={closeModal}
          buttonText="Create"
          disableButton={disableButton}
        />
      </form>
    </AddProviderStyles>
  );
};

AddProvider.propTypes = {
  closeModal: PropTypes.func,
  fetchProviders: PropTypes.func,
  setCurrentPage: PropTypes.func
};

export default AddProvider;
