import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
// components
import { Card, InputLabel } from "../../../../components";
// styles
import TopBenefitsStyles from "./TopBenefitsStyles";
// config
import { barChart } from "./bar.config";
import { pieChart } from "./pie.config";
// services
import { formatDateForSearch } from "../dashboad.services";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";

const radioBoxResults = {
  CHOSEN_MONTH: "CHOSEN_MONTH",
  PREVIOUS_MONTH: "PREVIOUS_MONTH"
};

const TopBenefits = ({ topBenefitsLoading, setTopBenefitsLoading }) => {
  const [chosenMonth, setChosenMonth] = useState({ barChart, pieChart });
  const [chosenMonthErrorMsg, setChosenMonthErrorMessage] = useState(undefined);
  const [previouseMonth, setPreviouseMonth] = useState({ barChart, pieChart });
  const [previousMonthErrorMsg, setPreviousMonthErrorMessage] = useState(
    undefined
  );
  const [chooseCurretOrLastMonth, setChooseCurrentOrLastMonth] = useState(
    radioBoxResults.CHOSEN_MONTH
  );
  const [date, setDate] = useState(new Date());
  const [searchDateString, setSearchDateString] = useState(
    formatDateForSearch(new Date())
  );
  const isMounted = useRef(false);

  const handleDateChange = chosenDate => {
    setDate(chosenDate);
    setSearchDateString(formatDateForSearch(chosenDate));
  };

  const fetchData = async () => {
    const response = await dashboardService.fetchTopBenefitsData(
      searchDateString
    );

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching Top Benefits failed."
      );
    }

    if (isMounted.current) {
      setTopBenefitsLoading(false);

      const pieChosenBenefitsLabels = [];
      const pieChosenBenefitsData = [];
      const barChosenSeries = [];
      if (response.actualBudget.length > 0) {
        response.actualBudget.forEach(benefit => {
          pieChosenBenefitsLabels.push(benefit.name);
          pieChosenBenefitsData.push(benefit.budget);
          barChosenSeries.push({ name: benefit.name, data: [benefit.budget] });
        });
      }

      const pieLastMonthBenefitsLabel = [];
      const pieLastMonthBenefitsData = [];
      const barLastMonthSeries = [];

      if (response.lastBudget.length > 0) {
        response.lastBudget.forEach(benefit => {
          pieLastMonthBenefitsLabel.push(benefit.name);
          pieLastMonthBenefitsData.push(benefit.budget);
          barLastMonthSeries.push({
            name: benefit.name,
            data: [benefit.budget]
          });
        });
      }
      if (response) {
        if (isMounted.current) {
          if (response.actualBudget.length > 0) {
            setChosenMonthErrorMessage(undefined);
            setChosenMonth({
              ...chosenMonth,
              pieChart: {
                ...chosenMonth.pieChart,
                series: pieChosenBenefitsData,
                options: {
                  ...chosenMonth.options,
                  labels: pieChosenBenefitsLabels
                }
              },
              barChart: {
                ...chosenMonth.barChart,
                series: barChosenSeries
              }
            });
          } else {
            setChosenMonthErrorMessage("No data to compare");
            setChosenMonth({
              ...chosenMonth,
              pieChart: {
                ...chosenMonth.pieChart,
                series: [],
                options: {
                  ...chosenMonth.options,
                  labels: []
                }
              },
              barChart: {
                ...chosenMonth.barChart,
                series: []
              }
            });
          }

          if (isMounted.current) {
            if (response.lastBudget.length > 0) {
              setPreviousMonthErrorMessage(undefined);
              setPreviouseMonth({
                ...previouseMonth,
                pieChart: {
                  ...previouseMonth.pieChart,
                  series: pieLastMonthBenefitsData,
                  options: {
                    ...previouseMonth.options,
                    labels: pieLastMonthBenefitsLabel
                  }
                },
                barChart: {
                  ...previouseMonth.barChart,
                  series: barLastMonthSeries
                }
              });
            } else {
              setPreviousMonthErrorMessage("No data to compare");
              setPreviouseMonth({
                ...previouseMonth,
                pieChart: {
                  ...previouseMonth.pieChart,
                  series: [],
                  options: {
                    ...previouseMonth.options,
                    labels: []
                  }
                },
                barChart: {
                  ...previouseMonth.barChart,
                  series: []
                }
              });
            }
          }
        }
      }
    }
    if (isMounted.current) {
      setChooseCurrentOrLastMonth(radioBoxResults.CHOSEN_MONTH);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [date]);

  useEffect(() => {
    if (topBenefitsLoading === false) {
      const datePicker = document.getElementById("datePickerTopBenefits");
      if (datePicker) {
        datePicker.setAttribute("readOnly", true);
      }
    }
  }, [topBenefitsLoading]);

  return (
    <TopBenefitsStyles>
      <Card
        boxShadow="
				0 2px 4px -1px rgba(0, 0, 0, 0.2),
				0 1px 10px 0 rgba(0, 0, 0, 0.12),
				0 4px 5px 0 rgba(0, 0, 0, 0.14)
			"
      >
        <div className="titleContainer">
          <DatePicker
            className="datePickerInput"
            id="datePickerTopBenefits"
            selected={date}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onChange={chosenDate => handleDateChange(chosenDate)}
            value={date}
            maxDate={new Date()}
          />
          <h3 className="title">Top Benefits</h3>
        </div>
        <div className="chartsAndInputsContiner">
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "20px 0"
            }}
          >
            <div className="inputContainer">
              <input
                type="radio"
                style={{ cursor: "pointer" }}
                name="chosen month"
                value={radioBoxResults.CHOSEN_MONTH}
                checked={
                  chooseCurretOrLastMonth === radioBoxResults.CHOSEN_MONTH
                }
                onChange={() =>
                  setChooseCurrentOrLastMonth(radioBoxResults.CHOSEN_MONTH)
                }
              />
              <InputLabel
                label="Chosen month"
                margin="0 0 0 10px"
                shouldHaveColon={false}
              />
            </div>
            <div className="inputContainer">
              <input
                type="radio"
                style={{ cursor: "pointer" }}
                name="chosen month"
                value={radioBoxResults.PREVIOUS_MONTH}
                checked={
                  chooseCurretOrLastMonth === radioBoxResults.PREVIOUS_MONTH
                }
                onChange={() =>
                  setChooseCurrentOrLastMonth(radioBoxResults.PREVIOUS_MONTH)
                }
              />
              <InputLabel
                label="Previous month"
                margin="0 0 0 10px"
                shouldHaveColon={false}
              />
            </div>
          </div>
          <div>
            <div className="pieChartAndMessageContainer">
              {(chooseCurretOrLastMonth === radioBoxResults.CHOSEN_MONTH
                ? chosenMonthErrorMsg
                : previousMonthErrorMsg) && (
                  <div className="messageContainer">
                    <p className="text">
                      {chooseCurretOrLastMonth === radioBoxResults.CHOSEN_MONTH
                        ? chosenMonthErrorMsg
                        : previousMonthErrorMsg}
                    </p>
                  </div>
                )}
              <ReactApexChart
                options={
                  chooseCurretOrLastMonth === radioBoxResults.CHOSEN_MONTH
                    ? chosenMonth.pieChart.options
                    : previouseMonth.pieChart.options
                }
                series={
                  chooseCurretOrLastMonth === radioBoxResults.CHOSEN_MONTH
                    ? chosenMonth.pieChart.series
                    : previouseMonth.pieChart.series
                }
                type="pie"
                width={300}
              />
            </div>
            <div className="barChartAndMessageContainer">
              {(chooseCurretOrLastMonth === radioBoxResults.CHOSEN_MONTH
                ? chosenMonthErrorMsg
                : previousMonthErrorMsg) && (
                  <div className="messageContainer">
                    <p className="text">
                      {chooseCurretOrLastMonth === radioBoxResults.CHOSEN_MONTH
                        ? chosenMonthErrorMsg
                        : previousMonthErrorMsg}
                    </p>
                  </div>
                )}
              <ReactApexChart
                options={
                  chooseCurretOrLastMonth === radioBoxResults.CHOSEN_MONTH
                    ? chosenMonth.barChart.options
                    : previouseMonth.barChart.options
                }
                series={
                  chooseCurretOrLastMonth === radioBoxResults.CHOSEN_MONTH
                    ? chosenMonth.barChart.series
                    : previouseMonth.barChart.series
                }
                type="bar"
                width={300}
                height={350}
              />
            </div>
          </div>
        </div>
      </Card>
    </TopBenefitsStyles>
  );
};

TopBenefits.propTypes = {
  topBenefitsLoading: PropTypes.bool,
  setTopBenefitsLoading: PropTypes.func
};

export default TopBenefits;
