import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import { ImagePlaceholer, TrashIcon } from '../../assets/Icons'
import { readURL } from '../../services/fileReader'

import { getUrl } from '../../services/imageHandler'

const DropZoneStyles = styled.div`
	width: 481px;
	height: 190px;
	border: 2px dotted #d5dbdd;
	border-radius: 4px;
	outline: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.text {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.71;
		letter-spacing: -0.23px;
		text-align: center;
		color: #afafaf;
	}

	.imageContainer {
		width: calc(100% - 8px);
		height: calc(100% - 8px);
		margin: auto;
		position: relative;
		overflow: hidden;
		border-radius: 4px;

		.image {
			border-radius: 4px;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		.trashContainer {
			width: 28px;
			height: 28px;
			position: absolute;
			right: 16px;
			bottom: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			background: #ffffff;
		}
	}
`


const MyDropzone = ({ photo, name, handleChange, onBlur, setIsDelete }) => {
	const onDrop = useCallback(acceptedFiles => {
		// Do something with the files
		if (acceptedFiles[0]) {
			handleChange(name, acceptedFiles[0])
		}
	}, [])
	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false })

	const handleBlur = () => {
		onBlur(name, true)
	}
	const removeImage = () => {
		handleChange(name, null)
		setIsDelete && setIsDelete(true)
	}

	return (
		<DropZoneStyles {...getRootProps()}>
			{!photo &&
				<>
					<input {...getInputProps()} name={name} onBlur={() => handleBlur()} />
					<ImagePlaceholer />
					<p className="text">Drag and drop image or&nbsp;
						<span style={{
							color: '#1568bf',
							cursor: 'pointer'
						}}
						>
							click here to upload
						</span>
					</p>
					<p className="text">Supported formats .png, .jpg, .jpeg</p>
				</>
			}
			{photo && photo instanceof File &&
				<div className="imageContainer">
					<img src={readURL(photo, 'photo')} id="photo" alt="company" className="image" />
					<div className="trashContainer" onClick={() => removeImage()}>
						<TrashIcon stroke="#d5dbdd" />
					</div>
				</div>
			}
			{photo && photo instanceof File === false &&
				<div className="imageContainer">
					<img src={getUrl(photo, 'url5x')} alt="photoImage" className="image" />
					<div className="trashContainer" onClick={() => removeImage()}>
						<TrashIcon stroke="#d5dbdd" />
					</div>
				</div>
			}
		</DropZoneStyles>
	)
}

MyDropzone.propTypes = {
	photo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
	handleChange: PropTypes.func,
	onBlur: PropTypes.func.isRequired,
	name: PropTypes.string,
	setIsDelete: PropTypes.func
}

export default MyDropzone
