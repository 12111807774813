import styled from 'styled-components'

const AzureLoginStyles = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  padding: 30px;
  width: 400px;
  height: 400px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 2px 5px 10px 2px #888;

  button {
    justify-content: space-around;
		&:hover {	
			background-color: #1568bf; //#668CCC
			color: #fff;
      svg {
        fill: #fff;
      }      
		}
	}

  .logo {
    font-size: 32px;
  }

  @media only screen and (max-width: 420px) {
    width: 100%;
    height: 100%;  
  }
`;

export default AzureLoginStyles