import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
// components
import { Card, Button } from "../../../../components";
import BasicInfoSection from "../BasicInfoSection";
import CompanyTagsSection from "../CompanyTagsSection";
import DetailsSection from "../DetailsSection";
// styles
import AddCompanyStyles from "./AddCompanyStyles";
// entity
import { Company } from "../../../../models/domain";
// services
import { validateCompanyForm } from "../../../../services/validations/superAdmin/company.validations";
import { closeAddCompany } from "../companies.services";
import * as companiesService from "../../../../services/api/admin/companies.service";
import * as actionCreators from "../../../../actions/admin/companies.actions";

const AddCompany = ({ setIsAddCompanyOpen, addNewCompany }) => {
  const [disableButton, setDisableButton] = useState(false);

  const handleCreateCompanyError = ({ error }) => {
    const { data } = error;
    if (data.errors) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
      if (data.errors.address) {
        toast.error(`${data.error.message}: ${data.errors.address.keys[0]}`);
      }
      if (data.errors.pib) {
        toast.error(`${data.error.message}: ${data.errors.pib.keys[0]}`);
      }
      if (data.errors.idNumber) {
        toast.error(`${data.error.message}: ${data.errors.idNumber.keys[0]}`);
      }
      if (data.errors.contact) {
        toast.error(`${data.error.message}: ${data.errors.contact.keys[0]}`);
      }
      if (data.errors.invoiceEmail) {
        toast.error(
          `${data.error.message}: ${data.errors.invoiceEmail.keys[0]}`
        );
      }
      if (data.errors.paymentDeadline) {
        toast.error(
          `${data.error.message}: ${data.errors.paymentDeadline.keys[0]}`
        );
      }
      if (data.errors.photo) {
        toast.error(`${data.errors.photo.keys[0]}`);
      }
    } else {
      toast.error(data.error.message);
    }
  };

  const createCompanySubmit = async values => {
    setDisableButton(true);
    const response = await companiesService.createCompany(values);
    setDisableButton(false);

    if (response.hasError) {
      return handleCreateCompanyError(response);
    }

    addNewCompany(response);
    closeAddCompany(setIsAddCompanyOpen);
  };

  const formik = useFormik({
    initialValues: new Company(),
    validate: validateCompanyForm,
    onSubmit: createCompanySubmit
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    handleChange,
    handleBlur
  } = formik;

  return (
    <AddCompanyStyles>
      <Card>
        <form onSubmit={handleSubmit} autoComplete="off">
          <BasicInfoSection
            isFormDisplay
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <CompanyTagsSection
            isFormDisplay
            values={values}
            setFieldValue={setFieldValue}
          />
          <DetailsSection
            isFormDisplay
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            setFieldError={setFieldError}
          />
          <div className="submitContainer">
            {disableButton ? (
              <Button width="130px" disabled margin="0 30px 0 0">
                Please wait
              </Button>
            ) : (
              <Button
                type="submit"
                width="130px"
                margin="0 30px 0 0"
                padding="10px 25px"
              >
                Create
              </Button>
            )}
            <Button
              onClick={() => closeAddCompany(setIsAddCompanyOpen)}
              width="130px"
              margin="0"
              outline
              padding="10px 25px"
            >
              Back
            </Button>
          </div>
        </form>
      </Card>
    </AddCompanyStyles>
  );
};

AddCompany.propTypes = {
  setIsAddCompanyOpen: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    addNewCompany: company => dispatch(actionCreators.addNewCompany(company))
  };
};

export default connect(undefined, mapDispatchToProps)(AddCompany);
