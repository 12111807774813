import React from "react";
import moment from "moment";

export const LeftModalSection = ({ singleRefund }) => {
  return (
    <div className="leftSection">
      <div>
        <span>Email:</span>
        <span>{singleRefund.UserBenefit.User.email}</span>
      </div>
      <div>
        <span>Date:</span>
        <span>{moment(singleRefund.createdAt).format("DD.MM.YYYY.")}</span>
      </div>
      <div>
        <span>Benefit:</span>
        <span>{singleRefund.UserBenefit.BenefitGroup.name}</span>
      </div>
      <div>Price: {singleRefund.UserBenefit.benefitPrice}</div>
      <div>Type: {singleRefund.UserBenefit.BenefitVoucher.name}</div>
    </div>
  );
};
