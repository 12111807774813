import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
// Components
import TypesMultiselectInputStyles from './TypesMultiselectInput.styles';
import itemRenderer from '../selectInput/itemRendered';

const TypesMultiselectInput = ({
  name,
  options,
  selectedValues,
  shouldHaveHight = false,
  shouldHaveFullWidth = false,
  handleChange,
  isDisabled = false,
  customHeight = '100%',
  customInputFieldHeight = '100%'
}) => {

  return (
    <TypesMultiselectInputStyles
      shouldHaveHight={shouldHaveHight}
      shouldHaveFullWidth={shouldHaveFullWidth}
      customHeight={customHeight}
      customInputFieldHeight={customInputFieldHeight}
    >
      <Select
        value={selectedValues}
        className='react-dropdown-select'
        classNamePrefix='react-dropdown'
        placeholder="Filter By"
        labelField="name"
        valueField="id"
        getOptionLabel={(options) => options['name']}
        getOptionValue={(options) => options['key']}
        isMulti
        isDisabled={isDisabled}
        name={name}
        options={options}
        searchable
        searchBy="name"
        dropdownPosition="auto"
        onChange={(values, action) => handleChange(values, action)}
        itemRenderer={
          itemRenderer
            ? (item, itemIndex, props, state, methods) =>
              itemRenderer(item, itemIndex, props, state, methods)
            : undefined
        }
      />
    </TypesMultiselectInputStyles>
  )
};

TypesMultiselectInput.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  selectedValues: PropTypes.array,
  shouldHaveHight: PropTypes.bool,
  shouldHaveFullWidth: PropTypes.bool,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  customHeight: PropTypes.string,
  customInputFieldHeight: PropTypes.string,
}

export default TypesMultiselectInput;