import React from "react";
import ActivityLog from "../../../assets/images/activity-log.png";

export const EmptyRefunds = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 460
      }}
    >
      <img src={ActivityLog} alt="Refunds placeholder" />
      <p className="text">There are no refunds yet.</p>
    </div>
  );
};
