import React from "react";
import moment from "moment";

const formatDate = dateString => {
  const dateObject = moment(dateString);

  const formattedDate = dateObject.format("DD.MM.YYYY");
  const formattedTime = dateObject.format("HH:MM");

  return `${formattedDate} ${formattedTime}`;
};

export const ListOfHistoryItems = ({ openModal, showList, historyList }) => {
  return (
    <div
      className={`cardHeight historyCard ${
        showList ? "historyCard--show" : ""
      }`}
    >
      {historyList.map(historyItem => {
        return (
          <div className="content" key={historyItem.id}>
            <div
              style={{
                borderLeft: "1px solid #d6d6d6",
                paddingLeft: "15px"
              }}
            >
              <div className="list">
                <div className="item">
                  <div className="dotAndDateContainerHistory">
                    <p className="time">{formatDate(historyItem.startDate)}</p>
                    <div className="outerDot">
                      <div className="innerDot" />
                    </div>
                  </div>
                  <div
                    className="logAndAvatar logAndAvatar--history"
                    onClick={() => openModal(historyItem.BenefitGroup)}
                  >
                    <p className="log">
                      Izabrani benefit:
                      <span className="logAndAvatarHistoryPopupWrapper">
                        <span className="logAndAvatarHistoryPopup">
                          {`${historyItem.Provider?.name}`}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                            width="14"
                            height="14"
                            style={{ marginLeft: "10px" }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                            />
                          </svg>
                          <div className="arrow"></div>
                        </span>
                        <b
                          style={{
                            fontWeight: "600"
                          }}
                        >
                          <u>
                            {" "}
                            {historyItem.Provider?.name} -{" "}
                            {historyItem.Benefit.title || ""}
                          </u>
                        </b>
                      </span>
                      ,
                      <span className="historyItemParagraphWrapper">
                        {" "}
                        Cena u tokenima:{" "}
                        <b style={{ fontWeight: "600" }}>
                          {historyItem.benefitPrice || 0}
                        </b>
                        ,
                      </span>{" "}
                      Količina:{" "}
                      <b style={{ fontWeight: "600" }}>
                        {historyItem.quantity || 0}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
