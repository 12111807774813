import React, { useEffect, useState, useContext, useRef } from "react";
import { BeatLoader } from "react-spinners";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// components
import { Header, TextAndLine, Card, Button } from "../../../../components";
import BasicInfoSection from "../BasicInfoSection";
import CompanyTagsSection from "../CompanyTagsSection";
import DetailsSection from "../DetailsSection";
// styling
import CompanyDetailsStyles from "./CompanyDetailsStyles";
import { override } from "../../../../services/loadingOverride.styles";
// entity
import { Company } from "../../../../models/domain";
// services
import * as companiesService from "../../../../services/api/admin/companies.service";
// context
import { UserContext } from "../../../../modules/user/context/user.context";

const CompanyDetails = ({ match }) => {
  const [company, setCompany] = useState(new Company());
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(true);
  // const { user } = useContext(UserContext)
  const history = useHistory();

  const fetchSingleCompany = async () => {
    const response = await companiesService.getSelectedCompany(match.params.id);
    if (isMounted.current && response) {
      setTimeout(() => {
        setCompany(response);
        setIsLoading(false);
        setTimeout(() => {
          const companyDetails = document.getElementById("companyDetails");
          if (isMounted.current && companyDetails) {
            companyDetails.style.opacity = 1;
          }
        }, 100);
      }, 100);
    }
  };

  useEffect(() => {
    fetchSingleCompany();
    return () => () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <CompanyDetailsStyles>
      <div className="companyDetailsContainer">
        <Header headerTitle="Company Details" />
        <div className="companyDetailsContent">
          {isLoading ? (
            <div
              style={{
                height: "calc(100vh - 180px)",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <BeatLoader
                css={override}
                size={25}
                color="#123abc"
                loading={isLoading}
              />
            </div>
          ) : (
            <Card id="companyDetails">
              <BasicInfoSection
                values={{
                  name: company.name,
                  address: company.address,
                  pib: company.pib,
                  idNumber: company.idNumber,
                  invoiceEmail: company.invoiceEmail,
                  contact: company.contact,
                  paymentDeadline: company.paymentDeadline
                }}
              />
              <CompanyTagsSection
                values={{
                  tags: company.tags
                }}
              />
              <DetailsSection
                values={{
                  photo: company.photo
                }}
              />
              <div style={{ display: "flex", marginBottom: 30 }}>
                <Button
                  width="130px"
                  padding="10px 22px"
                  margin="0 0 0 0"
                  onClick={() =>
                    history.push(`/companies/edit/${match.params.id}`)
                  }
                >
                  Edit
                </Button>
                <Button
                  width="130px"
                  padding="10px 22px"
                  margin="0 0 0 30px"
                  outline
                  onClick={() => history.push("/companies")}
                >
                  Back
                </Button>
              </div>
              {company.tiers.length > 0 && (
                <Card>
                  <TextAndLine title="Company Tiers" />
                  <div className="companyTiersList">
                    {company.tiers.map(tier => (
                      <div className="tierItem" key={tier.id}>
                        <p className="tierName">{tier.name}</p>
                        <p>
                          {tier.tokens} <span className="span">Tokens</span>
                        </p>
                      </div>
                    ))}
                  </div>
                </Card>
              )}
            </Card>
          )}
        </div>
      </div>
    </CompanyDetailsStyles>
  );
};

CompanyDetails.propTypes = {
  match: PropTypes.shape()
};

export default CompanyDetails;
