import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
// custom components
import { Button } from "../../../../components";
// styles
import { ButtonContainerStyles } from "./buttonsContainer.styles";
// services
import * as actionCreators from "../../../../actions/employee/employeeBenefits.actions";
// constants
import * as benefitGroupTypes from "../../../../builders/benefitGroups/benefitGroup.types";

const ButtonsContainer = ({
  isSubscribedBenefitGroupsVisible,
  setIsSubscribedBenefitGroupsVisible,
  setBenefitGroupType,
  benefitGroupType,
  isFavoritePageOpen,
  setIsFavoritePageOpen
}) => {
  const { listOfFavoriteBenefits } = useSelector(
    state => state.employeeBenefitsPage.favoriteBenefits
  );

  const showFilters = () => {
    const filters = document.getElementById("filters");
    const filterWrapper = document.getElementById("filterWrapper");
    const body = document.getElementsByTagName("body")[0];

    if (
      filters &&
      filterWrapper &&
      !filterWrapper.classList.contains("showWrapper") &&
      !filters.classList.contains("showFilters")
    ) {
      filterWrapper.classList.add("showWrapper");
      filters.classList.add("showFilters");
      body.style.overflow = "hidden";
      body.style.position = "fixed";
      body.style.width = "100%";
    }
  };

  const navigateToBrowseBenefits = () => {
    setIsSubscribedBenefitGroupsVisible(false);
    setBenefitGroupType(benefitGroupTypes.PUBLIC);
  };

  const navigateToCompanyBenefits = () => {
    setIsSubscribedBenefitGroupsVisible(false);
    setBenefitGroupType(benefitGroupTypes.LOCAL);
  };

  const navigateToMyBenefits = () => {
    setIsSubscribedBenefitGroupsVisible(true);
    setBenefitGroupType("");
  };

  const navigateToFavoriteBenefits = () => {
    setIsSubscribedBenefitGroupsVisible(true);
    setIsFavoritePageOpen(true);
    setBenefitGroupType(benefitGroupTypes.FAVORITE);
  };

  useEffect(() => {
    if (benefitGroupType !== benefitGroupTypes.FAVORITE && isFavoritePageOpen) {
      setIsFavoritePageOpen(false);
    }
  }, [benefitGroupType]);

  return (
    <ButtonContainerStyles>
      <div className="buttonsContainer">
        <div className="pageButtonsContainer">
          <Button
            customClass="mobileButton"
            margin="0 10px 0 0"
            onClick={navigateToBrowseBenefits}
            outline={
              benefitGroupType === benefitGroupTypes.PUBLIC ? false : true
            }
            transparent={
              benefitGroupType === benefitGroupTypes.PUBLIC ? false : true
            }
          >
            Browse Benefits
          </Button>
          <Button
            customClass="mobileButton"
            margin="0 10px 0 0"
            onClick={navigateToCompanyBenefits}
            outline={
              benefitGroupType === benefitGroupTypes.LOCAL ? false : true
            }
            transparent={
              benefitGroupType === benefitGroupTypes.LOCAL ? false : true
            }
          >
            Company Benefits
          </Button>
          <Button
            customClass="mobileButton"
            margin="0 10px 0 0"
            width="113px"
            onClick={navigateToMyBenefits}
            outline={benefitGroupType ? true : false}
            transparent={benefitGroupType ? true : false}
          >
            My Benefits
          </Button>
          <Button
            customClass="mobileButton"
            margin="0 10px 0 0"
            onClick={navigateToFavoriteBenefits}
            outline={
              benefitGroupType === benefitGroupTypes.FAVORITE ? false : true
            }
            transparent={
              benefitGroupType === benefitGroupTypes.FAVORITE ? false : true
            }
          >
            Favorite Benefits
          </Button>
        </div>
        {!isSubscribedBenefitGroupsVisible && (
          <Button
            customClass="mobileFilterButton"
            margin="0"
            width="115px"
            onClick={showFilters}
          >
            Open Filters
          </Button>
        )}
      </div>
    </ButtonContainerStyles>
  );
};

const mapStateToProps = state => {
  return {
    isSubscribedBenefitGroupsVisible:
      state.employeeBenefitsPage.pageFrontEndStates
        .isSubscribedBenefitGroupsVisible,
    benefitGroupType:
      state.employeeBenefitsPage.pageFrontEndStates.benefitGroupType,
    isFavoritePageOpen:
      state.employeeBenefitsPage.pageFrontEndStates.isFavoriteTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setIsSubscribedBenefitGroupsVisible: isVisible =>
      dispatch(actionCreators.setIsSubscribedBenefitGroupsVisible(isVisible)),
    setBenefitGroupType: benefitGroupType =>
      dispatch(actionCreators.setBenefitGroupType(benefitGroupType)),
    setIsFavoritePageOpen: payload =>
      dispatch(actionCreators.setIsFavoritePageOpen(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsContainer);
