/* eslint-disable */
import validator from 'validator'

export const setPasswordValidation = ({ email, password, passwordConfirmation, termsAndPolicy }) => {
	const errors = {}
	if (!email) {
		errors.email = '* Required'
	} else if (!validator.isEmail(email)) {
		errors.email = 'Invalid email'
	}

	if (!password) {
		errors.password = '* Required'
	} else if (password.length < 8) {
		errors.password = 'At least 8 characters'
	} else if (!password.match(/\d+/g)) {
		errors.password = 'Must contain number'
	} else if (!password.match(/[a-zA-Z]/)) {
		errors.password = 'Must contain letters'
	}

	if (!passwordConfirmation) {
		errors.passwordConfirmation = '* Required'
	} else if (passwordConfirmation !== password) {
		errors.passwordConfirmation = 'No match'
    }
    
    if (!termsAndPolicy) {
        errors.termsAndPolicy = '* Required'
    }

	return errors
}
