import validator from "validator";
import moment from "moment";
import { isEmpty, removeSpacesAndHTML } from "../../general.utils";
import { UserRolesKeysEnum } from "../../../constants/roles.constants";
import { doesRoleExist } from "../../roles.utils";

const allowedDateFormats = [
  "DD/MM/YYYY",
  "D/M/YYYY",
  "DD.MM.YYYY",
  "D.M.YYYY",
  "DD-MM-YYYY",
  "D-M-YYYY"
];

const validateDate = value => moment(value, allowedDateFormats, true).isValid();

export const editUserFormValidations = ({
  firstName,
  lastName,
  email,
  roles,
  bornDate,
  firstDayAtWork,
  gender,
  tier,
  companyId,
  cities
}) => {
  const errors = {};
  if (!firstName || !/\S/.test(firstName)) errors.firstName = "* Required"
  else if (!removeSpacesAndHTML(firstName)){
    errors.firstName = 'HTML tag is not allowed'
  };

  if (!lastName || !/\S/.test(lastName)) errors.lastName = "* Required"
  else if (!removeSpacesAndHTML(lastName)){
    errors.lastName = 'HTML tag is not allowed'
  };

  if (!roles) {
    errors.roles = "* Required";
  }

  if (!email) errors.email = "* Required";
  else if (!validator.isEmail(email)) errors.email = "Invalid email";

  if (doesRoleExist(roles, UserRolesKeysEnum.MEMBER)) {
    // if (!bornDate) {
    // 	errors.bornDate = '* Required'
    // } else if (!validateDate(bornDate)) {
    // 	errors.bornDate = 'Invalid date'
    // }

    // if (!firstDayAtWork) {
    // 	errors.firstDayAtWork = '* Required'
    // } else if (!validateDate(firstDayAtWork)) {
    // 	errors.firstDayAtWork = 'Invalid date'
    // }
    if (typeof tier === Object) {
      if (tier.id === null) {
        errors.tier = "* Required";
      }
    } else {
      if (!tier) {
        errors.tier = "* Required";
      }
    }

    if (isEmpty(cities)) {
      errors.cities = "* Required";
    }
    // if (!gender) errors.gender = '* Required'
  }

  if (doesRoleExist(roles, UserRolesKeysEnum.HR) || doesRoleExist(roles, UserRolesKeysEnum.MEMBER)) {
    if (!companyId) errors.companyId = "* Required";
  }

  return errors;
};
