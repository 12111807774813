import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
// components
import BenefitGroupForm from "../BenefitGroupForm";
// styles
import EditBenefitGroupStyles from "./EditBenefitGroupStyles";
import { override } from "../../../../../../services/loadingOverride.styles";
// entity
import { BenefitGroup } from "../../../../../../models/domain";
// services
import { validateBenefitGroupForm } from "../../../../../../services/validations/benefitGroupFormValidation";

import * as providersService from "../../../../../../services/api/admin/providers.service";
import * as benefitGroupsService from "../../../../../../services/api/admin/benefitGroups.service";
import * as actionCreators from "../../../../../../actions/admin/providers.actions";
// context
import { ProvidersContext } from "../../../../../../modules/superAdmin/providers/context/providers.context";

const EditBenefitGroup = ({
  benefitGroupId,
  providerId,
  closeModal,
  editBenefitGroup
}) => {
  // const { providersActions } = useContext(ProvidersContext);
  const [isLoading, setIsLoading] = useState(true);
  const [benefitGroup, setBenfitGroup] = useState(new BenefitGroup());
  const [disableButton, setDisableButton] = useState(false);

  const handleEditBenefitGroupError = ({ error }) => {
    const { data } = error;
    if (data.errors) {
      if (data.errors.name) {
        toast.error(`${data.error.message}: ${data.errors.name.keys[0]}`);
      }
      if (data.errors.description) {
        toast.error(
          `${data.error.message}: ${data.errors.description.keys[0]}`
        );
      }
      if (data.errors.summary) {
        toast.error(
          `${data.error.message}: ${data.errors.summary.keys[0]}`
        );
      }
      if (data.errors.categoryIds) {
        toast.error(`${data.error.message}: ${data.errors.categoryIds.keys[0]}`);
      }
      if (data.errors.taxes) {
        toast.error(`${data.error.message}: ${data.errors.taxes.keys[0]}`);
      }
      if (data.errors.photo) {
        toast.error(`${data.errors.photo.keys[0]}`);
      }
      if (data.errors.cityIds) {
        toast.error(`${data.errors.cityIds.keys[0]}`);
      }
    } else {
      toast.error(data.error.message);
    }
  };

  const updateBenefitGroup = async values => {
    setDisableButton(true);

    const response = await providersService.editBenefitGroup(
      providerId,
      benefitGroupId,
      values
    );

    if (response.hasError) {
      setDisableButton(false);
      return handleEditBenefitGroupError(response);
    }

    editBenefitGroup(providerId, response);
    toast.success("Benefit group successfully updated!");
    setTimeout(() => {
      setDisableButton(false);
    }, 550);
    closeModal();
  };

  const fetchBenefitGroup = async () => {
    const response = await benefitGroupsService.getChosenBenefitGroup(
      benefitGroupId
    );

    if (response.hasError) {
      return toast.error(response.error.data.error.message);
    }

    setBenfitGroup(response);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBenefitGroup();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: benefitGroup,
    validate: validateBenefitGroupForm,
    onSubmit: updateBenefitGroup
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur
  } = formik;

  return (
    <EditBenefitGroupStyles>
      <h3 className="title">Edit Benefit Group</h3>
      {isLoading ? (
        <div
          style={{
            height: "calc(100vh - 180px)",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <BeatLoader
            css={override}
            size={25}
            color="#123abc"
            loading={isLoading}
          />
        </div>
      ) : (
        <form className="form" autoComplete="off" onSubmit={handleSubmit}>
          <BenefitGroupForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            handleChange={handleChange}
            buttonText="Update"
            handleBlur={handleBlur}
            closeModal={closeModal}
            disableButton={disableButton}
            isEdit
          />
        </form>
      )}
    </EditBenefitGroupStyles>
  );
};

EditBenefitGroup.propTypes = {
  benefitGroupId: PropTypes.number,
  providerId: PropTypes.number,
  closeModal: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    editBenefitGroup: (providerId, benefitGroup) =>
      dispatch(actionCreators.editBenefitGroup(providerId, benefitGroup))
  };
};

export default connect(undefined, mapDispatchToProps)(EditBenefitGroup);
