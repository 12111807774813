import React, { useEffect } from "react";
import { CheckboxInput } from "../../../../../components";
import ToggleSlider from "../../../../../components/ToggleSlider/ToggleSlider";
import { apiService } from "../../../../../services/api/api.service";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setToggleReimbursement } from "../../../../../actions/hr/refunds.actions";
import debounce from "../../../../../utils/helpers/debounce";

export const RefundListSecondLayer = props => {
  const {
    handleCheckAll,
    checkedPendingItems,
    handleBulkAcceptOrDecline
  } = props;

  const dispatch = useDispatch();

  const { companyId } = useSelector(state => state.app.user);
  const { toggleReimbursement } = useSelector(state => state.refundsReducer);

  const fetchCompanyReimbursementState = async () => {
    try {
      const response = await apiService.get(`/company/${companyId}`);
      dispatch(setToggleReimbursement(response.data.toggleReimbursement));
    } catch (error) {
      toast.error("Unable to get company reimbursement state.");
    }
  };

  useEffect(() => {
    if (!companyId) return;

    fetchCompanyReimbursementState();
  }, [companyId]);

  const changeCompanyToggleReimbursement = async value => {
    try {
      const response = await apiService.get(
        `/company/${companyId}/toggle-reimbursement?toggle=${value}`
      );

      dispatch(setToggleReimbursement(response.data.toggleReimbursement));
    } catch (error) {
      toast.error("Unable to get company reimbursement state.");
    }
  };

  return (
    <div className="refundListSecondLayerActivity">
      <div className="checkboxCol">
        <CheckboxInput
          onChange={handleCheckAll}
          isChecked={checkedPendingItems.length > 0}
          name="Select all"
          width="18px"
          height="18px"
        />
        {checkedPendingItems.length} selected
      </div>
      {checkedPendingItems.length > 0 && (
        <div className="bulkAcceptOrDecline">
          <span
            className="bulkAccept"
            onClick={() => handleBulkAcceptOrDecline(true)}
          >
            Accept
          </span>{" "}
          or{" "}
          <span
            className="bulkDecline"
            onClick={() => handleBulkAcceptOrDecline(false)}
          >
            Decline
          </span>
        </div>
      )}
      <div className="refundListSecondLayerActivityToggle">
        {toggleReimbursement ? "Auto Accept" : "Auto Decline"}
        <ToggleSlider
          isChecked={toggleReimbursement}
          onClick={debounce(changeCompanyToggleReimbursement, 500)}
        />
      </div>
    </div>
  );
};
