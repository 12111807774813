import {
  DashboardIcon,
  BenefitsIcon,
  EmployeesIcon,
  SettingsIcon
  // HelpIcon
} from "../assets/Icons";

const admin = [
  {
    path: "/",
    title: "Dashboard",
    icon: DashboardIcon
  },
  {
    path: "/providers",
    title: "Benefits",
    icon: BenefitsIcon
  },
  {
    path: "/activity",
    title: "Activity",
    icon: BenefitsIcon
  },
  {
    path: "/companies",
    title: "Companies",
    icon: BenefitsIcon
  },
  {
    path: "/users",
    title: "Users",
    icon: EmployeesIcon
  },
  {
    path: "/settings",
    title: "Settings",
    icon: SettingsIcon
  },
  {
    path: "/notifications",
    title: "Notifications",
    icon: DashboardIcon
  }
];

const hr = [
  {
    path: "/",
    title: "Dashboard",
    icon: DashboardIcon
  },
  {
    path: "/benefits",
    title: "Benefits",
    icon: BenefitsIcon
  },
  {
    path: "/company-benefits",
    title: "Company Benefits",
    icon: BenefitsIcon
  },
  {
    path: "/employees",
    title: "Employees",
    icon: EmployeesIcon
  },
  {
    path: "/settings",
    title: "Settings",
    icon: SettingsIcon
  }
];

export const navigationConfig = {
  admin: admin,
  hr: hr
};
