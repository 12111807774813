import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { Card } from "../../../../components";
import UsageWidgetStyles from "./UsageWidgetStyles";
import * as dashboardService from "../../../../services/api/hr/dashboard.service";

const UsageWidget = ({ companyName, setUsageLoading }) => {
  const [values, setValues] = useState({});

  const handleFetchCompanyUsage = async () => {
    const response = await dashboardService.fetchCompanyUsage();
    setUsageLoading(false);

    if (response.hasError) {
      return toast.error(
        response.errorMessage
          ? response.errorMessage
          : "Fetching Company Usage failed."
      );
    }

    setValues(response);
  };

  useEffect(() => {
    handleFetchCompanyUsage();
  }, []);

  return (
    <UsageWidgetStyles>
      <Card padding='19px'>
        <div style={{ height: 185 }}>
          <div className="titleContainer">
            <h3 className="title">{companyName}</h3>
            <h3 className="title">Usage Statistics</h3>
          </div>
          <div className="statisticsContainer">
            <div className="statisticItem">
              <h4 className="statisticName">Total users</h4>
              <p>{values.totalUsers}</p>
            </div>
            <div className="statisticItem">
              <h4 className="statisticName">HRs</h4>
              <p>{values.totalHr}</p>
            </div>
            <div className="statisticItem">
              <h4 className="statisticName">Employees</h4>
              <p>{values.totalEmployee}</p>
            </div>
            <div className="statisticItem">
              <h4 className="statisticName">Employees using the system</h4>
              <p>{values.employeeOnPlatform}</p>
            </div>
            <div className="statisticItem">
              <h4 className="statisticName">Employees not using the system</h4>
              <p>{values.employeeNotOnPlatform}</p>
            </div>
          </div>
        </div>
      </Card>
    </UsageWidgetStyles>
  );
};

UsageWidget.propTypes = {
  companyName: PropTypes.string,
  setUsageLoading: PropTypes.func
};

export default UsageWidget;
