import React from "react";
import moment from "moment";

const EmployeeSubscribedToMcpBenefit = ({ notification }) => {
  return (
    <>
      <h4 className="notification-title">
        You have successfully subcribed to a benefit that has a minimum contract period.
      </h4>
      <p className="notification-subtitle">
        Once it becomes active, you cannot unsubscribe from it until the minimum contract period expires.
        Then you will be unsubscribed automatically.
      </p>
      <p className="subtitle-description">
        Your subscription will last for {notification.payload.minimumContractPeriod} months, and will expire on {moment(notification.payload.minimumContractPeriodExpiration).format("DD. MMMM")}
      </p>
    </>
  );
};

export default EmployeeSubscribedToMcpBenefit;
