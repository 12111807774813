import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Input, InputLabel, Button } from '../../../../../../components'

const FormStyles = styled.div`

.labelErrorContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
`

const Form = ({
	values,
	errors,
	touched,
	handleChange,
	handleBlur,
	buttonText
}) => (
	<FormStyles>
		<div>
			<div className="labelErrorContainer">
				<InputLabel margin="0 0 5px 0" label="Name" />
				<div className="errorMsg" style={{ marginBottom: 5 }}>{errors.name && touched.name ? errors.name : ''}</div>
			</div>
			<Input
				name="name"
				value={values.name}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
		</div>
		<Button margin="20px 0 0 0" type="submit">
			{buttonText}
		</Button>
	</FormStyles>
)

Form.propTypes = {
	values: PropTypes.shape(),
	errors: PropTypes.shape(),
	touched: PropTypes.shape(),
	handleChange: PropTypes.func,
	handleBlur: PropTypes.func,
	buttonText: PropTypes.string
}

export default Form
