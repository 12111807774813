import React from 'react'
import PropTypes from 'prop-types'

import AuthStyles from './AuthsSyles'
import { BenefitLogo } from '../../assets/Icons'
import Button from '../button'

const Auth = ({
	isRegistration = false,
	onChange,
	onSubmit,
	credentials: { email, password },
	invitationCode
}) => (
	<AuthStyles>
		<div className="authWindow">
			<BenefitLogo color="#1568bf" />
			<div className="formContainer">
				<form onSubmit={onSubmit} className="form" autoComplete="off">
					<label className="label" htmlFor="email">
						Email:
						<input
							className="inputs"
							id="email"
							name="email"
							type="email"
							value={email}
							onChange={onChange}
						/>
					</label>
					<label className="label" htmlFor="password">
						Password:
						<input
							className="inputs"
							id="password"
							name="password"
							type="password"
							value={password}
							onChange={onChange}
						/>
					</label>
					{isRegistration && (
						<label className="label" htmlFor="invitationCode">
							Invitation code:
							<input
								className="inputs"
								id="invitationCode"
								name="invitationCode"
								type="number"
								value={invitationCode}
								onChange={onChange}
							/>
						</label>
					)}

					<div className="buttonContainer">
						<Button type="submit" margin="0" padding="10px 22px">
							{isRegistration ? 'Register' : 'Login'}
						</Button>
					</div>
				</form>
			</div>
		</div>
		<div className="bannerWindow" />
	</AuthStyles>
)

Auth.propTypes = {
	isRegistration: PropTypes.bool,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	credentials: PropTypes.shape({
		email: PropTypes.string,
		password: PropTypes.string
	}),
	invitationCode: PropTypes.string
}

export default Auth
